import React, { useEffect, useRef, useState } from "react";
import {
  Checkbox,
  Typography,
  Box,
  FormControl,
  OutlinedInput,
  Tooltip,
  InputAdornment,
  IconButton,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  CircularProgress,
} from "@mui/material";
import styled from "styled-components";
import { useNavigate, Link } from "react-router-dom";
import { completeWalkthrough, getSession, setUser, signUp, verifyEmail } from "../slices/AuthSlice";
import { Agent, AgentType, EHR, PartialFrontendUser } from "../types";
import { useSelector } from "react-redux";
import axios from "axios";
import { RootState, useAppDispatch } from "../store";
import { useSnackbar } from "../providers/SnackbarProvider";
import SignupIntegrationCard from "../subcomponents/SignupIntegrationCard";
import healthieLogo from "../assets/Healthie.png";
import LoadingWithMessage from "../subcomponents/LoadingWithMessage";
import { usePostHog } from "posthog-js/react";
import { PrimaryButton, TransparentButton } from "../subcomponents/CustomButton";
import { fetchAgents } from "../slices/AgentSlice";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Colors } from "../Colors";
import NoApiKeyMsg from "./NoApiKeyMsg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

declare const grecaptcha: any;

enum CreateAccountStep {
  CreateAccount = 0,
  VerifyEmail = 1,
  ApiKeyQuestion = 2,
  Integrations = 3,
  NoApiKeyMessage = 4,
}

const ModalBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 70%;
  margin: 5% auto;
  background-color: #ffffff;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);

  @media (max-width: 1100px) {
    width: 80%;
    padding: 20px;
    margin: 10% auto;
  }

  @media (max-width: 768px) {
    margin: 5% auto;
  }

  @media (max-width: 480px) {
    padding: 30px 15px;
  }

  @media (max-width: 380px) {
    padding: 15px 20px;
  }
`;

const StepsContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  margin-bottom: 30px;
  width: 25%;

  @media (max-width: 1200px) {
    width: 35%;
  }

  @media (max-width: 768px) {
    width: 45%;
  }

  @media (max-width: 480px) {
    width: 65%;
  }
`;

const StepCircle = styled(Box)<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: ${({ active }) => (active ? "#13B7CC" : "#000000")};
  color: #ffffff;
  font-size: 1rem;
  font-weight: bold;

  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
    font-size: 0.8rem;
  }
`;

const StepLabel = styled(Typography)`
  margin-top: 5px;
  font-size: 0.9rem;
  white-space: nowrap;

  @media (max-width: 1100px) {
    font-size: 0.8rem;
  }

  @media (max-width: 768px) {
    font-size: 0.7rem;
  }
`;

const FormBox = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 60%;
  margin: auto;

  @media (max-width: 1100px) {
    width: 90%;
  }
`;

const RowBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
  gap: 5%;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
  }
`;

const IntegrationsContainer = styled(Box)`
  display: flex;
  justify-content: center;
  gap: 3%;
  padding: 5%;
  margin-top: -20px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
    padding: 0;
    margin-top: 0;
    margin-bottom: 20px;
  }
`;

const PasswordRequirements = styled(Box)`
  font-size: 0.8rem;
  color: #555;

  ul {
    padding-left: 20px;
    margin: 0;
  }

  li {
    list-style-type: disc;
    color: #acacac;
    margin-bottom: 5px;

    &.valid {
      color: lightgreen;
      font-weight: bold;
    }
  }
`;

const CreateAccountForm: React.FC = () => {
  const walkthroughUser = useSelector((state: RootState) => state.auth.authUserModel);
  const user = useSelector((state: RootState) => state.auth.user);
  const [currentStep, setCurrentStep] = useState(CreateAccountStep.CreateAccount);
  const [termsAndPrivacyChecked, setTermsAndPrivacyChecked] = useState(false);
  const [hipaaBaaChecked, setHipaaBaaChecked] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordFocused, setPasswordFocused] = useState(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [apiKeyAnswer, setApiKeyAnswer] = useState<string>("yes");
  const [verificationCode, setVerificationCode] = useState("");
  const [verificationSent, setVerificationSent] = useState(false);
  const [verificationError, setVerificationError] = useState("");
  const [userData, setUserData] = useState<PartialFrontendUser | null>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { showMessage } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState<string>("");
  const [createAccountLoading, setCreateAccountLoading] = useState(false);
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const hasRunRef = useRef(false);

  const posthog = usePostHog();

  // Computed password requirements
  const isLengthValid = password.length >= 8;
  const hasUppercase = /[A-Z]/.test(password);
  const hasLowercase = /[a-z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecialChar = /[!@#$%^&*()]/.test(password);
  const passwordIsValid = isLengthValid && hasUppercase && hasLowercase && hasNumber && hasSpecialChar;

  useEffect(() => {
    posthog?.capture("[PENCILED] Create Account page loaded");
  }, []);

  useEffect(() => {
    if (currentStep === CreateAccountStep.ApiKeyQuestion) {
      posthog?.capture("[PENCILED] API Key Question page loaded");
    }
  }, [currentStep]);

  useEffect(() => {
    if (currentStep === CreateAccountStep.Integrations) {
      posthog?.capture("[PENCILED] Integrations page loaded");
    }
  }, [currentStep]);

  useEffect(() => {
    if (currentStep === CreateAccountStep.NoApiKeyMessage) {
      posthog?.capture("[PENCILED] No API Key Message page loaded");
    }
  }, [currentStep]);

  const handleCreateAccount = async () => {
    setCreateAccountLoading(true);

    try {
      const action = await dispatch(
        signUp({
          email,
          password,
          firstName,
          lastName,
          // organizationName: "",
          // callbackNumber: "",
        })
      );

      if (action.type === "auth/signUp/rejected") {
        throw new Error(`Sign up failed: ${(action.payload as Error).message}`);
      }

      const newUserData = action.payload as PartialFrontendUser;
      setUserData(newUserData); // Save user data for verification step
      setVerificationSent(true);
      setCurrentStep(CreateAccountStep.VerifyEmail);
      showMessage("Verification code sent to your email", "success");
    } catch (error) {
      console.error("Sign-up failed:", error);
      setErrorMessage((error as Error).message);
      showMessage(`Sign-up failed: ${(error as Error).message}`, "error");
    } finally {
      setCreateAccountLoading(false);
    }
  };

  const handleVerifyEmail = async () => {
    if (!userData) return;

    setVerifyLoading(true);

    try {
      const action = await dispatch(
        verifyEmail({
          email,
          code: verificationCode,
          userData,
        })
      );

      if (action.type === "auth/verifyEmail/rejected") {
        throw new Error(`Email verification failed: ${(action.payload as Error).message}`);
      }

      showMessage("Email verified successfully", "success");
      setCurrentStep(CreateAccountStep.ApiKeyQuestion);
    } catch (error) {
      console.error("Verification failed:", error);
      setVerificationError((error as Error).message || "Verification failed.");
      showMessage((error as Error).message || "Verification failed.", "error");
    } finally {
      setVerifyLoading(false);
    }
  };

  const handleCreateAgentWithEhrParameters = async (providerId: string, appointmentTypeId: string) => {
    if (hasRunRef.current) return;
    hasRunRef.current = true;
    posthog?.capture("[PENCILED] Create Agent Button clicked", { providerId: providerId, appointmentTypeId: appointmentTypeId });
    setLoading(true);
    setLoadingMessage("Creating agent...");
    const agent: Partial<Agent & { organizationName: string }> = {
      ehr: EHR.healthie,
      enableCalling: true,
      enableTexting: false,
      name: walkthroughUser?.agentName || undefined,
      areaCode: walkthroughUser?.orgAreaCode || undefined,
      ehrProviderId: providerId,
      ehrApptTypeId: appointmentTypeId,
      agentType: AgentType.scheduling, // walkthroughUser?.agentType || undefined,
      promptId: "general-scheduling-healthie",
    };

    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/agents`, { agent: agent }, { headers: { Authorization: `Bearer ${user?.token}` } });

      if (!user?.token) {
        throw new Error("User token not found");
      }

      await dispatch(getSession());
      dispatch(completeWalkthrough());

      // Get the newly created agent and then navigate to make a call
      if (user?.userId && user?.token) {
        const action = await dispatch(fetchAgents({ userId: user?.userId, token: user?.token }));
        if (action.payload instanceof Error) {
          throw new Error(`Failed to fetch agents: ${(action.payload as Error).message}`);
        } else {
          const agents = action.payload?.agents;
          const agentId = agents?.[0]?.agentId;
          if (agentId) {
            posthog?.capture("[PENCILED] Agent created", { agentId: agentId });
            navigate(`/dashboard/agents/${agentId}?call=true`);
          } else {
            navigate(`/dashboard`);
          }
        }
      } else {
        navigate(`/dashboard`);
      }
    } catch (error) {
      console.error("Failed to create agent:", error);
      showMessage("Failed to create agent.", "error");
    } finally {
      setLoading(false);
      setLoadingMessage("");
    }
  };

  const handleCreateAgentWithoutEhrParameters = async () => {
    if (hasRunRef.current) return;
    hasRunRef.current = true;
    posthog?.capture("[PENCILED] Create Agent Button clicked but without EHR integration");
    const agent: Partial<Agent> = {
      agentType: AgentType.scheduling, // walkthroughUser?.agentType || undefined,
      enableCalling: true,
      enableTexting: false,
      name: walkthroughUser?.agentName || undefined,
      areaCode: walkthroughUser?.orgAreaCode || undefined,
      promptId: "general-scheduling-healthie",
      ehr: EHR.healthie,
    };

    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/agents`, { agent: agent }, { headers: { Authorization: `Bearer ${user?.token}` } });

      if (!user?.token) {
        throw new Error("User token not found");
      }

      await dispatch(getSession());
      dispatch(completeWalkthrough());

      // Get the newly created agent and then navigate to make a call
      if (user?.userId && user?.token) {
        const action = await dispatch(fetchAgents({ userId: user?.userId, token: user?.token }));
        if (action.payload instanceof Error) {
          throw new Error(`Failed to fetch agents: ${(action.payload as Error).message}`);
        }
      }
    } catch (error) {
      console.error("Failed to create agent:", error);
    }
  };

  // Function to get the cookies and authenticity token
  const getCookieAndToken = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/healthie-form/cookie-and-token`, { withCredentials: true });
      return response.data;
    } catch (error) {
      console.error("Error retrieving cookies and authenticity token:", error);
      throw error;
    }
  };

  // Function to submit the form
  const submitHealthieForm = async (
    organizationName: string,
    firstName: string,
    lastName: string,
    email: string,
    authenticityToken: string,
    cookies: string[]
  ) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/healthie-form/submit-form`,
        {
          organizationName,
          firstName,
          lastName,
          email,
          authenticityToken,
          cookies,
        },
        {
          withCredentials: true,
        }
      );
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("Error submitting Healthie form:", error.response ? error.response.data : error.message);
      } else {
        console.error("Error submitting Healthie form:", (error as Error).message);
      }
    }
  };

  // Main function to handle the form submission
  const handleApiKeyResponse = async () => {
    setApiLoading(true);
    // const organizationName = userData?.organizationName || '';

    posthog?.capture("[PENCILED] API Key Question answered", { answer: apiKeyAnswer });

    if (apiKeyAnswer === "yes") {
      setCurrentStep(CreateAccountStep.Integrations);
    } else {
      try {
        const { cookie, authenticityToken } = await getCookieAndToken();
        // console.log("cookie: ", cookie);
        // await submitHealthieForm(organizationName, firstName, lastName, email, authenticityToken, cookie);
      } catch (error) {
        console.error("Error during form submission process:", error);
      } finally {
        setApiLoading(false);
      }
      setCurrentStep(CreateAccountStep.NoApiKeyMessage);
    }
    setApiLoading(false);
  };

  // const handleContinueToDashboard = async () => {
  //   if (hasRunRef.current) return; // Check if the function has already run
  //   hasRunRef.current = true; // Set the ref to true to prevent further runs

  //   posthog?.capture("[PENCILED] Continue to Dashboard button clicked (no EHR integration)");
  //   navigate("/dashboard");
  // };

  const isFormValid = () => {
    return (
      firstName.trim() !== "" &&
      lastName.trim() !== "" &&
      email.trim() !== "" &&
      password.trim() !== "" &&
      termsAndPrivacyChecked &&
      hipaaBaaChecked &&
      passwordIsValid
    );
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleBackToApiKeyQuestion = () => {
    posthog?.capture("[PENCILED] Back button clicked", { currentStep: currentStep });
    setCurrentStep(CreateAccountStep.ApiKeyQuestion);
  };

  return (
    <Box sx={{ overflowY: "auto", height: "100%" }}>
      <ModalBox>
        {loading ? (
          <LoadingWithMessage message={loadingMessage} size={60} />
        ) : (
          <>
            <StepsContainer>
              <Box display="flex" flexDirection="column" alignItems="center">
                <StepCircle active={currentStep === CreateAccountStep.CreateAccount || currentStep === CreateAccountStep.VerifyEmail}>1</StepCircle>
                <StepLabel sx={{ fontSize: "0.9rem" }}>Create Account</StepLabel>
              </Box>

              <Box display="flex" flexDirection="column" alignItems="center">
                <StepCircle
                  active={
                    currentStep === CreateAccountStep.ApiKeyQuestion ||
                    currentStep === CreateAccountStep.Integrations ||
                    currentStep === CreateAccountStep.NoApiKeyMessage
                  }
                >
                  2
                </StepCircle>
                <StepLabel sx={{ fontSize: "0.9rem" }}>Integration</StepLabel>
              </Box>
            </StepsContainer>

            {currentStep === CreateAccountStep.CreateAccount && (
              <FormBox>
                <RowBox>
                  <FormControl fullWidth style={{ marginRight: "8px" }}>
                    <Typography variant="h6" sx={{ fontSize: { xs: "0.9rem", md: "1rem" } }}>
                      First Name
                    </Typography>
                    <OutlinedInput
                      placeholder="eg. Shawn"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      style={{ borderRadius: "10px", fontSize: "14px" }}
                      required
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <Typography variant="h6" sx={{ fontSize: { xs: "0.9rem", md: "1rem" } }}>
                      Last Name
                    </Typography>
                    <OutlinedInput
                      placeholder="eg. Shivdat"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      style={{ borderRadius: "10px", fontSize: "14px" }}
                      required
                    />
                  </FormControl>
                </RowBox>

                <FormControl fullWidth sx={{ marginBottom: { xs: "10px", sm: "20px" } }}>
                  <Typography variant="h6" sx={{ fontSize: { xs: "0.9rem", md: "1rem" } }}>
                    Email Address
                  </Typography>
                  <OutlinedInput
                    placeholder="Enter your email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={{ borderRadius: "10px", fontSize: "14px" }}
                    required
                  />
                </FormControl>

                <FormControl fullWidth>
                  <Typography variant="h6" sx={{ fontSize: { xs: "0.9rem", md: "1rem" } }}>
                    Password
                  </Typography>
                  <Tooltip
                    title={
                      <PasswordRequirements>
                        <ul>
                          <li className={isLengthValid ? "valid" : ""}>At least 8 characters in length</li>
                          <li className={hasUppercase ? "valid" : ""}>Contains at least one uppercase letter</li>
                          <li className={hasLowercase ? "valid" : ""}>Contains at least one lowercase letter</li>
                          <li className={hasNumber ? "valid" : ""}>Contains at least one number</li>
                          <li className={hasSpecialChar ? "valid" : ""}>Contains at least one special character (e.g., !@#$%^&*())</li>
                        </ul>
                      </PasswordRequirements>
                    }
                    open={passwordFocused}
                    placement="bottom-start"
                  >
                    <OutlinedInput
                      placeholder="Password"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      onFocus={() => setPasswordFocused(true)}
                      onBlur={() => setPasswordFocused(false)}
                      style={{ borderRadius: "10px", fontSize: "14px" }}
                      required
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </Tooltip>
                </FormControl>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Already have an account?{" "}
                  <Link to="/login" style={{ textDecoration: "none", color: Colors.link }}>
                    Sign in
                  </Link>
                </Typography>

                <Box display="flex" alignItems="center" marginTop={"20px"}>
                  <Checkbox checked={termsAndPrivacyChecked} onChange={() => setTermsAndPrivacyChecked(!termsAndPrivacyChecked)} required />
                  <Typography style={{ fontSize: "12px" }}>
                    I agree with the{" "}
                    <a target="_blank" rel="noreferrer" href="https://penciled.com/Terms" style={{ textDecoration: "none", color: Colors.link }}>
                      Terms
                    </a>{" "}
                    and{" "}
                    <a target="_blank" rel="noreferrer" href="https://penciled.com/Privacy" style={{ textDecoration: "none", color: Colors.link }}>
                      Privacy Policy
                    </a>{" "}
                    of this company.
                  </Typography>
                </Box>

                <Box display="flex" alignItems="center" marginBottom="20px">
                  <Checkbox checked={hipaaBaaChecked} onChange={() => setHipaaBaaChecked(!hipaaBaaChecked)} required />
                  <Typography style={{ fontSize: "12px" }}>
                    I agree with the{" "}
                    <a target="_blank" rel="noreferrer" href="https://penciled.com/Baa" style={{ textDecoration: "none", color: Colors.link }}>
                      Business Associate Agreement
                    </a>{" "}
                    regarding compliance with the Health Insurance Portability and Accountability Act.
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", justifyContent: { xs: "center", md: "right" } }}>
                  <PrimaryButton onClick={handleCreateAccount} disabled={!isFormValid()} sx={{ width: { xs: "90%", md: "40%" } }}>
                    {createAccountLoading ? <CircularProgress size={24} color="inherit" /> : "Next"}
                  </PrimaryButton>
                </Box>

                {errorMessage && (
                  <Typography variant="body1" sx={{ color: Colors.error }}>
                    {errorMessage}
                  </Typography>
                )}
              </FormBox>
            )}

            {currentStep === CreateAccountStep.VerifyEmail && (
              <FormBox>
                <Typography variant="h6" sx={{ marginBottom: "10px" }}>
                  A verification code has been sent to your email. Please enter the code below to verify your email.
                </Typography>
                <FormControl fullWidth sx={{ marginBottom: { xs: "10px", sm: "20px" } }}>
                  <Typography variant="h6" sx={{ fontSize: { xs: "0.9rem", md: "1rem" } }}>
                    Verification Code
                  </Typography>
                  <OutlinedInput
                    placeholder="Enter verification code"
                    value={verificationCode}
                    onChange={(e) => setVerificationCode(e.target.value)}
                    required
                  />
                </FormControl>

                {verificationError && (
                  <Typography variant="body2" sx={{ color: Colors.error }}>
                    {verificationError}
                  </Typography>
                )}

                <Box sx={{ display: "flex", justifyContent: "right", marginTop: "20px" }}>
                  <PrimaryButton onClick={handleVerifyEmail} disabled={verifyLoading}>
                    {verifyLoading ? <CircularProgress size={24} color="inherit" /> : "Verify Email"}
                  </PrimaryButton>
                </Box>
              </FormBox>
            )}

            {currentStep === CreateAccountStep.ApiKeyQuestion && (
              <FormBox>
                <Typography variant="h6" sx={{ marginBottom: "20px" }}>
                  Do you already have a Healthie API Key?
                </Typography>
                <RadioGroup aria-label="api-key" name="api-key" value={apiKeyAnswer} onChange={(e) => setApiKeyAnswer(e.target.value)}>
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                    onClick={() => posthog?.capture("[PENCILED] API key answer selected", { answer: apiKeyAnswer })}
                  />
                  <FormControlLabel
                    value="no"
                    control={<Radio />}
                    label="No"
                    onClick={() => {
                      const answerNo = async () => {
                        await handleCreateAgentWithoutEhrParameters();
                        posthog?.capture("[PENCILED] API key answer selected", { answer: apiKeyAnswer });
                      };
                      answerNo();
                    }}
                  />

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FormControlLabel
                      value="not-sure"
                      control={<Radio />}
                      label="Not Sure"
                      onClick={() => {
                        const answerNotSure = async () => {
                          await handleCreateAgentWithoutEhrParameters();
                          posthog?.capture("[PENCILED] API key answer selected", { answer: apiKeyAnswer });
                        };
                        answerNotSure();
                      }}
                    />
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://penciled.com/blogs/how-to-get-healthie-api-key"
                      onClick={() => posthog?.capture("[PENCILED] 'How do I get my API key?' link clicked")}
                      style={{ textDecoration: "none", color: Colors.link, fontFamily: "Foundry-Buch" }}
                    >
                      How do I get my API key?
                    </a>
                  </div>
                </RadioGroup>
                <Box sx={{ display: "flex", justifyContent: "right", marginTop: "20px" }}>
                  <PrimaryButton onClick={handleApiKeyResponse} disabled={apiLoading}>
                    {apiLoading ? <CircularProgress size={24} color="inherit" /> : "Next"}
                  </PrimaryButton>
                </Box>
              </FormBox>
            )}

            {currentStep === CreateAccountStep.Integrations && (
              <>
                <IntegrationsContainer>
                  <SignupIntegrationCard ehr={EHR.healthie} logo={healthieLogo} handleSubmit={handleCreateAgentWithEhrParameters} />
                </IntegrationsContainer>

                <Box sx={{ display: "flex", justifyContent: "left", marginTop: "0px" }}>
                  <TransparentButton onClick={handleBackToApiKeyQuestion}>
                    <ArrowBackIcon sx={{ marginRight: "5px" }} /> Back
                  </TransparentButton>
                </Box>
              </>
            )}
            {currentStep === CreateAccountStep.NoApiKeyMessage && <NoApiKeyMsg handleBackToApiKeyQuestion={handleBackToApiKeyQuestion} />}
          </>
        )}
      </ModalBox>
    </Box>
  );
};

export default CreateAccountForm;
