import React from 'react';
import { Box, Typography, IconButton, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { PrimaryButton } from './CustomButton';
import { Colors } from '../Colors';


export const HeaderBox: React.FC<{
  title: string;
  onClose: () => void;
}> = ({ title, onClose }) => (
  <Box
    sx={{
      position: 'fixed',
      top: 0,
      left: 0,
      padding: '12px 25px',
      width: '100%',
      display: 'flex',
      borderBottom: `2px solid ${Colors.textfield}`,
      background: '#151A33',
      justifyContent: 'space-between',
      alignItems: 'center',
      zIndex: 1000,
    }}
  >
    <Typography variant={'body2'} fontSize={'1.1rem'} fontWeight={'bold'}>
      {title}
    </Typography>
    <IconButton onClick={onClose} sx={{ color: '#FFFFFF' }}>
      <CloseIcon />
    </IconButton>
  </Box>
);

export const FooterBox: React.FC<{
  onClick: () => void;
  disabled?: boolean;
  buttonText: string;
  loading?: boolean;
}> = ({ onClick, disabled = false, buttonText, loading = false }) => (
  <Box
    sx={{
      position: 'fixed',
      bottom: 0,
      left: 0,
      padding: '12px',
      width: '100%',
      display: 'flex',
      borderTop: `2px solid ${Colors.textfield}`,
      background: '#151A33',
      justifyContent: 'flex-end',
      zIndex: 1000,
    }}
  >
    <PrimaryButton
      onClick={onClick}
      disabled={disabled}
      sx={{
        padding: '5px 15px',
        fontSize: '0.95rem',
        '&:disabled': {
          backgroundColor: '#31364B',
          color: Colors.info,
        },
      }}
    >
      {loading ? <CircularProgress size={24} color="inherit" /> : buttonText}
    </PrimaryButton>
  </Box>
);