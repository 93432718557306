import * as React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { FrontendUser, Organization, Agent } from "../types";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import SignoutHeader from '../subcomponents/SignoutHeader';
import DeleteAccountModal from '../confirmationModals/deleteAccountModal';
import { PrimaryButton } from '../subcomponents/CustomButton';
import { Colors } from '../Colors';

const Account: React.FC = () => {
    const [openModal, setOpenModal] = React.useState(false);
    const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
    const organization: Organization | null = useSelector((state: RootState) => state.auth.organization);
    const agents: Agent[] = useSelector((state: RootState) => state.agents.agents);

    const handleDeleteClick = () => {
        setOpenModal(true);
    };

    return (
        <Box sx={{color: '#FFF'}}>
            <SignoutHeader />
            <Typography variant='h5' sx={{ fontSize: { xs: "1.3rem", sm: "1.8rem" }, margin: { xs: "80px 0 10px 0", lg: "20px 0" } }}>My Account Info</Typography>

            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <Typography variant='h6' sx={{ fontSize: { xs: "0.9rem", sm: "1.1rem" }, margin: { xs: "20px 0 10px 0", md: "20px 0" } }}>Name: </Typography>
                <Typography variant='subtitle1' sx={{ fontSize: { xs: "0.9rem", sm: "1.1rem" }, margin: { xs: "20px 0 10px 0", md: "20px 0" } }}>{user?.firstName} {user?.lastName}</Typography>
            </Box>
            
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <Typography variant='h6' sx={{ fontSize: { xs: "0.9rem", sm: "1.1rem" }, margin: { xs: "20px 0 10px 0", md: "20px 0" } }}>Email: </Typography>
                <Typography variant='subtitle1' sx={{ fontSize: { xs: "0.9rem", sm: "1.1rem" }, margin: { xs: "20px 0 10px 0", md: "20px 0" } }}> {user?.email}</Typography>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <Typography variant='h6' sx={{ fontSize: { xs: "0.9rem", sm: "1.1rem" }, margin: { xs: "20px 0 10px 0", md: "20px 0" } }}>Organization: </Typography>
                <Typography variant="subtitle1" sx={{ fontSize: { xs: '0.9rem', sm: '1.1rem' }, margin: { xs: '20px 0 10px 0', md: '20px 0' } }}>
                    {organization?.name || "Organization Name Not Added"}
                </Typography>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <Typography variant='h6' sx={{ fontSize: { xs: "0.9rem", sm: "1.1rem" }, margin: { xs: "20px 0 10px 0", md: "20px 0" } }}>Callback Number: </Typography>
                <Typography variant="subtitle1" sx={{ fontSize: { xs: '0.9rem', sm: '1.1rem' }, margin: { xs: '20px 0 10px 0', md: '20px 0' } }}>
                    {organization?.phoneNumber || "Callback Number Not Added"}
                </Typography>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <Typography variant='h6' sx={{ fontSize: { xs: "0.9rem", sm: "1.1rem" }, margin: { xs: "20px 0 10px 0", md: "20px 0" } }}>Timezone: </Typography>
                <Typography variant="subtitle1" sx={{ fontSize: { xs: '0.9rem', sm: '1.1rem' }, margin: { xs: '20px 0 10px 0', md: '20px 0' } }}>
                    {organization?.timezone || "Timezone Not Found"}
                </Typography>
            </Box>

            {/* <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <Typography variant='h6' sx={{ fontSize: { xs: "0.9rem", sm: "1.1rem" }, margin: { xs: "20px 0 10px 0", md: "20px 0" } }}>Working Hours: </Typography>
                <Typography variant="subtitle1" sx={{ fontSize: { xs: '0.9rem', sm: '1.1rem' }, margin: { xs: '20px 0 10px 0', md: '20px 0' } }}>
                    {organization?.workingHoursStart && organization?.workingHoursEnd ? `Start: ${organization?.workingHoursStart} - End: ${organization?.workingHoursEnd}` : "Working Hours Not Found"}
                </Typography>
            </Box> */}
            
            {/* <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <Typography variant='h6' sx={{ fontSize: { xs: "0.9rem", sm: "1.1rem" }, margin: { xs: "20px 0 10px 0", md: "20px 0" } }}>EHR: </Typography>
                <Typography variant="subtitle1" sx={{ fontSize: { xs: '0.9rem', sm: '1.1rem' }, margin: { xs: '20px 0 10px 0', md: '20px 0' } }}>
                    {agents[0]?.ehr || "EHR Not Found"}
                </Typography>
            </Box> */}

            <PrimaryButton 
                onClick={handleDeleteClick} 
                sx={{
                    backgroundColor: Colors.error, 
                    padding: '8px 15px', 
                    "&:hover": {
                        backgroundColor: Colors.errorDark,
                    }
                }}>
                Delete Account
            </PrimaryButton>

            <DeleteAccountModal open={openModal} onClose={() => setOpenModal(false)} />
        </Box>
    );
};

export default Account;
