import axios from "axios";
import * as React from "react";
import Box from "@mui/material/Box";
import { useNavigate, useParams } from "react-router-dom";
import AudioComponent from "../subcomponents/AudioComponent";
import { Call, CallStatuses, FrontendUser, OutcomeSentiment } from "../types";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { useEffect, useState } from "react";
import SignoutHeader from "../subcomponents/SignoutHeader";
import Typography from "@mui/material/Typography";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { SecondaryButton, TransparentButton } from "../subcomponents/CustomButton";
import { Colors } from "../Colors";
import { useSnackbar } from "../providers/SnackbarProvider";
import { DateTime } from "luxon";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Loader from "../subcomponents/Loader";
import { callStatusColor, callStatusDisplay, convertSnakeCaseToOfficial, getOutcomeColor } from "../utils/utils";
import { OvalOutlineListItem } from "../styles/GeneralStyles";
import ListItemIcon from "@mui/material/ListItemIcon";
import { ListItemText } from "@mui/material";
import CallIcon from '@mui/icons-material/Call';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import NotesIcon from '@mui/icons-material/Notes';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import QuestionMarkIcon from '@mui/icons-material/Help';

const CallInfo: React.FC = () => {
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const [call, setCall] = useState<Call | null>(null);
  const [transcript, setTranscript] = useState<string | null>(null);
  const [transcriptLines, setTranscriptLines] = useState<any[]>([]);
  const [showOutcomeJustification, setShowOutcomeJustification] = useState(false);
  const [showTranscript, setShowTranscript] = useState(false);

  const { callId } = useParams<{ callId: string }>();
  const { showMessage } = useSnackbar();
  const navigate = useNavigate();

  const [callLoading, setCallLoading] = useState(true);

  const getCall = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/calls/${callId}?getAgentName=true`, {
        headers: { Authorization: `Bearer ${user?.token}` },
      });
      return response.data.call;
    } catch (error) {
      console.error(error);
      showMessage("Failed to get call. Please try again later.", "error");
    }
  };

  const getTranscript = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/calls/${callId}/transcript`, {
        headers: { Authorization: `Bearer ${user?.token}` },
      });
      return response.data.transcript?.transcript;
    } catch (error) {
      console.error(error);
      showMessage("Failed to get transcript. Please try again later.", "error");
    }
  };

  const loadCall = async () => {
    setCallLoading(true);
    const call = await getCall();
    setCall(call);
    setCallLoading(false);
    const transcript = await getTranscript();
    setTranscript(transcript);
  };

  useEffect(() => {
    loadCall();
  }, []);

  useEffect(() => {
    if (transcript) {
      const lines = transcript.split("\n").map((line: string, index: number) => {
        const isAI = line.trim().startsWith("assistant:");
        const isUser = line.trim().startsWith("user:");
        const isAction = line.trim().startsWith("agent-action:");

        const cleanedLine = line.replace(/(assistant:|user:)/, "").trim();

        return (
          <Box
            key={index}
            sx={{
              display: "flex",
              justifyContent: isAI ? "flex-end" : isUser ? "flex-start" : "center",
            }}
          >
            <Typography
              component="div"
              variant="body1"
              sx={{
                backgroundColor: isAI ? "#007AFF" : isUser ? Colors.bgTextMsg : "transparent",
                color: isAI || isUser ? "#FFF" : Colors.warning,
                padding: "10px 15px",
                borderRadius: "15px",
                fontSize: "0.9rem",
                fontWeight: isAction ? "bold" : "normal",
                maxWidth: "75%",
                marginBottom: "15px",
              }}
            >
              <strong style={{ color: isUser ? Colors.primary : "#EEE" }}>
                {isAI ? "Agent: " : isUser ? "Patient: " : ""}
              </strong>
              <br /> {cleanedLine}
            </Typography>
          </Box>
        );
      });
      setTranscriptLines(lines);
    } else {
      setTranscriptLines([]);
    }
  }, [transcript]);

  const toggleOutcomeJustification = () => {
    setShowOutcomeJustification(!showOutcomeJustification);
  };

  const toggleTranscript = () => {
    setShowTranscript(!showTranscript);
  };

  const handleBack = () => {
    const currentPath = location.pathname;
    const pathParts = currentPath.split("/").slice(0, -2).join("/"); // removes /calls/:callId from the end of the url
    navigate(pathParts || "/dashboard");
  };

  const renderIconBasedOnOutcome = (outcome: string) => {
    switch (outcome) {
      case "accepted":
        return <CheckCircleIcon style={{ color: Colors.success }} />;
      case "declined":
      case "no-valid-patients":
      case "booking-failed":
        return <CloseIcon style={{ color: Colors.error }} />;
      default:
        return <QuestionMarkIcon style={{ color: "#FFF" }} />;
    }
  };

  // TODO (Hizami): Handle when call is null
  const callDate = DateTime.fromISO(call?.startedAt || "");
  const date = callDate.toFormat("M/d");
  const time = callDate.toFormat("h:mm a");

  const summary = call?.summary;
  const outcomeJustification = call?.outcomeJustification;
  const patientName = call?.patientFirstName + " " + call?.patientLastName;
  const patientPhoneNumber = call?.patientPhoneNumber;
  const type = "Phone call";
  const outcome = call?.outcome as string;
  const outcomeSentiment = call?.outcomeSentiment as OutcomeSentiment;
  const status = call?.status as keyof typeof CallStatuses;
  const agentName = call?.agentName;

  return (
    <Box sx={{ margin: { xs: "65px 0 20px 0", lg: "0" } }}>
      <SignoutHeader />
      <SecondaryButton onClick={handleBack} sx={{ display: { xs: "none", lg: "flex" }, alignItems: "center", margin: "20px 0" }}>
        <ArrowBackIcon sx={{ marginRight: "10px" }} /> Back
      </SecondaryButton>

      {callLoading ? (
        <Loader message="" size={60} customStyles={{ height: "100%", marginTop: "30vh" }} />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            marginTop: { xs: "70px", md: "30px" },
            gap: "30px",
            color: "#FFF",
          }}
        >
          <Box sx={{ width: { xs: "100%", md: "60%", lg: "60%" } }}>
            <div style={{ display: "flex", alignItems: "baseline", lineHeight: 0 }}>
              <Typography variant="h4" sx={{ fontSize: { xs: "1.4rem", sm: "1.75rem" } }}>
                {patientName}
              
              <span style={{ marginLeft: "7px", fontFamily: "Konnect-Regular", fontWeight: "normal", fontSize: "0.9rem",}}>
                {date} at {time}
              </span>
              </Typography>
            </div>

            <Typography variant="h6" mb={2} sx={{ color: Colors.primary, fontSize: { xs: "1rem", sm: "1.2rem" } }}>
              {type} with {agentName}
            </Typography>

            <OvalOutlineListItem maxWidth="380px">
              <ListItemIcon>
                <CallIcon style={{ color: "#FFF" }} />
              </ListItemIcon>
              <ListItemText
                primary="Patient Phone Number"
                secondary={patientPhoneNumber}
                secondaryTypographyProps={{ color: Colors.info }}
              />
            </OvalOutlineListItem>

            <OvalOutlineListItem maxWidth="380px">
              <ListItemIcon>
                <FingerprintIcon style={{ color: "#FFF" }} />
              </ListItemIcon>
              <ListItemText
                primary="Call ID"
                secondary={callId}
                secondaryTypographyProps={{ color: Colors.info }}
              />
            </OvalOutlineListItem>

            <OvalOutlineListItem maxWidth="380px">
              <ListItemIcon>
                <CallIcon style={{ color: "#FFF" }} />
              </ListItemIcon>
              <ListItemText
                primary="Call Status"
                secondary={callStatusDisplay(status as CallStatuses)}
                secondaryTypographyProps={{ color: callStatusColor(status as CallStatuses), textTransform: "capitalize", fontSize: "1.1rem", fontFamily: "Konnect-SemiBold" }}
              />
            </OvalOutlineListItem>

            <OvalOutlineListItem maxWidth="380px">
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <ListItemIcon>
                  {renderIconBasedOnOutcome(outcome)}
                </ListItemIcon>
                <ListItemText 
                  primary={<span>Call Outcome: <span style={{fontFamily: 'Konnect-SemiBold', color: getOutcomeColor(outcomeSentiment) }}>{convertSnakeCaseToOfficial(outcome)}</span></span>} 
                  secondary= {outcomeJustification}
                  secondaryTypographyProps={{ color: Colors.info }} 
                />
              </Box>
            </OvalOutlineListItem>

            <OvalOutlineListItem maxWidth="380px">
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <ListItemIcon>
                  <NotesIcon style={{ color: "#FFF" }} />
                </ListItemIcon>
                <ListItemText 
                  primary="Summary"
                  secondary= {summary}
                  secondaryTypographyProps={{ color: Colors.info }} 
                />
              </Box>
            </OvalOutlineListItem>
          </Box>

          <Box sx={{ marginTop: { xs: "30px", sm: "0px" }, width: { xs: "100%", sm: "40%", md: "45%", lg: "50%" } }}>
            <div>
            <Typography variant={'h6'} sx={{ marginBottom: 1 }}>Call recording</Typography>
              <AudioComponent audioURL={`${process.env.REACT_APP_BACKEND_URL}/api/calls/${callId}/streamMp3File`} />
            </div>
            <SecondaryButton onClick={toggleTranscript} style={{ marginTop: "20px", fontFamily: "sans-serif", fontWeight: "bold" }}>
              {showTranscript ? "Hide Call Transcript" : "View Call Transcript"}
            </SecondaryButton>

            {showTranscript && (
              <div style={{ 
                backgroundColor: Colors.bg2, 
                padding: "20px", 
                borderRadius: "20px", 
                marginTop: "15px",
                overflowY: "auto",
                maxHeight: "45vh",
                }}
              >
                {transcriptLines}
              </div>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CallInfo;
