import axios from "axios";
import * as React from "react";
import Box from "@mui/material/Box";
import { useNavigate, useParams } from "react-router-dom";
import AudioComponent from "../subcomponents/AudioComponent";
import { Call, CallStatuses, FrontendUser, OutcomeSentiment } from "../types";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { useEffect, useState } from "react";
import SignoutHeader from "../subcomponents/SignoutHeader";
import Typography from "@mui/material/Typography";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { TransparentButton } from "../subcomponents/CustomButton";
import { Colors } from "../Colors";
import { useSnackbar } from "../providers/SnackbarProvider";
import { DateTime } from "luxon";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Loader from "../subcomponents/Loader";
import {
  callStatusColor,
  callStatusDisplay,
  convertSnakeCaseToOfficial,
  formatIsoToCustomDateStringWithEEEEHHMMA,
  getOutcomeColor,
} from "../utils/utils";
import { set } from "date-fns";
import LoadingWithMessage from "../subcomponents/LoadingWithMessage";
import TextThreadDisplay from "../components/TextThreadDisplay";

const TextInfo: React.FC = () => {
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const { textId } = useParams<{ agentId: string; textId: string }>();

  const { showMessage } = useSnackbar();
  const navigate = useNavigate();

  const [text, setText] = useState<any | undefined>(undefined);
  const [textLoading, setTextLoading] = useState(true);

  const [showOutcomeJustification, setShowOutcomeJustification] = useState(false);

  const getText = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/texts/${textId}`, {
        headers: { Authorization: `Bearer ${user?.token}` },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      showMessage("Failed to get text. Please try again later.", "error");
    }
  };

  const loadText = async () => {
    setTextLoading(true);
    const text = await getText();
    setText(text);
    setTextLoading(false);
  };

  const handleBack = () => {
    const currentPath = location.pathname;
    const pathParts = currentPath.split("/").slice(0, -2).join("/"); // removes /calls/:callId from the end of the url
    navigate(pathParts || "/dashboard");
  };

  const toggleOutcomeJustification = () => {
    setShowOutcomeJustification(!showOutcomeJustification);
  };

  useEffect(() => {
    loadText();
  }, []);

  // TODO (Hizami): I am getting these from variables
  const patientName = text?.variables.patientFirstName + " " + text?.variables.patientLastName;
  const patientPhoneNumber = text?.patientPhoneNumber;
  const type = "Text";
  const outcome = text?.state;
  const outcomeSentiment = text?.outcomeSentiment;
  const stateJustification = text?.stateJustification;
  const agentName = text?.variables.assistantName;

  return (
    <Box sx={{ margin: { xs: "65px 0 20px 0", lg: "0" } }}>
      <SignoutHeader />
      <TransparentButton onClick={handleBack} sx={{ display: { xs: "none", lg: "flex" }, alignItems: "center", margin: "20px 0" }}>
        <ArrowBackIcon sx={{ marginRight: "10px" }} /> Back
      </TransparentButton>
      {textLoading ? (
        <LoadingWithMessage message="Loading text" size={60} />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            marginTop: { xs: "70px", md: "30px" },
            gap: "30px",
          }}
        >
          <Box sx={{ width: { xs: "100%", md: "60%", lg: "60%" } }}>
            <div style={{ display: "flex", alignItems: "baseline", lineHeight: 0 }}>
              <Typography variant="h4" sx={{ fontSize: { xs: "1.4rem", sm: "1.75rem" } }}>
                {patientName}
              </Typography>
            </div>

            <Typography variant="h6" sx={{ color: Colors.primary, fontSize: { xs: "1rem", sm: "1.2rem" } }}>
              {type} with {agentName}
            </Typography>

            <Typography variant="subtitle1" sx={{ fontSize: { xs: "1rem", sm: "0.9rem" } }}>
              Patient Phone Number: {patientPhoneNumber}
            </Typography>

            <Box onClick={toggleOutcomeJustification} sx={{ marginBottom: "10px", cursor: "pointer", display: "flex", alignItems: "center", gap: 1 }}>
              <Typography sx={{ display: "flex", alignItems: "center" }}>
                {showOutcomeJustification ? <KeyboardArrowDownIcon /> : <ChevronRightIcon />}
                Text Outcome:{" "}
              </Typography>

              <Typography
                variant="h6"
                style={{ color: getOutcomeColor(outcomeSentiment), textTransform: "capitalize", fontSize: "1.1rem", marginBottom: "-3px" }}
              >
                {outcome}
              </Typography>
            </Box>

            {showOutcomeJustification && (
              <Typography
                variant="subtitle2"
                sx={{ marginBottom: "20px", width: { xs: "100%", sm: "350px" }, fontSize: { xs: "1rem", sm: "0.8rem" }, padding: "0 10px 0 25px" }}
              >
                {stateJustification}
              </Typography>
            )}
          </Box>

          {textId && (
            <Box sx={{ marginTop: { xs: "30px", sm: "0px" }, width: { xs: "100%", sm: "40%", md: "45%", lg: "40%" } }}>
              <TextThreadDisplay text={text} />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default TextInfo;
