import { Box, Modal, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { sessionExpired } from "../slices/SessionSlice";
import { PrimaryButton } from "../subcomponents/CustomButton";
import styled from "styled-components";
import { useAppDispatch } from "../store";

const ModalBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 30%;
  margin: 10% auto;
  background-color: #fff;
  padding: 2%;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);

  @media (max-width: 768px) {
    width: 70%;
    padding: 5%;
  }
`;

const SessionExpiredModal: React.FC<{ open: boolean }> = ({ open }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleLoginRedirect = () => {
    dispatch(sessionExpired(false));
    navigate("/login");
  };

  return (
    <Modal open={open}>
      <ModalBox sx={{ textAlign: "center" }}>
        <Typography variant="h5" fontFamily={"Konnect-SemiBold"} mb={2}>
          Session Expired
        </Typography>
        <Typography variant="subtitle1" fontFamily={"Foundry-Buch"} mb={3}>
          Your session has expired due to inactivity. Please log in again.
        </Typography>
        <PrimaryButton onClick={handleLoginRedirect} sx={{ fontFamily: "Konnect-SemiBold", width: "50%", margin: "auto" }}>
          Sign Out
        </PrimaryButton>
      </ModalBox>
    </Modal>
  );
};

export default SessionExpiredModal;
