import React, { ElementType, useEffect } from 'react';
import styled from 'styled-components';
import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { useDispatch, useSelector } from 'react-redux';
import { StyledDisconnectButton, StyledIntegrateButton, IntegrationCard, StyledFormBox } from '../../styles/CalendlyStyles';
import { AppDispatch, RootState } from '../../store';
import { checkCalendlyIntegration, disconnectCalendly, fetchCalendlyMeetingNames, updateCalendlyMeetingName } from '../../slices/CalendlySlice';
import LoadingWithMessage from '../../subcomponents/LoadingWithMessage';
import { PrimaryButton } from '../../subcomponents/CustomButton';
import { Colors } from '../../Colors';

interface IntegrationCardProps {
  Icon: ElementType;
  name: string;
  subtitle: string;
}

const CalendlyIntegrationCard: React.FC<IntegrationCardProps> = ({ Icon, name, subtitle }) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    isIntegrated,
    trackedMeetingNames,
    selectedMeetingName,
    loading,
    loadingMsg,
  } = useSelector((state: RootState) => state.calendly);

  useEffect(() => {
    const initializeIntegrationStatus = async () => {
      try {
        const isIntegrated = await dispatch(checkCalendlyIntegration()).unwrap();
        if (isIntegrated) {
          await dispatch(fetchCalendlyMeetingNames()).unwrap();
        }
      } catch (error: any) {
        console.error('Error initializing Calendly integration:', error);
      }
    };

    initializeIntegrationStatus();
  }, [dispatch]);

  const handleDisconnectCalendly = async () => {
    try {
      await dispatch(disconnectCalendly()).unwrap();
    } catch (error: any) {
      console.error('Error disconnecting from Calendly:', error);
    }
  };

  const handleChange = async (event: SelectChangeEvent) => {
    try {
      await dispatch(updateCalendlyMeetingName({ meetingName: event.target.value as string })).unwrap();
    } catch (error: any) {
      console.error('Error updating meeting name:', error);
    }
  };

  const handleCalendlyIntegration = () => {
    if (!loading && !isIntegrated) {
      const clientId = process.env.REACT_APP_CALENDLY_CLIENT_ID;
      const calendlyRedirectUrl = `${process.env.REACT_APP_FRONTEND_URL}/calendly/auth`;
      const redirectUri = encodeURIComponent(calendlyRedirectUrl);
      const calendlyAuthUrl = `https://auth.calendly.com/oauth/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}`;
      window.location.href = calendlyAuthUrl;
    }
  };

  const StyledIcon = styled(Icon)`
    width: 250px;
    height: 255px;
    margin: -80px 0 -110px 0;
  `;

  return (
    <Box
      sx={{
        padding: "25px",
        backgroundColor: Colors.bg2,
        height: "280px",
        width: { xs: "100%", md: "45%", lg: "32%" },
        borderRadius: "20px",
        marginRight: "20px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
        color: "white",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between", gap: "15px", alignItems: "center" }}>
        <Typography variant="h6">{name}</Typography>
        <div
          style={{
            backgroundColor: isIntegrated ? Colors.success : 'grey',
            color: "white",
            fontSize: "0.65rem",
            padding: "3px 10px",
            borderRadius: "25px",
          }}
        >
          {isIntegrated ? "Connected" : "Not connected"}
        </div>
      </div>

      <StyledIcon />
      
      {/* <p>{subtitle}</p> */}

      {loading ? (
        <LoadingWithMessage message={loadingMsg ?? 'Loading...'} size={20} />
      ) : (
        <>
          {isIntegrated ? (
            <>
              <StyledFormBox sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="select-meeting-name-label">Meeting name</InputLabel>
                  <Select
                    labelId="select-meeting-name-label"
                    id="select-meeting-name"
                    value={selectedMeetingName}
                    label={selectedMeetingName || 'Tracked meeting name'}
                    onChange={handleChange}
                    sx={{
                      marginBottom: "5px",
                      borderRadius: "10px",
                      color: "#FFFFFF",
                      background: Colors.textfield,
                      "& .MuiInputBase-input": {
                        padding: "15px",            
                        color: "#FFFFFF",
                      },    
                      "& .MuiSvgIcon-root": {
                        color: "#FFF",
                      },               
                    }}
                  >
                    {trackedMeetingNames?.map((name) => (
                      <MenuItem key={name} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </StyledFormBox>
              <PrimaryButton onClick={handleDisconnectCalendly} style={{background: Colors.error, width: '100%'}}>
                Disconnect
              </PrimaryButton>
            </>
          ) : (
            <PrimaryButton onClick={handleCalendlyIntegration} style={{width: '100%', marginTop: '40px'}}>
              Connect
            </PrimaryButton>
          )}
        </>
      )}
    </Box>
  );
};

export default CalendlyIntegrationCard;
