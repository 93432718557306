import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../store";
import { HealthieWaitlistPatient, RawHealthiePatient } from "../types";
import { convertToCallablePhoneNumber } from "../utils/utils";

export interface PatientState {
  patients: HealthieWaitlistPatient[];
  loading: boolean;
  loadingMsg: string | undefined;
  errorMsg: string | undefined;
}

const initialState: PatientState = {
  patients: [],
  loading: false,
  loadingMsg: "",
  errorMsg: "",
};

export const getWaitlistPatients = createAsyncThunk<HealthieWaitlistPatient[], void, { rejectValue: Error }>(
  "waitlist/getWaitlistPatients",
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const user = state.auth.user;

      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/patients/waitlist`, {
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      });

      if (response.status !== 200) {
        return rejectWithValue(new Error("Failed to fetch waitlist patients"));
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(
        new Error("Failed to fetch waitlist patients: " + (error as any)?.response?.data?.error || (error as Error).message || "Unknown error")
      );
    }
  }
);

export const updatePatient = createAsyncThunk<void, { patientId: string; params: any }, { rejectValue: Error }>(
  "waitlist/updatePatient",
  async ({ patientId, params }, { dispatch, getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const user = state.auth.user;

      if (!user || !user.token) {
        throw new Error("User not logged in");
      }

      const response = await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/api/patients/waitlist/${patientId}`,
        {
          ...params,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      if (response.status !== 200) {
        throw new Error("Failed to update patient");
      }

      // Dispatch getWaitlistPatient to fetch the updated patient data
      await dispatch(getWaitlistPatient({ patientId }));
    } catch (error: any) {
      return rejectWithValue(new Error("Failed to update patient: " + (error.response?.data?.error || error.message || "Unknown error")));
    }
  }
);

export const getWaitlistPatient = createAsyncThunk<HealthieWaitlistPatient, { patientId: string }, { rejectValue: Error }>(
  "waitlist/getWaitlistPatient",
  async ({ patientId }, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const user = state.auth.user;

      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/patients/waitlist/${patientId}`, {
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      });

      if (response.status !== 200) {
        return rejectWithValue(new Error("Failed to fetch patient details"));
      }

      return response.data.patient;
    } catch (error) {
      return rejectWithValue(
        new Error("Failed to fetch patient details: " + (error as any)?.response?.data?.error || (error as Error).message || "Unknown error")
      );
    }
  }
);

export const deletePatient = createAsyncThunk<string, { patientId: string }, { rejectValue: Error }>(
  "waitlist/deletePatient",
  async ({ patientId }, { dispatch, getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const user = state.auth.user;

      const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/patients/${patientId}`, {
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      });

      if (response.status !== 200) {
        return rejectWithValue(new Error("Failed to delete patient"));
      }

      // Fetch updated waitlist patients after deletion
      await dispatch(getWaitlistPatients());

      return patientId;
    } catch (error) {
      return rejectWithValue(
        new Error("Failed to delete patient: " + (error as any)?.response?.data?.error || (error as Error).message || "Unknown error")
      );
    }
  }
);

export const createPatient = createAsyncThunk<
  HealthieWaitlistPatient,
  {
    selectedHealthiePatient: RawHealthiePatient;
    timezone: string;
    language: string;
    textEnabled: boolean;
    callEnabled: boolean;
    availableDates: string[];
    selectedProviders: string[];
    selectedAppointmentTypes: string[];
    selectedContactTypes: string[];
  },
  { rejectValue: Error }
>(
  "waitlist/createPatient",
  async (
    {
      selectedHealthiePatient,
      timezone,
      language,
      textEnabled,
      callEnabled,
      availableDates,
      selectedProviders,
      selectedAppointmentTypes,
      selectedContactTypes,
    },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const state = getState() as RootState;
      const user = state.auth.user;

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/patients`,
        {
          healthiePatientId: selectedHealthiePatient?.healthiePatientId,
          firstName: selectedHealthiePatient?.firstName,
          lastName: selectedHealthiePatient?.lastName,
          phoneNumber: convertToCallablePhoneNumber(selectedHealthiePatient?.phoneNumber),
          email: selectedHealthiePatient?.email,
          timezone,
          language,
          textEnabled,
          callEnabled,
          availableDates: availableDates,
          providerIds: selectedProviders,
          appointmentTypeIds: selectedAppointmentTypes,
          contactTypes: selectedContactTypes,
          dob: selectedHealthiePatient?.dob,
        },
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );

      dispatch(addPatient(response.data.patient));

      if (response.status !== 201) {
        return rejectWithValue(new Error("Failed to create patient"));
      }

      return response.data.patient;
    } catch (error: any) {
      return rejectWithValue(new Error("Failed to create patient: " + (error.response?.data?.error || error.message || "Unknown error")));
    }
  }
);

export const updatePriorities = createAsyncThunk<void, { patients: HealthieWaitlistPatient[] }, { rejectValue: Error }>(
  "waitlist/updatePriorities",
  async ({ patients }, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const user = state.auth.user;

      const priorities = patients.map((patient, index) => ({
        patientId: patient.patientId,
        priority: index + 1,
      }));

      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/patients/priorities`, priorities, {
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      });

      if (response.status !== 200) {
        return rejectWithValue(new Error("Failed to update priorities"));
      }
    } catch (error) {
      return rejectWithValue(
        new Error("Failed to update priorities: " + (error as any)?.response?.data?.error || (error as Error).message || "Unknown error")
      );
    }
  }
);

const patientSlice = createSlice({
  name: "patientSlice",
  initialState,
  reducers: {
    addPatient: (state, action: PayloadAction<HealthieWaitlistPatient>) => {
      state.patients.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPatient.pending, (state) => {
        state.loading = true;
        state.loadingMsg = "Creating patient...";
        state.errorMsg = "";
      })
      .addCase(createPatient.fulfilled, (state, action) => {
        state.loading = false;
        state.loadingMsg = "";
      })
      .addCase(createPatient.rejected, (state, action) => {
        state.loading = false;
        state.loadingMsg = "";
        state.errorMsg = action.payload?.message;
      })
      .addCase(updatePatient.pending, (state) => {
        state.loading = true;
        state.loadingMsg = "Updating patient...";
        state.errorMsg = "";
      })
      .addCase(updatePatient.fulfilled, (state) => {
        state.loading = false;
        state.loadingMsg = "";
        // The patient object will be updated by getWaitlistPatient, so no direct changes here
      })
      .addCase(updatePatient.rejected, (state, action) => {
        state.loading = false;
        state.loadingMsg = "";
        state.errorMsg = action.payload?.message;
      })
      .addCase(getWaitlistPatient.pending, (state) => {
        state.loading = true;
        state.loadingMsg = "Loading patient details...";
        state.errorMsg = "";
      })
      .addCase(getWaitlistPatient.fulfilled, (state, action) => {
        state.loading = false;
        state.loadingMsg = "";

        const index = state.patients.findIndex((patient) => patient.patientId === action.payload.patientId);

        if (index !== -1) {
          state.patients[index] = action.payload;
        } else {
          state.patients.push(action.payload);
        }
      })
      .addCase(getWaitlistPatients.pending, (state) => {
        state.loading = true;
        state.loadingMsg = "Loading waitlist patients...";
        state.errorMsg = "";
      })
      .addCase(getWaitlistPatients.fulfilled, (state, action) => {
        state.loading = false;
        state.loadingMsg = "";
        state.patients = action.payload;
      })
      .addCase(getWaitlistPatients.rejected, (state, action) => {
        state.loading = false;
        state.loadingMsg = "";
        state.errorMsg = action.payload?.message;
      })
      .addCase(deletePatient.pending, (state) => {
        state.loading = true;
        state.loadingMsg = "Deleting patient...";
        state.errorMsg = "";
      })
      .addCase(deletePatient.fulfilled, (state, action) => {
        state.loading = false;
        state.loadingMsg = "";
        state.patients = state.patients.filter((patient) => patient.patientId !== action.payload);
      })
      .addCase(deletePatient.rejected, (state, action) => {
        state.loading = false;
        state.loadingMsg = "";
        state.errorMsg = action.payload?.message;
      })
      .addCase(updatePriorities.pending, (state) => {
        state.loading = true;
        state.loadingMsg = "Updating priorities...";
        state.errorMsg = "";
      })
      .addCase(updatePriorities.fulfilled, (state) => {
        state.loading = false;
        state.loadingMsg = "";
      })
      .addCase(updatePriorities.rejected, (state, action) => {
        state.loading = false;
        state.loadingMsg = "";
        state.errorMsg = action.payload?.message;
      });
  },
});

export const { addPatient } = patientSlice.actions;

export const selectWaitlistState = (state: RootState) => state.patients;

export default patientSlice.reducer;
