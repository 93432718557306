import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Agent, FrontendUser, FrontendScheduledCall, CSVPatient, Organization } from "../types";
import { Typography, Modal, Tooltip, CircularProgress, IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import { useParams, useSearchParams } from "react-router-dom";
import NewPatientForm from "../forms/NewPatientForm";
import { PrimaryButton, SecondaryButton, TransparentButton } from "../subcomponents/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../store";
import { Colors } from "../Colors";
import { useSnackbar } from "../providers/SnackbarProvider";
import { DateTime } from "luxon";
import { nextAgentScheduledCallsPaginated, refreshAgentScheduledCallsPaginated } from "../slices/ScheduledCallSlice";
import ConversationQueueRow from "./rows/ConversationQueueRow";
import styled from "styled-components";
import Papa from "papaparse";
import UploadCSVModal from "../forms/UploadCSV";
import MatchColumnsModal from "../forms/MatchColumnsModal";
import { usePostHog } from "posthog-js/react";
import ScheduleCSVModal from "../forms/ScheduleCSVModal";
import { selectUploadCSV, sendPatientsToServer, setPatients } from "../slices/UploadCSVSlice";
import { EHR } from "../types";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Sidebar } from "../styles/GeneralStyles";

const RightAlignedSpan = styled.span`
  display: inline-block;
  margin-left: auto;
  display: flex;
  align-items: center;
  flex-direction: row;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
`;

const ConversationQueue: React.FC = () => {
  const callModalOpen = useSearchParams()[0].get("call") === "true"; // set to true if the user has just created a new account
  const { agentId } = useParams<{ agentId: string }>();
  const agent: Agent | undefined = useSelector((state: RootState) => state.agents.agents.find((agent) => agent.agentId === agentId));
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const organization: Organization | null = useSelector((state: RootState) => state.auth.organization);
  const scheduledCalls: FrontendScheduledCall[] = useSelector((state: RootState) => state.scheduledCalls.scheduledCalls);
  const [isAddPatientModalOpen, setIsAddPatientModalOpen] = useState(callModalOpen);

  // upload CSV
  const [uploadCSVModalOpen, setUploadCSVModalOpen] = useState<boolean>(false);
  const [matchingColumnsModalOpen, setMatchingColumnsModalOpen] = useState<boolean>(false);
  const [columns, setColumns] = useState<any>([]);
  const [dataRowsCount, setDataRowsCount] = useState(0);
  const [dataRows, setDataRows] = useState<any[]>([]);
  const { patients, patientPhoneNumbersToScheduledDate, overallSchedule, patientPhoneNumbersToOverallSchedule, uploadCSVLoading } =
    useSelector(selectUploadCSV);
  const [uploadCSVLoadingMessage, setUploadCSVLoadingMessage] = useState<string>("");

  const dispatch = useAppDispatch();
  const [index, setIndex] = React.useState<number>(0);
  const lastEvaluatedKey = useSelector((state: RootState) => state.scheduledCalls.lastEvaluatedKey);
  const datesFilter = undefined;
  const finalPage = useSelector((state: RootState) => state.scheduledCalls.finalPage);
  const [isDisabled, setIsDisabled] = useState(false);
  const [tooltipMessage, setTooltipMessage] = useState("");
  const posthog = usePostHog();
  const [scheduleCSVModalOpen, setScheduleCSVModalOpen] = useState<boolean>(false);
  const [warningMessage, setWarningMessage] = useState<string>("");

  const { showMessage } = useSnackbar();

  useEffect(() => {
    if (patients.length === 1) {
      setUploadCSVLoadingMessage(`Scheduling call...`);
    } else if (patients.length > 1) {
      setUploadCSVLoadingMessage(`Scheduling ${patients.length} calls...`);
    }
  }, [uploadCSVLoading, patients.length]);

  useEffect(() => {
    if (!agent) {
      return;
    }
    if (agent.ehr === EHR.healthie && !organization?.integrations?.includes(EHR.healthie)) {
      // if healthie is the EHR, but there isn't an integration token live, then that needs to be added from the Integrations tab
      setIsDisabled(true);
      setTooltipMessage("EHR integration required. Please add the Healthie integration from the Integrations page.");
    } else if (agent.ehr === EHR.healthie && (!agent.ehrProviderId || !agent.ehrProviderId)) {
      // if healthie is the EHR, then the ehr-specific questions need to be answered
      setIsDisabled(true);
      setTooltipMessage("EHR-specific context required. Please answer the EHR integration questions in the Configuration page.");
    } else if (agent.ehr === EHR.none) {
      setIsDisabled(false);
      setTooltipMessage("");
    } else {
      setIsDisabled(false);
      setTooltipMessage("");
    }
  }, [agent?.ehrProviderId, agent?.ehrApptTypeId, agent?.ehr, organization?.integrations]);

  const pageSize = 8;
  const handleOpenAddPatientModal = () => {
    setIsAddPatientModalOpen(true);
  };

  const handleCloseAddPatientModal = () => {
    setIsAddPatientModalOpen(false);
  };

  const handleFileChange = (file: File) => {
    if (file) {
      Papa.parse(file, {
        complete: function (results: any) {
          const headers = results.data[0];
          const dataRows = results.data.slice(1); // Exclude the header row

          // Initialize columns with header names
          const columns = headers.map((header: any) => ({
            name: header,
            data: [],
          }));

          // Generate mappings with indices
          const headerMappings = headers.map((header: any, index: any) => ({
            name: header,
            index: index,
            type: header,
          }));

          let nonEmptyRowCount = 0;

          dataRows.forEach((row: any) => {
            let isRowEmpty = row.every((cell: any) => cell.trim() === ""); // Check if every cell in the row is empty

            if (!isRowEmpty) {
              nonEmptyRowCount++;
              columns.forEach((column: any, index: number) => {
                column.data.push(row[index]);
              });
            }
          });

          setColumns(columns);
          setDataRowsCount(nonEmptyRowCount);
          setDataRows(dataRows);
        },
        header: false,
      });
      setUploadCSVModalOpen(false);
      setMatchingColumnsModalOpen(true);
    }
  };

  const getCallsPaginatedInitial = async (dates?: [DateTime | null, DateTime | null]) => {
    if (!user?.token) {
      showMessage("Session is expired. Please refresh.", "error");
      return;
    }
    dispatch(refreshAgentScheduledCallsPaginated({ agentId: agentId || "", dateRange: dates }));
    setIndex(0);
  };

  const refreshCalls = async () => {
    if (!user?.token) {
      showMessage("Session is expired. Please refresh.", "error");
      return;
    }
    dispatch(refreshAgentScheduledCallsPaginated({ agentId: agentId || "", dateRange: datesFilter }));
    setIndex(0);
  };

  const getCallsPaginated = async () => {
    if (!user?.token) {
      showMessage("Session is expired. Please refresh.", "error");
      return;
    }
    dispatch(nextAgentScheduledCallsPaginated({ token: user?.token, agentId: agentId || "", lastEvaluatedKey, dateRange: datesFilter }));
    setIndex(index + 1);
  };

  const prevPage = () => {
    if (index > 0) {
      setIndex(index - 1);
    }
  };

  const nextPage = () => {
    // check to see if we already have the calls loaded
    if (scheduledCalls && scheduledCalls.length > (index + 1) * pageSize) {
      setIndex(index + 1);
    } else if (!finalPage) {
      getCallsPaginated();
    }
  };

  useEffect(() => {
    getCallsPaginatedInitial();
  }, []);

  const endOfList = scheduledCalls ? scheduledCalls.length <= (index + 1) * pageSize : false;
  const pageCalls = scheduledCalls ? scheduledCalls.slice(index * pageSize, (index + 1) * pageSize) : [];

  const handleUploadCSVSuccess = async (fullData: any) => {
    try {
      const patients = fullData.map((row: any) => {
        if (!row?.[0] || !row?.[1] || !row?.[2]) {
          throw new Error("Missing required fields");
        }
        const patient: CSVPatient = {
          patientFirstName: row?.[0],
          patientLastName: row?.[1],
          patientPhoneNumber: row?.[2],
        };
        return patient;
      });

      await dispatch(setPatients(patients));
      setMatchingColumnsModalOpen(false);
      setScheduleCSVModalOpen(true);

      await refreshCalls();
    } catch (error) {
      console.error(error);
      posthog?.capture("error_uploading_csv", { error: (error as Error).message });
    }
  };

  // State for menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Box sx={{ background: Colors.bg2, color: "#FFF", boxShadow: { xs: "0 4px 6px rgba(0, 0, 0, 0.15)", sm: "none" }, marginTop: "20px" }}>
      <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h5" sx={{ fontSize: { xs: "1.25rem", sm: "1.5rem", md: "1.8rem" }, whiteSpace: "nowrap" }}>
            Conversation queue
            <span
              style={{
                fontFamily: "Konnect-Regular",
                fontWeight: "normal",
                fontSize: "0.8rem",
                color: Colors.grey3,
                marginLeft: "5px",
                marginRight: "10px",
              }}
            >
              ({scheduledCalls.length})
            </span>
          </Typography>

          <div style={{ marginLeft: "10px" }}>{uploadCSVLoading && <CircularProgress size={24} />}</div>
        </Box>

        <Tooltip title={tooltipMessage} disableHoverListener={!isDisabled}>
          <RightAlignedSpan>
            {isMobile ? (
              <>
                <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleMenuOpen}>
                  <AddIcon />
                </IconButton>
                <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={isMenuOpen} onClose={handleMenuClose}>
                  <MenuItem
                    onClick={() => {
                      setUploadCSVModalOpen(true);
                      handleMenuClose();
                    }}
                    disabled={isDisabled}
                  >
                    Upload CSV
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleOpenAddPatientModal();
                      handleMenuClose();
                    }}
                    disabled={isDisabled}
                  >
                    Call new patient
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <>
                <SecondaryButton
                  onClick={() => setUploadCSVModalOpen(true)}
                  disabled={isDisabled}
                  sx={{
                    padding: { xs: "5px 10px", sm: "10px 20px" },
                    fontSize: { xs: "0.8rem", sm: "1rem" },
                    marginLeft: "auto",
                    marginRight: { xs: "-20px", sm: "10px" },
                    width: { xs: "100%", sm: "auto" },
                  }}
                >
                  Upload CSV
                </SecondaryButton>
                <PrimaryButton
                  onClick={handleOpenAddPatientModal}
                  disabled={isDisabled}
                  sx={{
                    padding: { xs: "5px 10px", sm: "10px 20px" },
                    fontSize: { xs: "0.8rem", sm: "1rem" },
                    marginLeft: "auto",
                    marginRight: { xs: "-15px", sm: "0px" },
                    whiteSpace: "nowrap",
                  }}
                >
                  Call new patient
                </PrimaryButton>
              </>
            )}
          </RightAlignedSpan>
        </Tooltip>
      </div>

      <div
        style={{
          borderBottom: "1px solid #E0E0E0",
          alignSelf: "center",
          marginBottom: "20px",
        }}
      ></div>

      {scheduledCalls && scheduledCalls.length > 0 && (
        <>
          {!!agent &&
            pageCalls.map((conversation: FrontendScheduledCall) => (
              <ConversationQueueRow key={conversation.scheduledCallId} agent={agent} conversation={conversation} />
            ))}

          <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
            <TransparentButton onClick={refreshCalls} sx={{ padding: { xs: "5px 10px", sm: "10px 20px" }, fontSize: { xs: "0.8rem", sm: "1rem" } }}>
              Refresh
            </TransparentButton>
            <TransparentButton
              onClick={prevPage}
              disabled={index === 0}
              sx={{ padding: { xs: "5px 10px", sm: "10px 20px" }, fontSize: { xs: "0.8rem", sm: "1rem" } }}
            >
              Prev
            </TransparentButton>
            <TransparentButton
              onClick={nextPage}
              disabled={endOfList && finalPage}
              sx={{ padding: { xs: "5px 10px", sm: "10px 20px" }, fontSize: { xs: "0.8rem", sm: "1rem" } }}
            >
              Next
            </TransparentButton>
          </div>
        </>
      )}
      {scheduledCalls && scheduledCalls.length === 0 && (
        <>
          <Typography>No scheduled calls in queue</Typography>
        </>
      )}

      <Sidebar className={isAddPatientModalOpen ? "open" : ""}>
        <NewPatientForm onClose={handleCloseAddPatientModal} />
      </Sidebar>

      <Sidebar className={uploadCSVModalOpen ? "open" : ""}>
        <UploadCSVModal onClose={() => setUploadCSVModalOpen(false)} onUpload={handleFileChange} />
      </Sidebar>

      <Sidebar className={matchingColumnsModalOpen ? "open" : ""}>
        <MatchColumnsModal
          open={matchingColumnsModalOpen}
          onClose={() => {
            setMatchingColumnsModalOpen(false);
          }}
          columns={columns}
          numRows={dataRowsCount}
          fullData={dataRows}
          onSuccess={(fullData: any) => {
            posthog?.capture("csv_upload", { num_rows: fullData.length });
            handleUploadCSVSuccess(fullData);
          }}
        />
      </Sidebar>

      <Sidebar className={scheduleCSVModalOpen ? "open" : ""}>
        <ScheduleCSVModal
          open={scheduleCSVModalOpen}
          onClose={() => setScheduleCSVModalOpen(false)}
          onSuccess={async () => {
            setScheduleCSVModalOpen(false);

            if (!user?.token || !patients || !agentId) {
              return;
            }
            await dispatch(sendPatientsToServer({ token: user.token, agentId: agentId }));
            posthog?.capture("[PENCILED] uploaded_patients_via_csv_uploader", {
              num_patients: patients.length,
              agentId: agentId,
              env: process.env.NODE_ENV,
            });

            await refreshCalls();
          }}
        />
      </Sidebar>
    </Box>
  );
};

export default ConversationQueue;
