// slices/callsSlice.tsx

import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../store";
import axios, { AxiosError } from "axios";
import { Agent, Call } from "../types";
import { sessionExpired } from "./SessionSlice";
import { DateTime } from "luxon";

export interface CallsSlice {
  calls: Call[];
  agents: Agent[];
  loading: boolean;
  lastEvaluatedKey: unknown;
  finalPage: boolean;
}

const initialState: CallsSlice = {
  calls: [],
  agents: [],
  loading: false,
  lastEvaluatedKey: undefined,
  finalPage: false,
};

export const refreshCallsPaginated = createAsyncThunk<
  { calls: Call[]; lastEvaluatedKey: unknown }, // Expected return type
  { token: string; agentIds?: string[]; dateRange?: [DateTime | null, DateTime | null] }, // Arguments passed to the thunk
  { rejectValue: Error }
>("calls/fetchCalls/paginated/refresh", async ({ token, agentIds, dateRange }, { rejectWithValue }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/calls/paginatedFiltered`,
      { agentIds, startTime: dateRange?.[0] || undefined, endTime: dateRange?.[1] || undefined },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data) {
      const calls: Call[] = response.data.calls;
      return {
        calls: calls,
        lastEvaluatedKey: response.data.lastEvaluatedKey,
      };
    }
    throw new Error("Invalid response data");
  } catch (error) {
    console.log("calls error: ", error);
    if ((error as AxiosError).response?.status === 401) {
      sessionExpired(true);
    }
    return rejectWithValue(new Error("Failed to fetch calls"));
  }
});

export const nextCallsPaginated = createAsyncThunk<
  { calls: Call[]; lastEvaluatedKey: unknown }, // Expected return type
  { token: string; lastEvaluatedKey: unknown; agentIds?: string[]; dateRange?: [DateTime | null, DateTime | null] }, // Arguments passed to the thunk
  { rejectValue: Error }
>("calls/fetchCalls/paginated/next", async ({ token, lastEvaluatedKey, agentIds, dateRange }, { rejectWithValue }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/calls/paginatedFiltered`,
      { lastEvaluatedKey: lastEvaluatedKey, agentIds, startTime: dateRange?.[0] || undefined, endTime: dateRange?.[1] || undefined },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data) {
      const calls: Call[] = response.data.calls;
      return {
        calls: calls,
        lastEvaluatedKey: response.data.lastEvaluatedKey,
      };
    }
    throw new Error("Invalid response data");
  } catch (error) {
    console.log("calls error: ", error);
    if ((error as AxiosError).response?.status === 401) {
      sessionExpired(true);
    }
    return rejectWithValue(new Error("Failed to fetch calls"));
  }
});

export const refreshAgentCallsPaginated = createAsyncThunk<
  { calls: Call[]; lastEvaluatedKey: unknown }, // Expected return type
  { token: string; agentId: string; dateRange?: [DateTime | null, DateTime | null] }, // Arguments passed to the thunk
  { rejectValue: Error }
>("calls/fetchCalls/agent/paginated/refresh", async ({ token, agentId, dateRange }, { rejectWithValue }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/agents/${agentId}/callsPaginatedFiltered`,
      { startTime: dateRange?.[0] || undefined, endTime: dateRange?.[1] || undefined },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data) {
      const calls: Call[] = response.data.calls;
      return {
        calls: calls,
        lastEvaluatedKey: response.data.lastEvaluatedKey,
      };
    }
    throw new Error("Invalid response data");
  } catch (error) {
    console.log("calls error: ", error);
    if ((error as AxiosError).response?.status === 401) {
      sessionExpired(true);
    }
    return rejectWithValue(new Error("Failed to fetch calls"));
  }
});

export const nextAgentCallsPaginated = createAsyncThunk<
  { calls: Call[]; lastEvaluatedKey: unknown }, // Expected return type
  { token: string; lastEvaluatedKey: unknown; agentId: string; dateRange?: [DateTime | null, DateTime | null] }, // Arguments passed to the thunk
  { rejectValue: Error }
>("calls/fetchCalls/agent/paginated/next", async ({ token, lastEvaluatedKey, agentId, dateRange }, { rejectWithValue }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/agents/${agentId}/callsPaginatedFiltered`,
      { lastEvaluatedKey: lastEvaluatedKey, startTime: dateRange?.[0] || undefined, endTime: dateRange?.[1] || undefined },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data) {
      const calls: Call[] = response.data.calls;
      return {
        calls: calls,
        lastEvaluatedKey: response.data.lastEvaluatedKey,
      };
    }
    throw new Error("Invalid response data");
  } catch (error) {
    console.log("calls error: ", error);
    if ((error as AxiosError).response?.status === 401) {
      sessionExpired(true);
    }
    return rejectWithValue(new Error("Failed to fetch calls"));
  }
});

const callsSlice = createSlice({
  name: "calls",
  initialState,
  reducers: {
    deleteCall: (state, action: PayloadAction<{ callId: string }>) => {
      state.calls = state.calls.filter((call: Call) => call.callId !== action.payload.callId);
    },
    addCall: (state, action: PayloadAction<{ call: Call }>) => {
      const existingCall = state.calls.find((call) => call.callId === action.payload.call.callId);
      if (!existingCall) {
        state.calls.push(action.payload.call);
        state.calls.sort((a: Call, b: Call) => {
          if (!a.endedAt || !b.endedAt) {
            // If either of the endedAt dates is missing, keep the order unchanged
            return 0;
          }
          return a.endedAt.localeCompare(b.endedAt);
        });
      } else {
        console.log(`Duplicate call detected: ${action.payload.call.callId}`);
      }
    },
    modifyCall: (state, action: PayloadAction<{ callId: string; changes: Partial<Call> }>) => {
      const index = state.calls.findIndex((call: Call) => call.callId === action.payload.callId);
      if (index !== -1) {
        state.calls[index] = { ...state.calls[index], ...action.payload.changes };
      }
    },
    clearCalls: (state) => {
      state.calls = [];
      state.lastEvaluatedKey = undefined;
      state.finalPage = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(refreshCallsPaginated.pending, (state) => {
        state.loading = true;
        state.calls = [];
        state.finalPage = false;
        state.lastEvaluatedKey = undefined;
      })
      .addCase(refreshCallsPaginated.fulfilled, (state, action) => {
        const newCalls = action.payload.calls.filter((newCall) => !state.calls.some((existingCall) => existingCall.callId === newCall.callId));
        state.calls = [...state.calls, ...newCalls];
        state.lastEvaluatedKey = action.payload.lastEvaluatedKey;
        if (!action.payload.lastEvaluatedKey) {
          state.finalPage = true;
        } else {
          state.finalPage = false;
        }
        state.loading = false;
      })
      .addCase(refreshCallsPaginated.rejected, (state) => {
        state.loading = false;
        // Handle error state
      })
      .addCase(nextCallsPaginated.pending, (state) => {
        state.loading = true;
      })
      .addCase(nextCallsPaginated.fulfilled, (state, action) => {
        const newCalls = action.payload.calls.filter((newCall) => !state.calls.some((existingCall) => existingCall.callId === newCall.callId));
        state.calls = [...state.calls, ...newCalls];
        state.lastEvaluatedKey = action.payload.lastEvaluatedKey;
        if (!action.payload.lastEvaluatedKey) {
          state.finalPage = true;
        } else {
          state.finalPage = false;
        }
        state.loading = false;
      })
      .addCase(nextCallsPaginated.rejected, (state) => {
        state.loading = false;
        // Handle error state
      })
      .addCase(refreshAgentCallsPaginated.pending, (state) => {
        state.loading = true;
        state.calls = [];
        state.finalPage = false;
        state.lastEvaluatedKey = undefined;
      })
      .addCase(refreshAgentCallsPaginated.fulfilled, (state, action) => {
        const newCalls = action.payload.calls.filter((newCall) => !state.calls.some((existingCall) => existingCall.callId === newCall.callId));
        state.calls = [...state.calls, ...newCalls];
        state.lastEvaluatedKey = action.payload.lastEvaluatedKey;
        if (!action.payload.lastEvaluatedKey) {
          state.finalPage = true;
        } else {
          state.finalPage = false;
        }
        state.loading = false;
      })
      .addCase(refreshAgentCallsPaginated.rejected, (state) => {
        state.loading = false;
        // Handle error state
      })
      .addCase(nextAgentCallsPaginated.pending, (state) => {
        state.loading = true;
      })
      .addCase(nextAgentCallsPaginated.fulfilled, (state, action) => {
        const newCalls = action.payload.calls.filter((newCall) => !state.calls.some((existingCall) => existingCall.callId === newCall.callId));
        state.calls = [...state.calls, ...newCalls];
        state.lastEvaluatedKey = action.payload.lastEvaluatedKey;
        if (!action.payload.lastEvaluatedKey) {
          state.finalPage = true;
        } else {
          state.finalPage = false;
        }
        state.loading = false;
      })
      .addCase(nextAgentCallsPaginated.rejected, (state) => {
        state.loading = false;
        // Handle error state
      });
  },
});

export const { addCall, deleteCall, modifyCall, clearCalls } = callsSlice.actions;

export const selectCalls = (state: RootState) => state.calls.calls;

export default callsSlice.reducer;
