import React from "react";
import Button from "@mui/material/Button";
import { Theme, styled } from "@mui/material/styles";
import { Colors } from "../Colors";

interface CustomButtonProps {
  children: React.ReactNode;
  variant?: "text" | "outlined" | "contained";
  color?: "primary" | "secondary" | "success" | "error" | "warning" | "info";
  onClick?: () => void;
  disabled?: boolean;
  size?: "small" | "medium" | "large";
  fullWidth?: boolean;
  customStyles?: React.CSSProperties;
}

const getCustomColor = (color: string, theme: Theme) => {
  switch (color) {
    case "primary":
      return theme.palette.primary.main;
    case "secondary":
      return theme.palette.secondary.main;
    case "success":
      return theme.palette.success.main;
    case "error":
      return theme.palette.error.main;
    case "warning":
      return theme.palette.warning.main;
    case "info":
      return theme.palette.info.main;
    default:
      return color;
  }
};

const StyledButton = styled(Button)<CustomButtonProps>(({ theme, color, variant }) => ({
  textTransform: "none",
  fontSize: "1rem",
  padding: "10px 20px",
  borderRadius: "8px",
  backgroundColor: variant === "contained" ? getCustomColor(color!, theme) : "transparent",
  borderColor: variant === "outlined" ? getCustomColor(color!, theme) : "transparent",
  color: variant === "text" ? getCustomColor(color!, theme) : theme.palette.text.primary,
  "&:hover": {
    backgroundColor: variant === "contained" ? getCustomColor(color!, theme) : "transparent",
    borderColor: variant === "outlined" ? getCustomColor(color!, theme) : "transparent",
  },
  "&.Mui-disabled": {
    backgroundColor: theme.palette.action.disabledBackground,
    color: theme.palette.action.disabled,
    borderColor: theme.palette.action.disabledBackground,
  },
}));

const PrimaryButton = styled(StyledButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: "white",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
  "&.Mui-disabled": {
    backgroundColor: '#31364B',
    color: Colors.info,
  },
}));

const SecondaryButton = styled(StyledButton)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.primary.main,
  "&:hover": {
    backgroundColor: theme.palette.secondary.dark,
  },
  "&.Mui-disabled": {
    backgroundColor: '#31364B',
    color: Colors.info,
  },
}));

const TertiaryButton = styled(StyledButton)(({ theme }) => ({
  backgroundColor: theme.palette.info.main,
  color: "white",
  "&:hover": {
    backgroundColor: theme.palette.info.dark,
  },
  "&.Mui-disabled": {
    backgroundColor: '#31364B',
    color: Colors.info,
  },
}));

const TransparentButton = styled(StyledButton)(() => ({
  backgroundColor: "transparent",
  color: "white",
  border: "1px solid lightgrey",
  "&:hover": {
    backgroundColor: Colors.bgTextMsg,
    color: "white",
    border: "1px solid lightgrey",
  },
}));

const CustomButton: React.FC<CustomButtonProps> = ({
  children,
  variant = "contained",
  color = "primary",
  onClick,
  disabled = false,
  size = "medium",
  fullWidth = false,
  customStyles = {},
}) => {
  return (
    <StyledButton
      variant={variant}
      onClick={onClick}
      disabled={disabled}
      size={size}
      fullWidth={fullWidth}
      color={color}
      style={{
        ...customStyles,
      }}
    >
      {children}
    </StyledButton>
  );
};

export { CustomButton, PrimaryButton, SecondaryButton, TertiaryButton, TransparentButton };
