import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Autocomplete,
  TextField,
  Input,
  Menu,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  SelectChangeEvent,
  Typography,
  Button,
  CircularProgress,
  FormControlLabel,
} from "@mui/material";
import styled from "styled-components";
import { Form, useParams } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../store";
import moment from "moment-timezone";
import { DateTime } from "luxon";
import { DatePicker } from "@mui/x-date-pickers";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { formatIsoToCustomDateStringWithEEEE } from "../utils/utils";
import { HealthieAppointmentType, HealthieProvider } from "../types";
import { PrimaryButton, SecondaryButton } from "../subcomponents/CustomButton";
import { HelpTooltip } from "../subcomponents/HelpTooltip";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import { theme } from "../providers/themeProvider";
import CircleIcon from "@mui/icons-material/Circle";
import Healthie from "../assets/HealthieWhiteLogo.png";
import CloseIcon from "@mui/icons-material/Close";
import { Colors } from "../Colors";
import { createPatient as createPatientThunk } from "../slices/PatientSlice";
import CheckIcon from '@mui/icons-material/Check';
import { debounce } from 'lodash';
import LoadingWithMessage from "../subcomponents/LoadingWithMessage";

export const GiantCheckIcon = styled(CheckIcon)`
  font-size: 100px !important;
  color: #53C43B;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
  margin-bottom: 20px;
  transition: transform 0.3s;
  border-radius: 20px;
  padding: 5px;

  &:hover {
    transform: scale(1.1);
  }
`;

const ModalBox = styled(Box)`
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // width: 100%;
  // margin: 10% auto;
  // background-color: #fff;
  // padding: 2%;
  // border-radius: 15px;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);

  // @media (max-width: 768px) {
  //   width: 70%;
  //   padding: 5%;
  // }
`;

const ModalScrollBox = styled(Box)`
  // display: flex;
  // flex-direction: column;
  // width: 30%;
  // margin: 10% auto;
  // background-color: #fff;
  // padding: 2%;
  // border-radius: 15px;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  // max-height: 70%;
  // overflow-y: auto;

  // @media (max-width: 768px) {
  //   width: 70%;
  //   padding: 5%;
  // }
`;

//const timezones = moment.tz.names();
const timezones = ["America/New_York", "America/Chicago", "America/Denver", "America/Los_Angeles"];

const timezoneOptions = timezones.map((tz) => {
  const dt = DateTime.now().setZone(tz);
  const offsetString = dt.toFormat("ZZ");
  const text = `${tz} (UTC${offsetString})`;

  return { value: tz, label: text, offset: dt.offset };
});

timezoneOptions.sort((a, b) => a.offset - b.offset);

const SelfAddForm: React.FC = () => {
  const [availableDates, setAvailableDates] = React.useState<string[]>([]);
  const [selectedProviders, setSelectedProviders] = React.useState<string[]>([]);
  const [selectedAppointmentTypes, setSelectedAppointmentTypes] = React.useState<string[]>([]);
  const [selectedContactTypes, setSelectedContactTypes] = React.useState<string[]>([]);
  const [textEnabled, setTextEnabled] = React.useState<boolean>(false);
  const [textConsentChecked, setTextConsentChecked] = React.useState<boolean>(false);
  const [callEnabled, setCallEnabled] = React.useState<boolean>(false);
  const [callConsentChecked, setCallConsentChecked] = React.useState<boolean>(false);
  const { orgId } = useParams<{ orgId: string }>();
  const [providers, setProviders] = useState<HealthieProvider[]>([]);
  const [appointments, setAppointments] = useState<HealthieAppointmentType[]>([]);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [dob, setDob] = useState<DateTime>(DateTime.now());
  const [firstDate, setFirstDate] = useState<DateTime | null>(null);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [value, setValue] = useState<[DateTime | null, DateTime | null]>([null, null]);
  const [addedPatient, setAddedPatient] = useState<boolean>(false);
  const [uploadingPatient, setUploadingPatient] = useState<boolean>(false);
  const [callConsentMessage, setCallConsentMessage] = useState<string>("");
  const [textConsentMessage, setTextConsentMessage] = useState<string>("");
  const [fetchingInfo, setFetchingInfo] = useState<boolean>(true);

  useEffect(() => {
    const fetchOrgInfo = async () => {
      try {
        setFetchingInfo(true);
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/public/healthie/self-add-patient/${orgId}`);

        if (response.status !== 200) {
          console.error("Error fetching organization info");
          return;
        }

        setProviders(response.data.providers);
        setAppointments(response.data.appointments);
        setCallConsentMessage(response.data.callConsentMessage);
        setTextConsentMessage(response.data.textConsentMessage);
      } catch (error) {
        console.error("Error fetching organization info:", error);
      } finally {
        setFetchingInfo(false);
      }
    };

    fetchOrgInfo();
  }, [orgId]);

  const contactTypes = ["In Person", "Healthie Video Call", "Phone Call"];

  const [contactMode, setContactMode] = React.useState<"text" | "call" | "both" | "">("");

  const handleContactModeChange = (mode: "text" | "call" | "both") => {
    setContactMode(mode);
    setTextEnabled(mode === "text" || mode === "both");
    setCallEnabled(mode === "call" || mode === "both");
  };

  const handleDobChange = (newValue: DateTime | null) => {
    if (newValue) {
      setDob(newValue);
    }
  };

  const handleCalendarChange = (newValue: [DateTime | null, DateTime | null]) => {
    setValue(newValue);

    if (newValue[0] && !newValue[1]) {
      // User selects only the start date
      setFirstDate(newValue[0]);
    } else if (newValue[1]) {
      // User completes the date range
      setCalendarOpen(false);
      let start = (newValue[0] || firstDate)!.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      let end = newValue[1]!.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      let dates: string[] = [];

      while (start <= end) {
        const dateTimes: string[] = availableDates
          .map((date: string) => DateTime.fromISO(date, { setZone: true }).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }))
          .map((date: DateTime) => date.toISO()!);
        const newStart = start.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        if (!dateTimes.includes(newStart.toISO()!) && start.weekday !== 6 && start.weekday !== 7) {
          // Exclude weekends (Saturday is 6, Sunday is 7)
          dates.push(start.toISO()!);
        }
        start = start.plus({ days: 1 });
      }

      setAvailableDates(Array.from(new Set([...availableDates, ...dates.filter((date) => !availableDates.includes(date))])));
      setValue([null, null]); // Reset date range
    }
  };

  const createPatient = async () => {
    try {
      setUploadingPatient(true);
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/public/healthie/self-add-patient/${orgId}`, {
        patientFirstName: firstName,
        patientLastName: lastName,
        patientPhoneNumber: phoneNumber,
        availableDates: availableDates,
        dob: dob.startOf("day").setZone("UTC")?.toISO()!,
        callEnabled: callEnabled,
        textEnabled: textEnabled,
        selectedProviders: selectedProviders,
        selectedAppointmentTypes: selectedAppointmentTypes,
        selectedContactTypes: selectedContactTypes
      });

      if (response.status !== 200) {
        console.error("Error creating patient");
        return;
      }

      setFirstName("");
      setLastName("");
      setDob(DateTime.now());
      setAvailableDates([]);
      setAddedPatient(true);
      
    } catch (error) {
      console.error("Error creating patient:", error);
    } finally {
      setUploadingPatient(false);
    }
  };

  const handleRemoveDate = (index: number) => {
    setAvailableDates((prevDates) => prevDates.filter((_, idx) => idx !== index));
  };

  const providerOptions = providers.map((provider: HealthieProvider) => ({
    value: provider.id,
    label: provider.firstName + " " + provider.lastName,
  }));

  const appointmentOptions = appointments.map((appointment: HealthieAppointmentType) => ({
    value: appointment.id,
    label: appointment.name,
  }));

  const contactTypeOptions = contactTypes.map((contactType) => ({
    value: contactType,
    label: contactType,
  }));

  const handleSelectAllProviders = () => {
    setSelectedProviders(providers.map((provider: HealthieProvider) => provider.id));
  };

  const handleDeselectAllProviders = () => {
    setSelectedProviders([]);
  };

  const handleSelectAllAppointmentTypes = () => {
    setSelectedAppointmentTypes(appointments.map((appointment) => appointment.id));
  };

  const handleDeselectAllAppointmentTypes = () => {
    setSelectedAppointmentTypes([]);
  };

  const handleSelectAllContactTypes = () => {
    setSelectedContactTypes(contactTypes);
  };

  const handleDeselectAllContactTypes = () => {
    setSelectedContactTypes([]);
  };

  const debouncedOnChange = (func: (value: string) => void, delay: number = 500) => {
    return debounce((value: string) => {
      func(value);
    }, delay);
  };

  const debouncedSetFirstName = debouncedOnChange(setFirstName);
  const debouncedSetLastName = debouncedOnChange(setLastName);
  const debouncedSetPhoneNumber = debouncedOnChange(setPhoneNumber);

  return (
    <Box
      sx={{
        padding: "40px",
        backgroundColor: "#0D0F23",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          overflowY: "auto",
          height: "auto",
          paddingBottom: "60px",
          paddingRight: "20px",
          paddingLeft: "20px",
          paddingTop: "20px",
        }}
      >
    {addedPatient && (
      <Box
        sx={{
          backgroundColor: "#1C1F3E", // Slightly lighter background color
          padding: "40px 20px", // More padding to make it vertically fatter
          borderRadius: "10px", // Rounded corners
          border: "1px solid rgba(255, 255, 255, 0.2)", // Subtle border for definition
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "column", // Stack items vertically
          width: "50%", // Set a fixed width to make it centered and balanced
          height: "auto", // Adjust height automatically
          margin: "auto", // Center the box both horizontally and vertically
          textAlign: "center", // Center text and icons inside the box
          position: "absolute", // Allow the box to be centered absolutely
          top: "50%", // Center vertically
          left: "50%", // Center horizontally
          transform: "translate(-50%, -50%)", // Adjust for perfect centering
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "1.5rem", // Larger text size for emphasis
            color: "#FFFFFF", // White text color
            marginBottom: "20px", // Space between text and icon
          }}
        >
          Patient Added
        </Typography>
        <GiantCheckIcon style={{ fontSize: "4rem", color: "#00AC4F", marginBottom: "20px" }} /> {/* Larger icon with spacing */}
        <img
          src={Healthie}
          alt="Healthie"
          style={{
            width: "100px", // Larger logo
            height: "auto",
            borderRadius: "0",
          }}
        />
      </Box>
    )}


    { !addedPatient && (
      <>
    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} marginTop={"30px"}>
      <Typography margin={"30px 0 10px 0"} fontWeight={"bold"} fontSize={"1.2rem"}>
        Add Yourself to the Waitlist
      </Typography>
      <img src={Healthie} alt="Healthie" style={{ width: "85px", height: "auto", marginTop: "15px", borderRadius: "0" }} />
    </Box>

    <Typography variant="body1" sx={{ marginTop: "20px" }}>Patient</Typography>
        <TextField
          onChange={(e) => debouncedSetFirstName(e.target.value)}
          variant="outlined"
          fullWidth
          placeholder="First Name"
          sx={{
            borderRadius: "10px",
            background: "rgba(255, 255, 255, 0.04)",
            marginTop: "5px",
            "& .MuiInputBase-input": {
              padding: "15px",
              fontSize: "0.9rem",
              color: "#FFFFFF",
            },
            "& .MuiSvgIcon-root": {
              color: "#FFF",
            },
          }}
        />
        <TextField
          onChange={(e) => debouncedSetLastName(e.target.value)}
          variant="outlined"
          fullWidth
          placeholder="Last Name"
          sx={{
            borderRadius: "10px",
            background: "rgba(255, 255, 255, 0.04)",
            marginTop: "5px",
            "& .MuiInputBase-input": {
              padding: "15px",
              fontSize: "0.9rem",
              color: "#FFFFFF",
            },
            "& .MuiSvgIcon-root": {
              color: "#FFF",
            },
          }}
        />
        <TextField
          onChange={(e) => debouncedSetPhoneNumber(e.target.value)}
          variant="outlined"
          fullWidth
          placeholder="Phone Number"
          sx={{
            borderRadius: "10px",
            background: "rgba(255, 255, 255, 0.04)",
            marginTop: "5px",
            "& .MuiInputBase-input": {
              padding: "15px",
              fontSize: "0.9rem",
              color: "#FFFFFF",
            },
            "& .MuiSvgIcon-root": {
              color: "#FFF",
            },
          }}
        />
  
        {/* DOB */}
        <DatePicker
          value={dob}
          onChange={handleDobChange}
          label={"Date of Birth"}
          sx={{
            flexGrow: 1,
            marginTop: "20px",
            background: Colors.textfield,
            "& .MuiInputBase-input": {
              color: "#FFFFFF",
            },
            "& .MuiInputLabel-root": {
              color: Colors.info,
            },
            "& .MuiSvgIcon-root": {
              color: Colors.info,
            },
          }}
        />
  
        <Typography variant="body1" mt={3} mb={1}>
          AI agent mode of contact
        </Typography>
  
        <FormControl variant="outlined" fullWidth>
          <Box display="flex" gap="10px">
            <Button
              onClick={() => handleContactModeChange("call")}
              variant="outlined"
              sx={{
                borderRadius: "10px",
                textTransform: "none",
                borderColor: contactMode === "call" ? Colors.primary : "rgba(255, 255, 255, 0.2)",
                backgroundColor: "transparent",
                color: contactMode === "call" ? "#fff" : "#9FA1A6",
              }}
            >
              Call only
            </Button>
  
            <Button
              onClick={() => handleContactModeChange("text")}
              variant="outlined"
              sx={{
                borderRadius: "10px",
                textTransform: "none",
                borderColor: contactMode === "text" ? Colors.primary : "rgba(255, 255, 255, 0.2)",
                backgroundColor: "transparent",
                color: contactMode === "text" ? "#fff" : "#9FA1A6",
              }}
            >
              Text only
            </Button>
  
            <Button
              onClick={() => handleContactModeChange("both")}
              variant="outlined"
              sx={{
                borderRadius: "10px",
                textTransform: "none",
                borderColor: contactMode === "both" ? Colors.primary : "rgba(255, 255, 255, 0.2)",
                backgroundColor: "transparent",
                color: contactMode === "both" ? "#fff" : "#9FA1A6",
              }}
            >
              Both
            </Button>
          </Box>
        </FormControl>
  
        <Typography variant="body1" fontWeight={"bold"} mt={3}>
          Waitlist Parameters <HelpTooltip title={"The patient will automatically be put on waitlists for appointments fitting these criteria."} />
        </Typography>
  
        <FormControl fullWidth>
          <Box display="flex" alignItems="center" gap="10px">
            <Typography variant="body1">Providers</Typography>
            {selectedProviders.length === providerOptions.length ? (
              <Button sx={{ textTransform: "none" }} onClick={handleDeselectAllProviders}>
                Deselect All
              </Button>
            ) : (
              <Button sx={{ textTransform: "none" }} onClick={handleSelectAllProviders}>
                Select All
              </Button>
            )}
          </Box>
          <Select
            multiple
            displayEmpty
            value={selectedProviders}
            onChange={(e) => setSelectedProviders(e.target.value as string[])}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <Typography sx={{ color: "#9FA1A6" }}>Select options</Typography>;
              }
              return (selected as string[]).map((value) => providerOptions.find((option) => option.value === value)?.label).join(", ");
            }}
            sx={{
              borderRadius: "10px",
              background: "rgba(255, 255, 255, 0.04)",
              marginTop: "5px",
              "& .MuiInputBase-input": {
                padding: "15px",
                fontSize: "0.9rem",
                color: "#FFFFFF",
              },
              "& .MuiSvgIcon-root": {
                color: "#FFF",
              },
            }}
          >
            {providerOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <Checkbox checked={selectedProviders.indexOf(option.value) > -1} />
                <ListItemText primary={option.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
  
        <FormControl fullWidth>
          <Box display="flex" alignItems="center" gap="10px" mt={2}>
            <Typography variant="body1">Appointment Types</Typography>
            {selectedAppointmentTypes.length === appointmentOptions.length ? (
              <Button sx={{ textTransform: "none" }} onClick={handleDeselectAllAppointmentTypes}>
                Deselect All
              </Button>
            ) : (
              <Button sx={{ textTransform: "none" }} onClick={handleSelectAllAppointmentTypes}>
                Select All
              </Button>
            )}
          </Box>
          <Select
            multiple
            displayEmpty
            value={selectedAppointmentTypes}
            onChange={(e) => setSelectedAppointmentTypes(e.target.value as string[])}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <Typography sx={{ color: "#9FA1A6" }}>Select options</Typography>;
              }
              return (selected as string[]).map((value) => appointmentOptions.find((option) => option.value === value)?.label).join(", ");
            }}
            sx={{
              borderRadius: "10px",
              background: "rgba(255, 255, 255, 0.04)",
              marginTop: "5px",
              "& .MuiInputBase-input": {
                padding: "15px",
                fontSize: "0.9rem",
                color: "#FFFFFF",
              },
              "& .MuiSvgIcon-root": {
                color: "#FFF",
              },
            }}
          >
            {appointmentOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <Checkbox checked={selectedAppointmentTypes.indexOf(option.value) > -1} />
                <ListItemText primary={option.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
  
        <FormControl fullWidth>
          <Box display="flex" alignItems="center" gap="10px" mt={2}>
            <Typography variant="body1">Contact Types</Typography>
            {selectedContactTypes.length === contactTypeOptions.length ? (
              <Button sx={{ textTransform: "none" }} onClick={handleDeselectAllContactTypes}>
                Deselect All
              </Button>
            ) : (
              <Button sx={{ textTransform: "none" }} onClick={handleSelectAllContactTypes}>
                Select All
              </Button>
            )}
          </Box>
          <Select
            multiple
            displayEmpty
            value={selectedContactTypes}
            onChange={(e) => setSelectedContactTypes(e.target.value as string[])}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <Typography sx={{ color: "#9FA1A6" }}>Select options</Typography>;
              }
              return (selected as string[]).map((value) => contactTypeOptions.find((option) => option.value === value)?.label).join(", ");
            }}
            sx={{
              borderRadius: "10px",
              background: "rgba(255, 255, 255, 0.04)",
              marginTop: "5px",
              "& .MuiInputBase-input": {
                padding: "15px",
                fontSize: "0.9rem",
                color: "#FFFFFF",
              },
              "& .MuiSvgIcon-root": {
                color: "#FFF",
              },
            }}
          >
            {contactTypeOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <Checkbox checked={selectedContactTypes.indexOf(option.value) > -1} />
                <ListItemText primary={option.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
  
        <Typography variant="body1" mt={2} mb={1}>
          Available Days
        </Typography>
  
        <DateRangePicker
          value={value}
          onChange={handleCalendarChange}
          localeText={{ start: "Start date", end: "End date" }}
          sx={{
            flexGrow: 1,
            background: "rgba(255, 255, 255, 0.04)",
            "& .MuiInputBase-input": {
              color: "#FFFFFF",
            },
            "& .MuiInputLabel-root": {
              color: "#9FA1A6",
            },
            "& .MuiSvgIcon-root": {
              color: "#9FA1A6",
            },
          }}
        />
  
        {availableDates.length > 0 && (
          <List
            sx={{
              width: "100%",
              bgcolor: "#1A1E36",
              display: "flex",
              flexDirection: "column", // Make the list items stack vertically
              gap: "8px",
              marginTop: "10px",
              padding: "10px",
              borderRadius: "10px",
              maxHeight: "200px", // Increase this value for more height
              overflowY: "auto", // Enable scrolling if the content exceeds the max height
            }}
          >
            {availableDates.map((date, index) => (
              <ListItem
                key={index}
                secondaryAction={
                  <IconButton edge="end" aria-label="delete" style={{ color: "#9FA1A6" }} onClick={() => handleRemoveDate(index)}>
                    <CloseIcon />
                  </IconButton>
                }
                sx={{
                  background: "#23283E",
                  color: "#FFF",
                  paddingTop: "2px",
                  paddingBottom: "2px",
                  width: "auto",
                  borderRadius: "10px",
                  fontSize: "0.75rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <ListItemText
                  primary={formatIsoToCustomDateStringWithEEEE(date)}
                  sx={{
                    fontSize: "0.75rem",
                    lineHeight: "1.2",
                  }}
                />
              </ListItem>
            ))}
        </List>
        )}

        { fetchingInfo && <LoadingWithMessage message={"Loading, please wait..."} size={20} /> }
        {(callEnabled || textEnabled) && (
          <Box
            sx={{
              marginTop: "20px",
              padding: "20px",
              backgroundColor: "#1A1E36", // Background color for the box
              borderRadius: "10px", // Rounded corners
              border: "1px solid rgba(255, 255, 255, 0.2)", // Light border to distinguish the box
              display: "flex",
              flexDirection: "column", // Stack the checkboxes vertically
              gap: "10px", // Space between the checkboxes
            }}
          >
            {callEnabled && (
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: '#B0B0B0', // Light grey for unchecked state
                      '&.Mui-checked': {
                        color: '#FFFFFF', // White when checked
                      },
                    }}
                  />
                }
                onChange={(e: any) => setCallConsentChecked(e.target.checked)}
                label={callConsentMessage}
                sx={{ color: "#FFFFFF" }}
              />
            )}

            {textEnabled && (
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: '#B0B0B0', // Light grey for unchecked state
                      '&.Mui-checked': {
                        color: '#FFFFFF', // White when checked
                      },
                    }}
                  />
                }
                onChange={(e: any) => setTextConsentChecked(e.target.checked)}
                label={textConsentMessage}
                sx={{ color: "#FFFFFF" }}
              />
            )}
          </Box>
        )}
  
        <Box
          sx={{
            padding: "12px 20px", // Add padding around the button to avoid touching the bezels
            width: "100%",
            display: "flex",
            borderTop: "2px solid rgba(256, 256, 256, 0.04)",
            background: "#151A33",
            justifyContent: "flex-end", // Align the button to the right
          }}
        >
          <PrimaryButton
            disabled={!firstName || !lastName || !phoneNumber || availableDates.length === 0 || 
              ((callEnabled && !callConsentChecked) || (textEnabled && !textConsentChecked))
            }
            onClick={createPatient}
            sx={{
              padding: "10px 20px",
              "&:disabled": {
                backgroundColor: "#31364B",
                color: "#9FA1A6",
              },
            }}
          >
            {uploadingPatient ? <CircularProgress size={24} color="inherit" /> : "Add Yourself"}
          </PrimaryButton>
        </Box>
      </>
      )}
      </Box>
    </Box>
  );
  
};

export default SelfAddForm;
