import React, { useEffect, useState } from "react";
import { Box, Typography, FormControl, OutlinedInput, CircularProgress, IconButton } from "@mui/material";
import styled from "styled-components";
import { PrimaryButton, TransparentButton } from "../subcomponents/CustomButton";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DateTime } from "luxon";
import { useSnackbar } from "../providers/SnackbarProvider";
import { ArrayTypes, EHR, EssentialVariables, FrontendUser, OnboardingStep, SingleTypes, SpecialVariables } from "../types";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../store";
import { usePostHog } from "posthog-js/react";
import { useParams } from "react-router-dom";
import { enqueuePatientScheduledCall } from "../slices/ScheduledCallSlice";
import { Colors } from "../Colors";
import { Organization } from "../types";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import { convertCamelCaseToOfficial } from "../utils/utils";
import Loader from "../subcomponents/Loader";
import { isPhoneNumber } from "../utils/utils";
import { updateOnboardingStep } from "../slices/AuthSlice";
import CloseIcon from "@mui/icons-material/Close";

const ModalBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 70%;
  margin: 5% auto;
  background-color: #fff;
  padding: 40px 5%;
  border-radius: 35px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);

  @media (max-width: 1250px) {
    width: 80%;
    padding: 30px 5%;
    margin: 5% auto;
  }

  @media (max-width: 480px) {
    width: 85%;
    padding: 30px 8%;
    margin: 3% auto;
  }
`;

type VariableDetail = {
  variable: string;
  type: string;
  value: any; // To hold the values entered by the user
  metadata?: Record<string, string | number>;
};

const NewPatientModal: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const { agentId } = useParams<{ agentId: string }>();
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const agent = useSelector((state: RootState) => state.agents.agents.find((agent) => agent.agentId === agentId));
  const [scheduledDate, setScheduledDate] = useState<DateTime>(DateTime.now().plus({ hour: 1 }).set({ second: 0, millisecond: 0 }));
  const { showMessage } = useSnackbar();
  const [localErrorMsg, setLocalErrorMsg] = useState<string>("");
  const posthog = usePostHog();
  const [schedulingCallLoading, setSchedulingCallLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [warningMsg, setWarningMsg] = useState<string>("");
  const organization: Organization | null = useSelector((state: RootState) => state.auth.organization);
  const [variables, setVariables] = useState<VariableDetail[]>([]);
  const [loadingVariables, setLoadingVariables] = useState<boolean>(false);

  const schedulingError = useSelector((state: RootState) => state.scheduledCalls.schedulingError);

  useEffect(() => {
    posthog?.capture("[PENCILED] Call new patient modal opened", { agentId: agentId });
  }, [posthog, agentId]);

  const handleScheduledDateChange = (date: DateTime | null) => {
    try {
      if (!date) {
        return;
      }

      if (date < DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })) {
        showMessage("Scheduled date cannot be in the past", "error");
        setLocalErrorMsg("Scheduled date cannot be in the past");
        return;
      }

      setScheduledDate(date.set({ second: 0, millisecond: 0 }));
    } catch (error) {
      console.error("Error setting scheduled date:", error);
      showMessage("Error setting scheduled date", "error");
    }
  };

  // set a 10 second timeout on localErrorMsg
  useEffect(() => {
    const timeout = setTimeout(() => {
      setLocalErrorMsg("");
    }, 10000);

    return () => clearTimeout(timeout);
  }, [localErrorMsg]);

  useEffect(() => {
    if (!agent) {
      return;
    }
    if (organization?.integrations?.includes(EHR.healthie) && agent.ehr !== EHR.healthie) {
      setWarningMsg(
        "WARNING: EHR integration is enabled, but the agent's EHR is not Healthie. Please ensure that you would like to make a non-Healthie call before proceeding."
      );
    }
  }, [agent?.ehr, agent?.ehrProviderId, agent?.ehrApptTypeId]);

  const handleEnqueuePatientScheduledCall = async () => {
    try {
      // if any processedVariables are empty, don't enqueue the call
      const emptyVariables = variables.filter((v) => {
        if (v.type === SingleTypes.phoneNumber && !isPhoneNumber(v.value)) {
          return true;
        }
        return !v.value || (Array.isArray(v.value) && v.value.length === 0);
      });

      if (emptyVariables.length > 0) {
        setLocalErrorMsg("All variables must be filled out.");
        return;
      }

      if (!scheduledDate) {
        setLocalErrorMsg("Scheduled date is required.");
        return;
      }

      if (!agentId) {
        throw new Error("Agent not found.");
      }
      if (!user?.organizationId) {
        throw new Error("User doesn't have an organization.");
      }

      const processedVariables: Record<string, any> = {};
      variables.forEach(({ variable, value, type }) => {
        if (Array.isArray(value)) {
          if (type === ArrayTypes.dateArray) {
            processedVariables[variable] = value.map((v: any) => v.toISO()).join(",");
          } else {
            processedVariables[variable] = value.map((v: any) => v.toString()).join(",");
          }
        } else if (value instanceof DateTime) {
          processedVariables[variable] = value.toISO()!;
        } else {
          processedVariables[variable] = value.toString();
        }
      });

      dispatch(
        enqueuePatientScheduledCall({
          organizationId: user.organizationId,
          scheduledDate: scheduledDate,
          agentId,
          posthog,
          onClose,
          setSchedulingCallLoading,
          setLocalErrorMsg,
          variables: processedVariables,
        })
      );
    } catch (error) {
      console.error("Error enqueueing patient scheduled call:", error);
      showMessage("Error enqueueing patient scheduled call", "error");
    }
  };

  useEffect(() => {
    const fetchPromptVariables = async () => {
      setLoadingVariables(true);
      try {
        if (!agentId) {
          return;
        }

        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/agents/${agent?.agentId}/variables`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user?.token}`,
          },
        });

        setVariables(
          response.data.variables.map((v: any) => ({ ...v, value: v.type.endsWith("[]") ? [] : v.type === "date" ? DateTime.now() : "" }))
        );
      } catch (error) {
        console.error("Error fetching prompt variables:", error);
        showMessage("Error fetching prompt variables", "error");
      } finally {
        setLoadingVariables(false);
      }
    };

    if (agent) {
      fetchPromptVariables();
    }
  }, []);

  const handleDateChange = (variable: string) => (date: DateTime | null) => {
    setVariables((prevVariables) => prevVariables.map((v) => (v.variable === variable ? { ...v, value: date } : v)));
  };

  const handleArrayAddition = (variable: string) => () => {
    setVariables((prevVariables) =>
      prevVariables.map((v) => (v.variable === variable && Array.isArray(v.value) ? { ...v, value: [...v.value, null] } : v))
    );
  };

  const renderInput = (variable: string, type: string, value: any, metadata?: Record<string, string | number>) => {
    switch (type) {
      case EssentialVariables.patientPhoneNumber:
      case SpecialVariables.patientFirstName:
      case SpecialVariables.patientLastName:
      case SpecialVariables.email:
      case SpecialVariables.preferredLanguage:
      case SpecialVariables.preferredContactMethod:
      case SpecialVariables.liveTransferNumber:
      case SingleTypes.string:
      case SingleTypes.phoneNumber:
        return (
          <OutlinedInput
            placeholder={`Enter ${metadata?.displayName || convertCamelCaseToOfficial(variable)}`}
            value={value}
            onChange={(e) =>
              setVariables((prevVariables) => prevVariables.map((v) => (v.variable === variable ? { ...v, value: e.target.value } : v)))
            }
            fullWidth
            sx={{
              background: Colors.textfield, 
              "& .MuiInputBase-input": {
                color: "#FFFFFF",
              },
              "& .MuiInputLabel-root": {
                color: Colors.info,
              },
              "& .MuiSvgIcon-root": {
                color: Colors.info,
              },
            }}
          />
        );
      case SingleTypes.date:
        return <DateTimePicker 
                  // label={`Select ${variable}`} 
                  value={value} 
                  onChange={handleDateChange(variable)} 
                  sx={{
                    background: Colors.textfield, 
                    "& .MuiInputBase-input": {
                      color: "#FFFFFF",
                    },
                    "& .MuiInputLabel-root": {
                      color: Colors.info,
                    },
                    "& .MuiSvgIcon-root": {
                      color: Colors.info,
                    },
                  }}
                />;
      case ArrayTypes.dateArray:
        return (
          <Box>
            {value.map((date: any, index: number) => (
              <DateTimePicker 
                key={index} 
                label={`Select ${variable} ${index + 1}`} 
                value={date} 
                onChange={handleDateChange(variable)} 
                sx={{
                  background: Colors.textfield, 
                  "& .MuiInputBase-input": {
                    color: "#FFFFFF",
                  },
                  "& .MuiInputLabel-root": {
                    color: Colors.info,
                  },
                  "& .MuiSvgIcon-root": {
                    color: Colors.info,
                  },
                }}
              />
            ))}
            <IconButton onClick={handleArrayAddition(variable)}>
              <AddIcon />
            </IconButton>
          </Box>
        );
      default:
        return null;
    }
  };

  const sortedVariables = variables.sort((a, b) => {
    const aRank: number = Number(a?.metadata?.rank ?? 0);
    const bRank: number = Number(b?.metadata?.rank ?? 0);
    return aRank - bRank;
  });

  return (
      <Box 
        sx={{
          overflowY: 'auto',
          height: 'auto',
          paddingBottom: '60px',   
          paddingRight: '20px',  
        }}    
      >
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            padding: '12px 25px',
            width: '100%',
            display: 'flex',
            borderBottom: '2px solid Colors.textfield',
            background: '#151A33', 
            justifyContent: 'space-between',
            alignItems: 'center',
            zIndex: 1000,
          }}
        >
          <Box display={'flex'} gap={'10px'}>
            <Typography variant={'body2'} fontSize={'1.1rem'} fontWeight={'bold'}>Call New Patient</Typography>
          </Box>
          <IconButton onClick={onClose} sx={{color: '#FFFFFF'}}>
            <CloseIcon />
          </IconButton>
        </Box>


        <FormControl fullWidth sx={{ mt: 8 }}>
          <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>Scheduled call time</Typography>

          <DateTimePicker 
            label="Call scheduled date" 
            value={scheduledDate} 
            onChange={handleScheduledDateChange} 
            sx={{ 
              flexGrow: 1,
              background: Colors.textfield, 
              "& .MuiInputBase-input": {
                color: "#FFFFFF",
              },
              "& .MuiInputLabel-root": {
                color: Colors.info,
              },
              "& .MuiSvgIcon-root": {
                color: Colors.info,
              },
            }} 
          />
        </FormControl>

        <Typography variant="h6" style={{ marginTop: "20px" }}>
          Variables
        </Typography>

        {loadingVariables && <Loader message={"Loading prompt variables..."} size={30} />}
        {sortedVariables.map(({ variable, type, value, metadata }) => (
          <FormControl key={variable} fullWidth sx={{ mt: 2 }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>{metadata?.displayName || convertCamelCaseToOfficial(variable)}</Typography>
            {renderInput(variable, type, value, metadata)}
          </FormControl>
        ))}

        {/* Display an error if it exists */}
        {localErrorMsg && (
          <Typography color="error" style={{ marginTop: "15px" }}>
            {localErrorMsg}
          </Typography>
        )}

        { schedulingError && (
          <Typography color="error" style={{ marginTop: "15px" }}>
            {schedulingError}
          </Typography>
        )}

        {warningMsg && (
          <Typography variant="body2" style={{ fontSize: "1rem", fontWeight: "bold", marginTop: "15px", color: Colors.error }}>
            {warningMsg}
          </Typography>
        )}

        {/* {schedulingCallLoading && <LoadingWithMessage message="Scheduling call..." size={20} />} */}

        <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            padding: '12px',
            width: '100%',
            display: 'flex',
            borderTop: '2px solid Colors.textfield',
            background: '#151A33',
            justifyContent: 'right',
            zIndex: 1000,
          }}
        >
          {!!agent && (
          <PrimaryButton 
            onClick={() => {
              posthog?.capture("[PENCILED] Call Patient button clicked", { agentId: agentId, env: process.env.NODE_ENV }),
              handleEnqueuePatientScheduledCall();
              dispatch(updateOnboardingStep(OnboardingStep.end));
            }}
            variant="contained"
            color="primary"
            fullWidth={false}
            disabled={!agent.isActive} 
            sx={{
              padding: '5px 15px',
              '&:disabled': {
                backgroundColor: '#31364B',
                color: Colors.info,
              }, 
            }}
          >
            Call Patient
          </PrimaryButton>
      )}
        </Box>
    </Box>
  );
};

export default NewPatientModal;