import * as React from "react";
import { Box, Drawer, CssBaseline, AppBar, Toolbar, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DashboardIcon from "@mui/icons-material/Dashboard";
import HistoryIcon from "@mui/icons-material/History";
import PeopleIcon from "@mui/icons-material/People";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import HelpIcon from "@mui/icons-material/Help";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import DeveloperModeIcon from "@mui/icons-material/DeveloperMode";
import Typography from "@mui/material/Typography";
import { StyledPenciledTextLogo, WhitePenciledLogo } from "../styles/HeaderStyles";
import { Outlet, useNavigate } from "react-router-dom";
import { Colors } from "../Colors";
import { useSelector } from "react-redux";
import { Agent, AgentType, EHR, FrontendUser, OnboardingStep, Organization } from "../types";
import { RootState, useAppDispatch } from "../store";
import { clearAgents, fetchAgents } from "../slices/AgentSlice";
import { useSnackbar } from "../providers/SnackbarProvider";
import { useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { fetchAppointments, fetchHealthiePatients, fetchProviders } from "../slices/HealthieIntegrationSlice";
import { signOut } from "../slices/AuthSlice";
import styled from "styled-components";
import { TransparentButton } from "../subcomponents/CustomButton";
import { usePostHog } from "posthog-js/react";
import { AxiosError } from "axios";
import { useState } from "react";
import { getWaitlistPatients } from "../slices/PatientSlice";
import { dropdownStyles, menuPropsStyles } from "../styles/GeneralStyles";

const SignoutButton = styled(TransparentButton)`
  display: none;

  @media (max-width: 1024px) {
    display: block;
    border: none;
  }
`;

const drawerWidth = 300;

const DashboardSidebar: React.FC = () => {
  const [activeItem, setActiveItem] = React.useState<string | null>(null);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { showMessage } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const providers = useSelector((state: RootState) => state.healthieIntegration.providers);

  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const organization: Organization | null = useSelector((state: RootState) => state.auth.organization);

  const agents: Agent[] = useSelector((state: RootState) => state.agents.agents);

  const [firstWaitlistAgent, setFirstWaitlistAgent] = useState(agents.find((agent: Agent) => agent.agentType === AgentType.waitlist));
  const [dashboardPath, setDashboardPath] = useState<string>(`/dashboard/agents/${firstWaitlistAgent?.agentId}`);

  const onboardingStep: OnboardingStep = useSelector((state: RootState) => state.auth.onboardingStep);

  useEffect(() => {
    if (onboardingStep === OnboardingStep.end) {
      setDashboardPath(`/dashboard/agents/${firstWaitlistAgent?.agentId}`);
    } else {
      setDashboardPath(`/dashboard`);
    }
  }, [firstWaitlistAgent, onboardingStep]);

  useEffect(() => {
    if (agents) {
      setFirstWaitlistAgent(agents.find((agent: Agent) => agent.agentType === AgentType.waitlist));
    }
  }, [agents]);

  const posthog = usePostHog();

  const fetchGlobalAgents = async () => {
    if (user && user?.userId && user?.token) {
      try {
        await dispatch(fetchAgents({ userId: user?.userId, token: user?.token }));
      } catch (error) {
        if ((error as AxiosError).response?.status === 401) {
          showMessage("Session has expired", "error");
        }
        console.error("Failed to fetch calls:", (error as Error).message);
      }
    } else {
      dispatch(clearAgents());
    }
  };

  useEffect(() => {
    fetchGlobalAgents();
  }, [user?.token]);

  useEffect(() => {
    if (organization?.integrations.includes(EHR.healthie)) {
      dispatch(fetchProviders());
      dispatch(fetchHealthiePatients());
      dispatch(getWaitlistPatients());
    }
  }, [organization?.integrations]);

  useEffect(() => {
    if (providers.length > 0 && user?.token) {
      dispatch(fetchAppointments({ providerIds: providers.map((provider) => provider.id) }));
    }
  }, [providers, user?.token]);

  const handleListItemClick = (item: string, path: string) => {
    posthog?.capture("[PENCILED] dashboard_sidebar_item_clicked", { item });
    setActiveItem(item);

    if (path.startsWith("https://")) {
      window.open(path, "_blank");
    } else {
      navigate(path);
    }

    if (isMobile) {
      setMobileOpen(false);
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = () => {
    dispatch(signOut());
    navigate("/login");
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (action?: string) => {
    setAnchorEl(null);
    if (action === "account") {
      navigate("/dashboard/account");
    } else if (action === "logout") {
      handleLogout();
    }
  };

  const menuItems = [
    { text: "Dashboard", path: dashboardPath, icon: <DashboardIcon /> },
    { text: "Conversation History", path: "/dashboard/conversation-history", icon: <HistoryIcon /> },
    { text: "Agents", path: "/dashboard/agents", icon: <PeopleIcon /> },
    {
      text: "Integrations",
      path: "/dashboard/integrations",
      icon: <IntegrationInstructionsIcon />,
      status: organization?.integrations?.includes(EHR.healthie) ? null : "Setup required",
    },
    { text: "Developers", path: "/dashboard/developers", icon: <DeveloperModeIcon /> },
    { text: "Docs", path: "https://docs.penciled.com", icon: <LibraryBooksIcon /> },
    { text: "Help", path: "/dashboard/help", icon: <HelpIcon /> },
  ];

  const drawer = (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%", background: "#181C2F" }}>
      <Box>
        <WhitePenciledLogo style={{ width: "150px", margin: "20px 15px" }} />
        <List>
          {menuItems.map((item) => (
            <ListItem key={item.text} disablePadding>
              <ListItemButton
                onClick={() => {
                  posthog?.capture(`[PENCILED] Sidebar item clicked: ${item.text}`);
                  handleListItemClick(item.text, item.path);
                }}
                sx={{
                  backgroundColor: activeItem === item.text ? "#2D3042" : "inherit",
                  "&:hover": {
                    backgroundColor: activeItem === item.text ? Colors.primary : "rgba(0, 0, 255, 0.1)",
                  },
                  borderRadius: "10px",
                  margin: "5px 15px",
                }}
              >
                <ListItemIcon sx={{ color: "white" }}>{item.icon}</ListItemIcon>
                <ListItemText
                  primary={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ fontSize: "0.95rem", color: "#FFF" }}>{item.text}</Typography>
                      {item.status && (
                        <Typography
                          sx={{
                            ml: 1,
                            backgroundColor: "red",
                            color: "white",
                            padding: "2px 4px",
                            borderRadius: "15px",
                            fontSize: "0.65rem",
                          }}
                        >
                          {item.status}
                        </Typography>
                      )}
                    </Box>
                  }
                />
                {activeItem !== item.text && (
                  <ListItemIcon sx={{ minWidth: "unset" }}>
                    <ArrowForwardIosIcon sx={{ fontSize: "12px", color: "#FFF" }} />
                  </ListItemIcon>
                )}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
      {/* <Box sx={{ mt: "auto", p: 3 }}>
        <UsageCard />
      </Box> */}
    </Box>
  );

  return (
    <Box sx={{ display: "flex", background: "#151A32", minHeight: "100vh" }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ display: { lg: "none" }, background: Colors.bg, height: "70px" }}>
        <Toolbar style={{ display: "flex", justifyContent: "space-between", height: "70px" }}>
          <IconButton color="primary" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2 }}>
            <MenuIcon sx={{ fontSize: "30px" }} />
          </IconButton>
          <WhitePenciledLogo style={{ width: "125px" }} />
          <Box onClick={handleClick}>
            <AccountCircleIcon style={{ fontSize: "2.1rem", cursor: "pointer", color: Colors.primary }} />
          </Box>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={() => handleClose()}
            PaperProps={{
              style: menuPropsStyles.PaperProps.sx,
            }}
            sx={dropdownStyles}
          >
            <MenuItem onClick={() => handleClose("account")}>My account</MenuItem>
            <MenuItem onClick={() => handleClose("logout")}>Logout</MenuItem>
          </Menu>
          {/* <SignoutButton
            onClick={() => {
              posthog?.capture("Logout");
              posthog?.reset();
              handleLogout();
            }}
            sx={{ fontSize: "1rem", border: "none", padding: 0 }}
          >
            Sign out
          </SignoutButton> */}
        </Toolbar>
      </AppBar>
      <Box component="nav" sx={{ width: { lg: drawerWidth }, flexShrink: { md: 0 } }}>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", lg: "none" },
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              border: "none",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", lg: "block" },
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              border: "none",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ flexGrow: 1, p: { xs: 3, md: 5 }, background: Colors.bg }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default DashboardSidebar;
