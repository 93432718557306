// slices/agentsSlice.tsx

import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import axios, { AxiosError } from "axios";
import { Agent, FrontendUser } from "../types";
import { sessionExpired } from "./SessionSlice";
import { DateTime } from "luxon";

export interface AgentsSlice {
  agents: Agent[];
  loading: boolean;
  error: string | null; // Add an error field to store error messages
}

const initialState: AgentsSlice = {
  agents: [],
  loading: false,
  error: null, // Initial error state is null
};

export const fetchAgents = createAsyncThunk<
  { agents: Agent[] }, // Expected return type
  { userId: string; token: string }, // Arguments passed to the thunk
  { rejectValue: Error }
>(
  "agents/fetchAgents", // Ensure the prefix matches the slice name
  async ({ userId, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/agents?getStats=true`, {
        params: { userId: userId },
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.data) {
        // Make sure you're accessing the right data property
        const Agent: Agent[] = response.data;
        return { agents: Agent };
      }
      throw new Error("Invalid response data");
    } catch (error) {
      if ((error as AxiosError).response?.status === 401) {
        sessionExpired(true);
      }
      return rejectWithValue(new Error("Failed to fetch agents: " + (error as Error)?.message));
    }
  }
);

export const createAgent = createAsyncThunk(
  "agents/createAgent",
  async ({ agent }: { agent: Partial<Agent> }, { dispatch, getState, rejectWithValue }) => {
    try {
      const user: FrontendUser | null = (getState() as RootState).auth.user as FrontendUser | null;

      if (!user || !user?.token) {
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/agents`,
        {
          agent,
        },
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );

      const newAgent = response.data.agent as Agent;

      dispatch(addAgent(newAgent)); // Add the new agent to the store

      if (response.status === 200) {
        return newAgent;
      } else {
        return rejectWithValue("Failed to create agent.");
      }
    } catch (error) {
      if ((error as AxiosError).response?.status === 401) {
        sessionExpired(true);
      }
      return rejectWithValue("Failed to create agent: " + (error as Error).message);
    }
  }
);

export const updateAgentThunk = createAsyncThunk(
  "agents/updateAgent",
  async ({ agent }: { agent: Partial<Agent> }, { dispatch, getState, rejectWithValue }) => {
    try {
      const user: FrontendUser | null = (getState() as RootState).auth.user as FrontendUser | null;

      if (!user || !user?.token) {
        return;
      }

      const response = await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/api/agents/${agent.agentId}`,
        {
          agent,
        },
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );

      dispatch(updateAgent(agent as Agent)); // Update the agent in the store

      if (response.status === 200) {
        return agent; // Return the updated agent data
      } else {
        return rejectWithValue("Failed to update agent.");
      }
    } catch (error) {
      if ((error as AxiosError).response?.status === 401) {
        sessionExpired(true);
      }
      return rejectWithValue("Failed to update agent: " + (error as Error).message);
    }
  }
);

const agentsSlice = createSlice({
  name: "agents",
  initialState,
  reducers: {
    // Define a reducer to update an agent directly in the state
    updateAgent: (state, action: PayloadAction<Agent>) => {
      const index = state.agents.findIndex((agent: Agent) => agent.agentId === action.payload.agentId);
      if (index !== -1) {
        state.agents[index] = { ...state.agents[index], ...action.payload };
      }
    },
    clearAgents: (state) => {
      state.agents = [];
      state.loading = false;
      state.error = null;
    },
    addAgent: (state, action: PayloadAction<Agent>) => {
      if (!state.agents.find((agent) => agent.agentId === action.payload.agentId)) {
        state.agents.push(action.payload);
      }
    },
  },
  extraReducers: (builder) => {
    // your existing cases
    builder
      .addCase(fetchAgents.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAgents.fulfilled, (state, action) => {
        state.agents = action.payload.agents;
        state.loading = false;
      })
      .addCase(fetchAgents.rejected, (state, action) => {
        state.error = action.error.message || "Failed to fetch agents";
        state.loading = false;
      })
      .addCase(updateAgentThunk.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateAgentThunk.rejected, (state, action) => {
        state.error = action.error.message || "Failed to update agent";
      })
      .addCase(createAgent.rejected, (state, action) => {
        state.error = action.error.message || "Failed to create agent";
      })
      .addCase(createAgent.pending, (state) => {
        state.loading = true;
      })
      .addCase(createAgent.fulfilled, (state, action) => {
        state.loading = false;
      });
  },
});

export const selectAgents = (state: RootState) => state.agents.agents;
export const { updateAgent, clearAgents, addAgent } = agentsSlice.actions;

export default agentsSlice.reducer;
