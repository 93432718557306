import React, { useEffect, useRef } from "react";
import "./App.css";
import { Navigate, Route, Routes } from "react-router-dom";
import useWindowWidth from "./useWindowWidth";
import ProtectedRoute from "./generalPages/ProtectedRoute";
import AgentType from "./AuthFlow/AgentType";
import PatientCommunication from "./AuthFlow/PatientCommunication";
import SchedulingSoftwarePage from "./AuthFlow/SchedulingSoftwarePage";
import PatientFinding from "./AuthFlow/PatientFinding";
import SelfReferral from "./AuthFlow/SelfReferral";
import Referral from "./AuthFlow/Referral";
import CloudFaxReferralChoice from "./AuthFlow/CloudFaxProviderChoice";
import AgentInfo from "./AuthFlow/AgentInfo";
import Dashboard from "./Dashboard/Dashboard";
import DashboardSidebar from "./Dashboard/DashboardSidebar";
import ConversationHistory from "./Dashboard/ConversationHistory";
import CallInfo from "./Dashboard/CallInfo";
import Integrations from "./Dashboard/Integrations";
import HIPAA from "./Dashboard/HIPAA";
import Help from "./Dashboard/Help";
import Agents from "./Dashboard/Agents";
import AgentInfoPage from "./Dashboard/AgentInfoPage";
import AgentConvoHistory from "./Dashboard/AgentConvoHistory";
import Account from "./Dashboard/MyAccount";
import Debug from "./Debug";
import LoginPage from "./generalPages/LoginPage";
import ForgotPasswordPage from "./generalPages/ForgotPassword";
import APIKeys from "./Dashboard/APIKeys";
import { WebsocketEvents, StreamType, FrontendScheduledCall, Call, WebsocketActions, EHR, Organization, WaitlistRun } from "./types";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "./store";
import { refreshAgentScheduledCallsPaginated } from "./slices/ScheduledCallSlice";
import { addCall, refreshAgentCallsPaginated } from "./slices/CallSlice";
import { fetchWalkthroughStatus, getSession } from "./slices/AuthSlice";
import { usePostHog } from "posthog-js/react";
import Webhooks from "./Dashboard/Webhooks";
import Developers from "./Dashboard/Developers";
import WaitlistRunInfo from "./Dashboard/WaitlistRunInfo";
import SignupWalkthrough from "./walkthrough/SignupWalkthrough";
import GetStarted from "./Dashboard/GetStarted";
import TextInfo from "./Dashboard/TextInfo";
import WaitlistSlotInfo from "./Dashboard/WaitlistSlotInfo";
import { fetchWaitlistRuns, setNewestRunIds } from "./slices/WaitlistRunsSlice";
import CalendlyIntegration from "./Dashboard/CalendlyIntegration";
import SelfAddForm from "./forms/SelfAddForm";

function App() {
  const user = useSelector((state: RootState) => state.auth.user);
  const windowWidth = useWindowWidth();
  const minWidth = 200;
  const socketRef = useRef<WebSocket | null>(null);
  const dispatch = useAppDispatch();
  const posthog = usePostHog();
  const organization: Organization | null = useSelector((state: RootState) => state.auth.organization);

  useEffect(() => {
    const awaitDispatch = async () => {
      if (!user || !user?.token) {
        return;
      }
      await dispatch(getSession() as any);
    };

    awaitDispatch();
  }, [dispatch]);

  useEffect(() => {
    const fetchWalkthroughStatusDispatch = async () => {
      if (user) {
        await dispatch(fetchWalkthroughStatus());
      }
    };
    fetchWalkthroughStatusDispatch();
  }, [user, dispatch]);

  useEffect(() => {
    if (!user || !user?.userId || !user?.token) {
      return;
    }

    // Check if a WebSocket connection already exists
    if (socketRef.current && (socketRef.current.readyState === WebSocket.OPEN || socketRef.current.readyState === WebSocket.CONNECTING)) {
      console.log("WebSocket connection is already open or connecting.");
      return;
    }

    const connectWebSocket = async () => {
      try {
        const socket = new WebSocket(
          `wss://${process.env.REACT_APP_BACKEND_URL_NO_HTTPS}/api/event-subscriptions/${user.userId}?token=${encodeURIComponent(user.token!)}`
        );
        socketRef.current = socket;

        socket.onopen = () => {
          console.log("Connected to WebSocket");
          socket.send(JSON.stringify({ action: WebsocketActions.subscribe, type: WebsocketEvents.calls }));
          socket.send(JSON.stringify({ action: WebsocketActions.subscribe, type: WebsocketEvents.scheduledCalls }));
          socket.send(JSON.stringify({ action: WebsocketActions.subscribe, type: WebsocketEvents.waitlistRuns }));
        };

        socket.onmessage = (event: MessageEvent<string>) => {
          const message: { eventType: WebsocketEvents; streamType: StreamType; data: Call | FrontendScheduledCall | any } = JSON.parse(
            event.data
          ) as {
            eventType: WebsocketEvents;
            streamType: StreamType;
            data: Call | FrontendScheduledCall | any;
          };

          switch (message.eventType) {
            case WebsocketEvents.calls:
              switch (message.streamType) {
                case StreamType.INSERT: {
                  const call: Call = message.data as Call;
                  dispatch(addCall({ call: call }));
                  if (!user?.token) {
                    return;
                  }
                  dispatch(refreshAgentCallsPaginated({ token: user?.token, agentId: call.agentId })); // Refresh the scheduled calls list
                  break;
                }
                case StreamType.REMOVE: {
                  // const callId: string = (message.data as FrontendCall).callId;
                  // dispatch(deleteCall({ callId: callId }));
                  break;
                }
                case StreamType.MODIFY: {
                  //const callModifyCase: FrontendCall = message.data;
                  //const { callId: callIdModifyCase, ...rest } = callModifyCase;
                  // dispatch(modifyCall({ callId: callIdModifyCase, changes: rest }));
                  break;
                }
              }
              break;
            case WebsocketEvents.scheduledCalls:
              switch (message.streamType) {
                case StreamType.INSERT: {
                  const scheduledCallInsertCase: FrontendScheduledCall = message.data as FrontendScheduledCall;
                  if (!user?.token) {
                    return;
                  }
                  dispatch(refreshAgentScheduledCallsPaginated({ agentId: scheduledCallInsertCase.agentId }));
                  break;
                }
                case StreamType.REMOVE: {
                  const scheduledCallRemoveCase: FrontendScheduledCall = message.data as FrontendScheduledCall;
                  if (!user?.token) {
                    return;
                  }
                  dispatch(refreshAgentScheduledCallsPaginated({ agentId: scheduledCallRemoveCase.agentId }));
                  break;
                }
                case StreamType.MODIFY: {
                  const scheduledCallModifyCase: FrontendScheduledCall = message.data as FrontendScheduledCall;
                  if (!user?.token) {
                    return;
                  }
                  dispatch(refreshAgentScheduledCallsPaginated({ agentId: scheduledCallModifyCase.agentId }));
                  break;
                }
              }
              break;
            case WebsocketEvents.waitlistRuns:
              switch (message.streamType) {
                case StreamType.INSERT: {
                  const waitlistRun: WaitlistRun = message.data as WaitlistRun;
                  dispatch(setNewestRunIds([waitlistRun.waitlistRunId]));
                  dispatch(fetchWaitlistRuns({ agentId: message.data.agentId }));

                  break;
                }
                case StreamType.REMOVE: {
                  dispatch(setNewestRunIds([]));
                  dispatch(fetchWaitlistRuns({ agentId: message.data.agentId }));
                  break;
                }
                case StreamType.MODIFY: {
                  const waitlistRun: WaitlistRun = message.data as WaitlistRun;
                  dispatch(setNewestRunIds([waitlistRun.waitlistRunId]));
                  dispatch(fetchWaitlistRuns({ agentId: message.data.agentId }));

                  break;
                }
              }
              break;
            default:
              console.log("Received unknown message:", message);
              break;
          }
        };

        socket.onclose = () => {
          console.log("Disconnected from WebSocket");
          socketRef.current = null;
        };

        socket.onerror = (error) => {
          console.error("WebSocket error:", error);
          socket.close();
        };

        return () => {
          socket.close();
        };
      } catch (error) {
        console.error("Error connecting to WebSocket:", error);
      }
    };

    connectWebSocket();

    // Clean up the WebSocket connection when the component unmounts or user changes
    return () => {
      if (socketRef.current) {
        socketRef.current.close();
        socketRef.current = null;
      }
    };
  }, [user?.token]);

  useEffect(() => {
    if (!user || !user?.userId) {
      return;
    }
    posthog?.identify(user.userId);
    posthog?.people.set({ email: user.email, firstName: user.firstName, lastName: user.lastName, organizationId: user.organizationId });
    posthog?.capture("Logged in", { email: user.email });
  }, [user]);

  return (
    <>
      {windowWidth >= minWidth ? (
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/login" element={user ? <Navigate to="/dashboard" replace /> : <LoginPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/walkthrough/agent-type" element={<AgentType />} />
          <Route path="/walkthrough/communication" element={<PatientCommunication />} />
          <Route path="/walkthrough/scheduling" element={<SchedulingSoftwarePage />} />
          <Route path="/walkthrough/patient-finding" element={<PatientFinding />} />
          <Route path="/walkthrough/self-referral" element={<SelfReferral />} />
          <Route path="/walkthrough/referral" element={<Referral />} />
          <Route path="/walkthrough/cloud-fax" element={<CloudFaxReferralChoice />} />
          <Route path="/walkthrough/build-agent" element={<AgentInfo />} />
          <Route path="/walkthrough/create-account" element={<SignupWalkthrough />} />
          
          <Route path="/dashboard" element={<ProtectedRoute element={<DashboardSidebar />} />}>
            <Route index element={<Navigate to="/dashboard/get-started" replace />} />
            <Route path="/dashboard/get-started" element={<GetStarted />} />
            <Route path="/dashboard/conversation-history" element={<ConversationHistory />} />
            <Route path="/dashboard/conversation-history/calls/:callId" element={<CallInfo />} />
            <Route path="/dashboard/agents" element={<Agents />} />
            <Route path="/dashboard/agents/:agentId" element={<AgentInfoPage />} />
            <Route path="/dashboard/agents/:agentId/agent-conversation-history" element={<AgentConvoHistory />} />
            <Route path="/dashboard/agents/:agentId/agent-conversation-history/calls/:callId" element={<CallInfo />} />
            <Route path="/dashboard/agents/:agentId/waitlist-runs/:waitlistRunId" element={<WaitlistRunInfo />} />
            <Route path="/dashboard/agents/:agentId/waitlist-runs/:waitlistRunId/calls/:callId" element={<CallInfo />} />
            <Route path="/dashboard/agents/:agentId/waitlist-runs/:waitlistRunId/texts/:textId" element={<TextInfo />} />
            <Route path="/dashboard/agents/:agentId/waitlist-runs/:waitlistRunId/slots/:waitlistSlotId" element={<WaitlistSlotInfo />} />
            <Route path="/dashboard/integrations" element={<Integrations />} />
            <Route path="/dashboard/hipaa" element={<HIPAA />} />
            <Route path="/dashboard/help" element={<Help />} />
            <Route path="/dashboard/account" element={<Account />} />
            <Route path="/dashboard/developers" element={<Developers />} />
            <Route path="/dashboard/keys" element={<APIKeys />} />
            <Route path="/dashboard/webhooks" element={<Webhooks />} />
          </Route>
          <Route path="/patient/self-add/:orgId" element={<SelfAddForm />} />
          <Route path="/calendly/auth" element={<CalendlyIntegration />} />
          <Route path="/debug" element={<Debug />} />
        </Routes>
      ) : (
        <div style={{ width: "70%", margin: "100px auto", fontSize: "2rem" }}>
          The screen is too small to display this application. Please use a larger screen.
        </div>
      )}
    </>
  );
}

export default App;
