import React from "react";
import { DateTime } from "luxon";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import { Box } from "@mui/material";
import LoopIcon from "@mui/icons-material/Loop";
import { CallStatuses, CloudFaxProvider, EHR, OutcomeSentiment, WaitlistTextStates } from "../types";
import calendlyLogo from "../assets/Calendly.png";
import faxplusLogo from "../assets/faxpluslogo.png";
import healthieLogo from "../assets/HealthieWhiteLogo.png";
import { Colors } from "../Colors";
import { RootState } from "../store";

/**
 * Takes in a timezone-aware ISO 8601 date string `isoString` and returns a formatted date string in the format 'M/d/yy'.
 *
 * If the input input is invalid, throw an error.
 *
 * @param isoString - The timezone-aware ISO 8601 date string eg 2024-05-20T11:30:12.000-07:00
 * @returns The formatted date string in the format 'Monday, M/d/yy at 4:30 PM'
 * @throws An error if the input ISO string is invalid
 */
export function formatIsoToCustomDateStringWithEEEEHHMMA(isoString: string): string {
  const dateTime = DateTime.fromISO(isoString, { setZone: true }); // Assuming the input ISO string is in UTC
  if (!dateTime.isValid) {
    throw new Error("Invalid ISO 8601 date string");
  }
  return dateTime.toFormat("EEEE, M/d/yy 'at' h:mm a");
}

/**
 * Takes in a timezone-aware ISO 8601 date string `isoString` and returns a formatted date string in the format 'Monday, M/d/yy'.
 *
 * If the input is invalid, throw an error.
 *
 * @param isoString - The timezone-aware ISO 8601 date string eg 2024-05-20T11:30:12.000-07:00
 * @returns The formatted date string in the format 'Monday, M/d/yy'
 * @throws An error if the input ISO string is invalid
 */
export function formatIsoToCustomDateStringWithEEEE(isoString: string): string {
  try {
    // Parse the ISO string with timezone
    const dateTime = DateTime.fromISO(isoString, { setZone: true });

    // Check if the DateTime object is valid
    if (!dateTime.isValid) {
      // throw new Error('Invalid ISO 8601 date string');
      return isoString;
    }

    // Extract the timezone from the input ISO string
    const timezone = dateTime.zoneName;

    // Set the DateTime object to the extracted timezone
    const dateTimeWithZone = dateTime.setZone(timezone);

    // Return the formatted date string
    return dateTimeWithZone.toFormat("EEEE, M/d/yy");
  } catch (error) {
    console.error("formatIsoToCustomDateStringWithEEEE Error: ", error);
    throw error; // Rethrow the error to ensure it propagates correctly
  }
}

export const textStateIcons: Record<WaitlistTextStates, React.ReactNode> = {
  [WaitlistTextStates.firstMessageSent]: <Box width={24} height={24} />,
  [WaitlistTextStates.inProgress]: <LoopIcon />,
  [WaitlistTextStates.accepted]: <EventAvailableIcon />,
  [WaitlistTextStates.declined]: <EventBusyIcon />,
  [WaitlistTextStates.notContacted]: <DoNotDisturbIcon />,
  [WaitlistTextStates.expired]: <Box width={24} height={24} />,
};

// Define the colors for each state
export const textStateColors: Record<WaitlistTextStates, string> = {
  [WaitlistTextStates.firstMessageSent]: "#4CAF50",
  [WaitlistTextStates.inProgress]: "#757575",
  [WaitlistTextStates.accepted]: "#4CAF50",
  [WaitlistTextStates.declined]: "#F44336",
  [WaitlistTextStates.notContacted]: "#757575",
  [WaitlistTextStates.expired]: "#757575",
};

/**
 * Generates a CryptoKey object from a PEM-formatted public key.
 *
 * @param publicKeyPem - The PEM-formatted public key
 * @returns - The CryptoKey object
 */
export async function importPublicKey(publicKeyPem: string): Promise<CryptoKey> {
  if (!publicKeyPem) {
    throw new Error("Public key not found");
  }

  // Correctly replace PEM header, footer, and newlines
  const base64String = publicKeyPem
    .replace(/-----BEGIN PUBLIC KEY-----/g, "")
    .replace(/-----END PUBLIC KEY-----/g, "")
    .replace(/\s+/g, ""); // Removes all whitespace including newlines

  if (!base64String) {
    throw new Error("Base64 string not found after replacements");
  }

  // Decode the base64 string to binary data
  const binaryDerString = window.atob(base64String);

  const binaryDer = new Uint8Array(binaryDerString.length);
  for (let i = 0; i < binaryDerString.length; i++) {
    binaryDer[i] = binaryDerString.charCodeAt(i);
  }

  return await window.crypto.subtle.importKey("spki", binaryDer.buffer, { name: "RSA-OAEP", hash: "SHA-256" }, true, ["encrypt"]);
}

/**
 * Given a public key `publicKey` and data `data`, encrypts the data using the public key.
 *
 * @param publicKey - The public key to use for encryption
 * @param data - The data to encrypt
 * @returns - The encrypted data
 */
export async function encryptData(publicKey: CryptoKey, data: string): Promise<string> {
  const encoded = new TextEncoder().encode(data);
  const encrypted = await window.crypto.subtle.encrypt({ name: "RSA-OAEP" }, publicKey, encoded);
  return window.btoa(String.fromCharCode(...new Uint8Array(encrypted)));
}

/**
 * Encrypts the given data `dataToEncrypt` using the public key stored in the environment variable `REACT_APP_PUBLIC_ENCRYPTION_KEY`.
 *
 * @param dataToEncrypt - The data to encrypt
 */
export async function encryptTokenWithPublicKey(dataToEncrypt: string): Promise<string> {
  const publicKeyPem = process.env.REACT_APP_PUBLIC_ENCRYPTION_KEY?.replace(/\\n/g, "\n");

  try {
    if (!publicKeyPem) {
      console.error("Public key not found");
      throw new Error("Public key not found");
    }

    const publicKey: CryptoKey = await importPublicKey(publicKeyPem);
    return await encryptData(publicKey, dataToEncrypt);
  } catch (error) {
    console.error("Encryption error:", error);
    throw error;
  }
}

export const cloudFaxProviderNames = {
  [CloudFaxProvider.faxPlus]: "Fax.Plus",
  [CloudFaxProvider.other]: "Other",
};

export const EHRLogos = {
  [EHR.healthie]: healthieLogo,
};

export const cloudFaxProviderLogos = {
  [CloudFaxProvider.faxPlus]: faxplusLogo,
  [CloudFaxProvider.other]: faxplusLogo,
};

export const callStatusDisplay = (status: CallStatuses) => {
  switch (status) {
    case CallStatuses.calling:
      return "Calling";
    case CallStatuses.complete:
      return "Complete";
    case CallStatuses.error:
      return "Error";
    case CallStatuses.voicemail:
      return "Voicemail";
  }
}

export const callStatusColor = (status: CallStatuses) => {
  switch (status) {
    case CallStatuses.calling:
      return Colors.primary;
    case CallStatuses.complete:
      return Colors.success;
    case CallStatuses.error:
      return Colors.error;
    case CallStatuses.voicemail:
      return 'white';
  }
}

export const getOutcomeColor = (outcomeSentiment: OutcomeSentiment) => {
  if (!outcomeSentiment) return Colors.grey3;   
  switch (outcomeSentiment) {
    case OutcomeSentiment.neutral:
      return 'white';
    case OutcomeSentiment.negative:
      return Colors.error;
    case OutcomeSentiment.positive:
      return Colors.success;
    default:
      return Colors.grey3; 
  }
};

/**
 * Converts a snake_case string to an official format.
 * 
 * @param input - The snake_case string to convert.
 * @returns The string in official format.
 */
export function convertSnakeCaseToOfficial(input: string): string {
  return input
    .split('_') // Split the string by underscores
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
    .join(' '); // Join the words with a space
}

/**
 * Converts a camelCase string to an official format.
 * 
 * @param input - The camelCase string to convert.
 * @returns The string in official format.
 */
export function convertCamelCaseToOfficial(input: string): string {
  return input
    .replace(/([a-z])([A-Z])/g, '$1 $2') // Insert a space before uppercase letters that are preceded by lowercase letters
    .replace(/^./, str => str.toUpperCase()) // Capitalize the first letter of the string
    .replace(/\b\w/g, char => char.toUpperCase()); // Capitalize the first letter of each word
}

/**
 * Converts a kebab-case string to an official format.
 * 
 * @param input - The kebab-case string to convert.
 * @returns The string in official format.
 */
export function convertKebabCaseToOfficial(input: string): string {
  return input
    .split('-') // Split the string by hyphens
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
    .join(' '); // Join the words with a space
}


/**
 * Validates if a string is a valid phone number in E.164 format with or without country code.
 * 
 * @param string - The string to check
 * @returns A boolean indicating whether the string is a valid E.164 phone number
 */
export function isPhoneNumber(string: string): boolean {
  // Regular expression to match phone numbers with:
  // - +1xxxxxxxxxx
  // - 1xxxxxxxxxx
  // - xxxxxxxxxx
  return /^(\+1|1)?\d{10}$/.test(string);
}

/**
 * Converts a phone number into E.164 format, which consists of a + followed by the country code and subscriber number. 
 * 
 * 
 * Accepts any format of phone number listed:
 * `+1xxxxxxxxxx`, (xxx) xxx-xxxx, xxx-xxx-xxxx, xxxxxxxxxx, and returns `+1xxxxxxxxxx`.
 * 
 * https://www.twilio.com/docs/glossary/what-e164
 * 
 * @param phoneNumber - The phone number to convert
 * @param countryCode - The country code to prepend to the phone number
 * @returns The phone number in the format '+[countryCode][phoneNumber]'
 */
export function convertToCallablePhoneNumber(phoneNumber: string, countryCode: string = "1"): string {
  if (!phoneNumber) {
    throw new Error('Invalid phone number');
  }

  // Remove all non-numeric characters except the leading plus sign if present
  const cleanedPhoneNumber = phoneNumber.replace(/(?!^\+)\D/g, '');

  // Check if the phone number is already in the format `+1xxxxxxxxxx`
  if (cleanedPhoneNumber.startsWith(`+${countryCode}`) && cleanedPhoneNumber.length === countryCode.length + 11) {
    return cleanedPhoneNumber;
  }

  // Ensure it returns a phone number in the format `+1xxxxxxxxxx`
  return `+${countryCode}${cleanedPhoneNumber.replace(/^\+/, '')}`;
};
