import React, { FocusEventHandler, useCallback, useEffect, useState } from "react";
import { Modal, Box, Typography, Table, TableHead, TableRow, TableCell, TableBody, Button, IconButton, Icon, TextField } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseIcon from "@mui/icons-material/Close";

interface NumberTextFieldProps {
  minValue: number;
  maxValue: number;
  defaultValue?: number;
  onChange: (value: number) => void;
}

const NumberTextField: React.FC<NumberTextFieldProps> = ({ minValue, maxValue, defaultValue = 0, onChange }) => {
  const [value, setValue] = useState<number | string>(defaultValue);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleBlur = () => {
    const numericValue = parseFloat(value as string);

    if (isNaN(numericValue)) {
      // If the value is not a number, reset it to the default or minValue
      setValue(defaultValue);
      onChange(defaultValue);
    } else if (numericValue < minValue) {
      setValue(minValue);
      onChange(minValue);
    } else if (numericValue > maxValue) {
      setValue(maxValue);
      onChange(maxValue);
    } else {
      setValue(numericValue);
      onChange(numericValue);
    }
  };

  return <TextField type="number" value={value} onChange={handleChange} onBlur={handleBlur} variant="outlined" style={{ width: "80px" }} />;
};

const Debug: React.FC = () => {
  const [patients, setPatients] = useState([{ name: "Alice" }, { name: "Bob" }, { name: "Charlie" }, { name: "David" }, { name: "Eve" }]);

  function moveElement<T>(array: T[], fromIndex: number, toIndex: number): T[] {
    const newArray = [...array]; // Create a copy of the array
    const [element] = newArray.splice(fromIndex, 1); // Remove the element from the fromIndex
    newArray.splice(toIndex, 0, element); // Insert the element at the toIndex
    console.log(newArray);
    return newArray;
  }

  const moveRowUp = (index: number) => {
    if (index === 0) return;
    setPatients(moveElement(patients, index, index - 1));
  };

  const moveRowDown = (index: number) => {
    if (index === patients.length - 1) return;
    setPatients(moveElement(patients, index, index + 1));
  };

  const handlePriorityChange = (fromIndex: number, toIndex: number) => {
    if (toIndex < 0 || toIndex >= patients.length) return;
    setPatients(moveElement(patients, fromIndex, toIndex));
  };

  const columnTypes = ["Priority", "Name"];

  return (
    <Table style={{ width: "auto" }}>
      <TableHead>
        <TableRow>
          {columnTypes.map((type, index) => (
            <TableCell key={index}>{type}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {patients.map((patient, index) => (
          <TableRow key={patient.name}>
            <TableCell>
              <NumberTextField
                key={index}
                minValue={1}
                maxValue={patients.length}
                defaultValue={index + 1}
                onChange={(value) => handlePriorityChange(index, value - 1)}
              />
              <IconButton onClick={() => moveRowUp(index)} disabled={index === 0}>
                <KeyboardArrowUpIcon />
              </IconButton>
              <IconButton onClick={() => moveRowDown(index)} disabled={index === patients.length - 1}>
                <KeyboardArrowDownIcon />
              </IconButton>
            </TableCell>
            <TableCell>{patient.name}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default Debug;
