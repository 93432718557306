import React, { useState } from "react";
import {
  Checkbox,
  Typography,
  Box,
  FormControl,
  OutlinedInput,
  Tooltip,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@mui/material";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { signUp } from "../../slices/AuthSlice";
import { useAppDispatch } from "../../store";
import { useSnackbar } from "../../providers/SnackbarProvider";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Colors } from "../../Colors";
import { PrimaryButton } from "../../subcomponents/CustomButton";
import { PartialFrontendUser } from "../../types";
import TextInputComponent from "../../subcomponents/TextInputComponent";

const ModalBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 35%;
  margin: auto;
  background-color: #1b1f32;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);

  @media (max-width: 1100px) {
    width: 80%;
    padding: 20px;
    margin: 10% auto;
  }

  @media (max-width: 768px) {
    margin: 5% auto;
  }

  @media (max-width: 480px) {
    padding: 30px 15px;
  }

  @media (max-width: 380px) {
    padding: 15px 20px;
  }
`;

const FormBox = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;

  @media (max-width: 1100px) {
    width: 90%;
  }
`;

const RowBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 5%;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
  }
`;

const PasswordRequirements = styled(Box)`
  font-size: 0.8rem;
  color: #555;

  ul {
    padding-left: 20px;
    margin: 0;
  }

  li {
    list-style-type: disc;
    color: #acacac;
    margin-bottom: 5px;

    &.valid {
      color: lightgreen;
      font-weight: bold;
    }
  }
`;

interface CreateAccountFormProps {
  onSuccess: () => void;
  onUserData: (data: PartialFrontendUser) => void;
  onLoading: (loading: boolean) => void;
}

const CreateAccountForm: React.FC<CreateAccountFormProps> = ({ onSuccess, onUserData, onLoading }) => {
  const [termsAndPrivacyChecked, setTermsAndPrivacyChecked] = useState(false);
  const [hipaaBaaChecked, setHipaaBaaChecked] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordFocused, setPasswordFocused] = useState(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [createAccountLoading, setCreateAccountLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const dispatch = useAppDispatch();
  const { showMessage } = useSnackbar();

  const isLengthValid = password.length >= 8;
  const hasUppercase = /[A-Z]/.test(password);
  const hasLowercase = /[a-z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecialChar = /[!@#$%^&*()]/.test(password);
  const passwordIsValid = isLengthValid && hasUppercase && hasLowercase && hasNumber && hasSpecialChar;

  const handleCreateAccount = async () => {
    setCreateAccountLoading(true);
    onLoading(true);

    try {
      const action = await dispatch(
        signUp({
          email,
          password,
          firstName,
          lastName,
        })
      );

      if (action.type === "auth/signUp/rejected") {
        throw new Error(`Sign up failed: ${(action.payload as Error).message}`);
      }

      const newUserData = action.payload as PartialFrontendUser;
      onUserData(newUserData);
      onSuccess();
      showMessage("Verification code sent to your email", "Fill code and verify your email",  "success");
    } catch (error) {
      console.error("Sign-up failed:", error);
      setErrorMessage((error as Error).message);
      showMessage("Sign-up failed:", `${(error as Error).message}`, "error");
    } finally {
      setCreateAccountLoading(false);
      onLoading(false);
    }
  };

  const isFormValid = () => {
    return (
      firstName.trim() !== "" &&
      lastName.trim() !== "" &&
      email.trim() !== "" &&
      password.trim() !== "" &&
      termsAndPrivacyChecked &&
      hipaaBaaChecked &&
      passwordIsValid
    );
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <ModalBox>
      <FormBox>
        <RowBox>         
          <TextInputComponent
            label="First Name"
            placeholder="eg. Shawn"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          /> 

          <TextInputComponent
            label="Last Name"
            placeholder="eg. Shivdat"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />

        </RowBox>

        <TextInputComponent
          label="Email Address"
          placeholder="Enter your email address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />

        <FormControl fullWidth sx={{ margin: '10px 0' }}>
          <Typography variant="body2" sx={{ fontWeight: "bold", color: "#E3E5EA", marginBottom: "5px" }}>
            Password
          </Typography>
          <Tooltip
            title={
              <PasswordRequirements>
                <ul>
                  <li className={isLengthValid ? "valid" : ""}>At least 8 characters in length</li>
                  <li className={hasUppercase ? "valid" : ""}>Contains at least one uppercase letter</li>
                  <li className={hasLowercase ? "valid" : ""}>Contains at least one lowercase letter</li>
                  <li className={hasNumber ? "valid" : ""}>Contains at least one number</li>
                  <li className={hasSpecialChar ? "valid" : ""}>Contains at least one special character (e.g., !@#$%^&*())</li>
                </ul>
              </PasswordRequirements>
            }
            open={passwordFocused}
            placement="bottom-start"
          >
            <OutlinedInput
              placeholder="Password"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onFocus={() => setPasswordFocused(true)}
              onBlur={() => setPasswordFocused(false)}
              style={{ borderRadius: "10px", fontSize: "14px", color: "#FFFFFF", backgroundColor: Colors.textfield }}
              required
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    sx={{ color: Colors.info }}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Tooltip>
        </FormControl>

        <Typography variant="body2" sx={{ mt: 1, color: "#E3E5EA", fontSize: "0.8rem" }}>
          Already have an account?{" "}
          <Link to="/login" style={{ textDecoration: "none", color: Colors.primary }}>
            Sign in
          </Link>
        </Typography>

        <Box display="flex" alignItems="center" marginTop={"20px"}>
          <Checkbox checked={termsAndPrivacyChecked} onChange={() => setTermsAndPrivacyChecked(!termsAndPrivacyChecked)} sx={{ color: Colors.info }} required />
          <Typography style={{ fontSize: "12px", color: "#E3E5EA" }}>
            I agree with the{" "}
            <a target="_blank" rel="noreferrer" href="https://penciled.com/Terms" style={{ textDecoration: "none", color: Colors.primary }}>
              Terms
            </a>{" "}
            and{" "}
            <a target="_blank" rel="noreferrer" href="https://penciled.com/Privacy" style={{ textDecoration: "none", color: Colors.primary }}>
              Privacy Policy
            </a>{" "}
            of this company.
          </Typography>
        </Box>

        <Box display="flex" alignItems="center" marginBottom="20px">
          <Checkbox checked={hipaaBaaChecked} onChange={() => setHipaaBaaChecked(!hipaaBaaChecked)} sx={{ color: Colors.info }} required />
          <Typography style={{ fontSize: "12px", color: "#E3E5EA" }}>
            I agree with the{" "}
            <a target="_blank" rel="noreferrer" href="https://penciled.com/Baa" style={{ textDecoration: "none", color: Colors.primary }}>
              Business Associate Agreement
            </a>{" "}
            regarding compliance with the Health Insurance Portability and Accountability Act.
          </Typography>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
          <PrimaryButton
            onClick={handleCreateAccount}
            disabled={!isFormValid()}
            sx={{
              width: "90%",
              backgroundColor: "#576CBC",
              color: "#FFFFFF",
              '&:disabled': {
                backgroundColor: '#31364B',
                color: Colors.info,
              },
            }}
          >
            {createAccountLoading ? <CircularProgress size={24} color="inherit" /> : "Create Account"}
          </PrimaryButton>
        </Box>

        {/* {errorMessage && (
          <Typography variant="body1" sx={{ color: Colors.error, marginTop: "15px" }}>
            {errorMessage}
          </Typography>
        )} */}
      </FormBox>
    </ModalBox>
  );
};

export default CreateAccountForm;
