import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Switch,
  Checkbox,
  Modal,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
  IconButton,
  Button,
  ListItemText,
  CircularProgress,
} from "@mui/material";
import styled from "styled-components";
import { Colors } from "../Colors";
import { Agent, AgentType, EHR, HealthieEventType, Organization, QuestionAnswerPair } from "../types";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../store";
import { PrimaryButton, SecondaryButton, TransparentButton } from "../subcomponents/CustomButton";
import { useSnackbar } from "../providers/SnackbarProvider";
import { updateAgentThunk } from "../slices/AgentSlice";
import { FormControlWithHorizontalPadding } from "../styles";
import { convertSnakeCaseToOfficial } from "../utils/utils";
import { Padding } from "@mui/icons-material";
import LoadingWithMessage from "../subcomponents/LoadingWithMessage";
import AddIcon from "@mui/icons-material/Add";
import { addQuestionsAndAnswers, deleteQuestion, updateAnswer, getQuestionsAndAnswers } from "../slices/QuestionAndAnswerSlice";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { isPhoneNumber } from "../utils/utils";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import Papa from "papaparse";
import { usePostHog } from "posthog-js/react";
import UploadCSVModal from "../components/modals/UploadCSVModal";
import stringSimilarity from "string-similarity-js";
import CloseIcon from "@mui/icons-material/Close";
import { setInboundRedirectNumber as setInboundRedirectNumberThunk, updateOrganizationAttribute } from "../slices/AuthSlice";
import { TimePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import { HelpTooltip } from "../subcomponents/HelpTooltip";
import axios from "axios";
import { FooterBox, HeaderBox } from "../subcomponents/SidebarComponents";
import { InputStyles } from "../styles/GeneralStyles";

const similarityThreshold = 0.8;

const ModalBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 65%;
  margin: 3% auto;
  background-color: #fff;
  padding: 3%;
  border-radius: 35px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  overflow-y: auto;

  @media (max-width: 1100px) {
    width: 95%;
    margin: 10% auto;
  }

  @media (max-width: 480px) {
    width: 90%;
    padding: 5%;
    margin: 5% auto;
  }
`;

const StyledLink = styled.a`
  font-size: 0.8rem;
  margin-left: 10px;
  color: ${Colors.link};
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledBox = styled(Box)`
  background: #1c2138;
  padding: 15px;
  border-radius: 15px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
`;

const StyledTextField = styled(TextField)`
  width: 100%;
  background-color: ${Colors.textfield};
  border-radius: 10px;
  & .MuiInputBase-input {
    padding: 10px;
    font-size: 0.85rem;
    color: #ffffff;
  }
`;

interface SettingsTextInputProps {
  label: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  mb?: number; // TODO (Hizami): Probably not the best way to do this
  ml?: number;
}

const SettingsTextInput: React.FC<SettingsTextInputProps> = ({ label, value, onChange, mb, ml }) => {
  return (
    <Typography variant="body1" fontSize={"1rem"} mb={mb} ml={ml} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      {label}:
      <TextField
        value={value}
        onChange={onChange}
        variant="outlined"
        sx={{
          marginLeft: "8px",
          "& .MuiInputBase-input": {
            color: "#FFF",
          },
          "& .MuiOutlinedInput-root": {
            borderRadius: "10px",
            background: Colors.textfield,
          },
          "& .MuiOutlinedInput-input": {
            padding: "6px 15px",
          },
        }}
      />
    </Typography>
  );
};

interface SettingsNumberInputProps {
  disabled: boolean;
  value: number;
  setter: (value: number) => void;
}

const SettingsNumberInput: React.FC<SettingsNumberInputProps> = ({ disabled, value, setter }) => {
  return (
    <TextField
      type="number"
      value={value}
      onChange={(e) => setter(parseInt(e.target.value))}
      variant="outlined"
      size="small"
      disabled={disabled}
      sx={{
        width: "45px",
        margin: "0 8px",
        "& .MuiInputBase-input": {
          color: "#FFF",
        },
        "& .MuiOutlinedInput-root": {
          borderRadius: "8px",
          background: Colors.textfield,
        },
        "& .MuiOutlinedInput-input": {
          padding: "2px",
          textAlign: "center",
        },
        "& input[type=number]": {
          "-moz-appearance": "textfield", // Firefox
          "&::-webkit-outer-spin-button": {
            "-webkit-appearance": "none", // Chrome, Safari, Edge, Opera
            margin: 0,
          },
          "&::-webkit-inner-spin-button": {
            "-webkit-appearance": "none", // Chrome, Safari, Edge, Opera
            margin: 0,
          },
        },
      }}
    />
  );
};

interface DropdownProps {
  label: string;
  options: { key: string; value: string }[];
  selectedValue?: string;
  onChange: (value: string) => void;
  disabled?: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({ label, options, selectedValue, onChange, disabled }) => {
  const [selectedOption, setSelectedOption] = useState<string>(options.find((option) => option.key === selectedValue)?.key || "");

  const handleChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setSelectedOption(value);
    onChange(value);
  };

  return (
    <FormControl fullWidth variant="outlined" margin="normal">
      <InputLabel>{label}</InputLabel>
      <Select
        value={selectedOption}
        onChange={handleChange}
        label={label}
        disabled={disabled}
        sx={{
          "& .MuiInputBase-input": {
            padding: "10px",
            fontSize: "0.9rem",
          },
          "& input::placeholder": {
            fontSize: "0.9rem",
            padding: "10px",
          },
        }}
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option.key}>
            {option.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

interface ConfigureFormProps {
  agent: Agent;
  isModalOpen: boolean;
  onClose: () => void;
}

const ConfigureForm: React.FC<ConfigureFormProps> = ({ agent, isModalOpen, onClose }) => {
  const user = useSelector((state: RootState) => state.auth.user);
  const posthog = usePostHog();
  const organization: Organization | null = useSelector((state: RootState) => state.auth.organization);
  const loadingProviders = useSelector((state: RootState) => state.healthieIntegration.loadingProviders);
  const loadingAppointments = useSelector((state: RootState) => state.healthieIntegration.loadingAppointments);
  const loadingPatients = useSelector((state: RootState) => state.healthieIntegration.loadingPatients);
  const providers = useSelector((state: RootState) => state.healthieIntegration.providers);
  const appointments = useSelector((state: RootState) => state.healthieIntegration.appointments);
  const questionsAndAnswers = useSelector((state: RootState) => state.questionsAndAnswers.questionsAndAnswers || []);
  const loadingQuestionsAndAnswers = useSelector((state: RootState) => state.questionsAndAnswers.loading);
  const loadingQuestionsAndAnswersMsg = useSelector((state: RootState) => state.questionsAndAnswers.loadingMsg);

  const { agentType, name } = agent;

  const [agentName, setAgentName] = useState(name);
  const [callbackNumber, setCallbackNumber] = useState(organization?.phoneNumber);
  const [inboundRedirectNumber, setInboundRedirectNumber] = useState(organization?.inboundRedirectNumber);
  const [emergencyTransferNumber, setEmergencyRedirectNumber] = useState(organization?.emergencyTransferNumber);

  const [maxNumberOfRedials, setMaxNumberOfRedials] = useState(agent.maxNumberOfRedials);
  const [redialGapMinutes, setRedialGapMinutes] = useState(agent.redialGapMinutes);
  const [minutesToWaitBeforeCalling, setMinutesToWaitBeforeCalling] = useState(agent.minutesToWaitBeforeCalling || 0);
  const [minutesToCutoffBeforeAppointment, setMinutesToCutoffBeforeAppointment] = useState(agent.minutesToCutoffBeforeAppointment || 0);
  const [daysForwardToCheckAvailableSlots, setDaysForwardToCheckAvailableSlots] = useState(agent.daysForwardToCheckAvailableSlots || 0);

  const [enableTexting, setEnableTexting] = useState(agent.enableTexting);
  //const [enableAutomaticTextResponses, setEnableAutomaticTextResponses] = useState(agent.enableAutomaticTextResponses);
  const [enableCalling, setEnableCalling] = useState(agent.enableCalling);
  const [enableRedialing, setEnableRedialing] = useState(agent.enableRedialing);
  const [enableLeavingVoicemail, setEnableLeavingVoicemail] = useState(agent.enableLeavingVoicemail);
  //const [enableAutomaticCallScheduling, setEnableAutomaticCallScheduling] = useState(agent.enableAutomaticCallScheduling);
  const [isActive, setIsActive] = useState(agent.isActive);

  const [ehrProviderId, setEhrProviderId] = useState(agent.ehrProviderId);
  const [ehrApptTypeId, setEhrApptTypeId] = useState(agent.ehrApptTypeId);
  const [autoStartWaitlistRuns, setAutoStartWaitlistRuns] = useState(agent.autoStartWaitlistRuns);

  const contactTypesMap: { [key: string]: string[] } = {};
  for (const appointment of appointments) {
    contactTypesMap[appointment.id] = appointment.available_contact_types;
  }

  const [uploadingLoading, setUploadingLoading] = useState(false);

  const [consentRequest, setConsentRequest] = useState("");
  const [loadingDefaultMsgs, setLoadingDefaultMsgs] = useState(false);

  const { showMessage } = useSnackbar();
  const dispatch = useAppDispatch();
  const [questionAnswerPairs, setQuestionAnswerPairs] = useState<QuestionAnswerPair[]>(questionsAndAnswers);
  const [newQuestion, setNewQuestion] = useState("");
  const [newAnswer, setNewAnswer] = useState("");
  const [newSource, setNewSource] = useState("");
  const [showSourceInput, setShowSourceInput] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [healthieEventTypes, setHealthieEventTypes] = useState<HealthieEventType[]>(agent.healthieEventTypes || []);

  const [uploadCSVModalOpen, setUploadCSVModalOpen] = useState(false);

  const [workingHoursStart, setWorkingHoursStart] = useState(
    organization?.workingHoursStart ? DateTime.fromISO(organization.workingHoursStart) : null
  );
  const [workingHoursEnd, setWorkingHoursEnd] = useState(organization?.workingHoursEnd ? DateTime.fromISO(organization.workingHoursEnd) : null);

  const settingNumbersLoading = useSelector((state: RootState) => state.auth.settingPhoneNumbersLoading);

  const [intro, setIntro] = useState(agent.intro);
  const [firstText, setFirstText] = useState(agent.firstText);

  const [uploadCsvErrorMsg, setUploadCsvErrorMsg] = useState("");

  const [editingIndex, setEditingIndex] = useState<number | null>(null);

  useEffect(() => {
    const loadDefaultMessages = async () => {
      try {
        setLoadingDefaultMsgs(true);
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/prompts/default/${agent.agentId}`, {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        });

        if (response.status !== 200) {
          return;
        }

        if (!intro) {
          setIntro(response.data.intro);
        }
        setConsentRequest(response.data.consentRequest);
      } catch (error) {
        console.error("Error loading default messages: ", error);
      } finally {
        setLoadingDefaultMsgs(false);
      }
    };

    loadDefaultMessages();
  }, []);

  const updatePhoneNumbers = async () => {
    setUploadingLoading(true);
    await dispatch(updateOrganizationAttribute({ attribute: "phoneNumber", value: callbackNumber }));
    await dispatch(updateOrganizationAttribute({ attribute: "emergencyTransferNumber", value: emergencyTransferNumber }));
    await dispatch(setInboundRedirectNumberThunk({ inboundRedirectNumber: inboundRedirectNumber! }));
    setUploadingLoading(false);
  };

  const updateWorkingHours = async () => {
    setUploadingLoading(true);
    if (workingHoursStart) {
      await dispatch(updateOrganizationAttribute({ attribute: "workingHoursStart", value: workingHoursStart.toISO() }));
    }
    if (workingHoursEnd) {
      await dispatch(updateOrganizationAttribute({ attribute: "workingHoursEnd", value: workingHoursEnd.toISO() }));
    }
    setUploadingLoading(false);
  };

  const handleSave = async () => {
    try {
      await updatePhoneNumbers();
      await updateWorkingHours();
      console.log("Update settled on Save button click.");
      handleCloseModal();
    } catch (error) {
      console.error("Error updating phone numbers on Save: ", error);
    }
  };

  useEffect(() => {
    if (organization?.phoneNumber) {
      setCallbackNumber(organization.phoneNumber);
    }
  }, [organization?.phoneNumber]);

  useEffect(() => {
    if (organization?.inboundRedirectNumber) {
      setInboundRedirectNumber(organization.inboundRedirectNumber);
    }
  }, [organization?.inboundRedirectNumber]);

  useEffect(() => {
    if (organization?.emergencyTransferNumber) {
      setEmergencyRedirectNumber(organization.emergencyTransferNumber);
    }
  }, [organization?.emergencyTransferNumber]);

  const handleFileChange = (file: File) => {
    if (file) {
      Papa.parse(file, {
        complete: function (results: any) {
          const headers = results.data[0];
          const dataRows = results.data.slice(1); // Exclude the header row

          // Ensure the CSV has the expected headers
          const expectedHeaders = ["question", "answer"];
          const hasValidHeaders = expectedHeaders.every((header) => headers.includes(header.toLowerCase()));

          if (!hasValidHeaders) {
            setUploadCsvErrorMsg("CSV file must have headers: 'question', 'answer'");
            return;
          }

          // Map the headers to their indices
          const headerIndexMap: { [key: string]: number } = headers.reduce((acc: any, header: string, index: number) => {
            acc[header] = index;
            return acc;
          }, {});

          let mostSimilarQuestion = "";
          let highestSimilarityRating = 0;

          // Convert each row into a QuestionAnswerPair and filter out close duplicates
          const questionsAndAnswersToAdd: QuestionAnswerPair[] = dataRows
            .map((row: any) => ({
              id: "", // Assuming id will be generated later or left empty
              question: row[headerIndexMap["question"]],
              answer: row[headerIndexMap["answer"]],
              source: row[headerIndexMap["source"]],
            }))
            .filter((newPair: QuestionAnswerPair) => {
              const newQuestion: string = newPair.question;

              const isDuplicate = questionAnswerPairs.some((pair) => {
                const similarityRating = stringSimilarity(newQuestion, pair.question);

                if (similarityRating > highestSimilarityRating) {
                  highestSimilarityRating = similarityRating;
                  mostSimilarQuestion = pair.question;
                }

                return similarityRating > 0.8;
              });

              return !isDuplicate;
            });

          if (questionsAndAnswersToAdd.length > 0) {
            // Dispatch the thunk with the filtered list of question-answer pairs
            dispatch(addQuestionsAndAnswers({ questionsAndAnswers: questionsAndAnswersToAdd }));
          } else {
            const errorMessage = `No new unique questions to add. Most similar existing question: "${mostSimilarQuestion}" that is ${(
              highestSimilarityRating * 100
            ).toFixed(0)}% similar.`;
            setErrorMessage(errorMessage);
          }
        },
        header: false,
      });
      setUploadCSVModalOpen(false);
      setUploadCsvErrorMsg("");
    }
  };

  const healthieEventTypeOptions = [
    { value: HealthieEventType.appointmentDeleted, label: "Appointment Deleted" },
    { value: HealthieEventType.appointmentRescheduled, label: "Appointment Rescheduled" },
    { value: HealthieEventType.appointmentCancelled, label: "Appointment Canceled" },
    { value: HealthieEventType.appointmentLateCancellation, label: "Appointment Late Cancellation" },
  ];

  const handleHealthieEventTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    if (checked) {
      setHealthieEventTypes([...healthieEventTypes, name as HealthieEventType]);
    } else {
      setHealthieEventTypes(healthieEventTypes.filter((type) => type !== name));
    }
  };

  useEffect(() => {
    setQuestionAnswerPairs(questionsAndAnswers);
  }, [questionsAndAnswers]);

  const handleCloseModal = async () => {
    const agentProps: Partial<Agent> = {
      agentId: agent.agentId,
      name: agentName,
      enableTexting,
      enableCalling,
      //enableAutomaticTextResponses,
      enableRedialing,
      maxNumberOfRedials,
      redialGapMinutes,
      autoStartWaitlistRuns,
      minutesToWaitBeforeCalling,
      minutesToCutoffBeforeAppointment,
      daysForwardToCheckAvailableSlots,
      enableLeavingVoicemail,
      isActive,
      ehrProviderId,
      ehrApptTypeId,
      healthieEventTypes,
      intro: intro,
      firstText,
    };
    if (!user?.token) {
      showMessage("Session is expired. Please refresh.", "error");
      return;
    }
    dispatch(updateAgentThunk({ agent: agentProps }));
    onClose();
  };

  const providerOptions = providers.map((provider) => ({ key: provider.id, value: `${provider.firstName} ${provider.lastName}` }));
  const appointmentOptions = appointments.map((appointment) => ({ key: appointment.id, value: appointment.name }));

  // useEffect(() => {
  //   if (!agent?.agentId) {
  //     return;
  //   }
  //   if (!agent?.namespaceId) {
  //     return;
  //   }
  //   const getQuestionsAndAnswersLocal = async () => {
  //     await dispatch(getQuestionsAndAnswers()); // expensive op $$$ -> avoid when possible!
  //   };
  //   getQuestionsAndAnswersLocal();
  // }, [agent?.agentId]);

  const handleEhrChange = (event: SelectChangeEvent<string>) => {
    const selectedEhr = event.target.value as EHR;
    setEhrProviderId("");
    setEhrApptTypeId("");
    if (!user?.token || !agent?.agentId) {
      return;
    }
    dispatch(updateAgentThunk({ agent: { agentId: agent.agentId, ehr: selectedEhr } }));
  };

  const handleAddQuestionAnswer = () => {
    if (newQuestion && newAnswer) {
      let mostSimilarQuestion = "";
      let highestSimilarityRating = 0;

      // Check if the new question is too similar to any existing question
      const isDuplicate = questionAnswerPairs.some((pair) => {
        const similarityRating = stringSimilarity(newQuestion, pair.question);
        console.log(`Similarity rating for question "${newQuestion}" compared to "${pair.question}": ${similarityRating}`);

        if (similarityRating > highestSimilarityRating) {
          highestSimilarityRating = similarityRating;
          mostSimilarQuestion = pair.question;
        }

        return similarityRating > 0.8;
      });

      if (isDuplicate) {
        setErrorMessage(
          `Question "${newQuestion}" is too similar to existing question "${mostSimilarQuestion}", which is ${(highestSimilarityRating * 100).toFixed(
            0
          )}% similar.`
        );
        return;
      }

      // If not a duplicate, proceed to add the new question and answer
      const newPair: QuestionAnswerPair = {
        id: "",
        question: newQuestion,
        answer: newAnswer,
        source: newSource,
      };

      setQuestionAnswerPairs([...questionAnswerPairs, newPair]);
      setNewQuestion("");
      setNewAnswer("");
      setNewSource("");
      setShowSourceInput(false);
      dispatch(addQuestionsAndAnswers({ questionsAndAnswers: [newPair] }));
    }
  };

  const handleUpdateQuestionAnswer = (index: number) => {
    const pair = questionAnswerPairs[index];
    if (pair) {
      dispatch(updateAnswer({ pair: pair }));
    }
  };

  const handleDeleteQuestionAnswer = (index: number) => {
    const pair = questionAnswerPairs[index];
    if (pair) {
      dispatch(deleteQuestion({ vectorId: pair.id }));
      setQuestionAnswerPairs(questionAnswerPairs.filter((_, i) => i !== index));
    }
  };

  const isFormValid = () => {
    return newQuestion.trim() !== "" && newAnswer.trim() !== "";
  };

  return (
    // <Modal open={isModalOpen} onClose={handleCloseModal} sx={{ overflowY: "auto" }}>
    <Box
      sx={{
        overflowY: "auto",
        height: "92%",
        paddingBottom: "40px",
        paddingRight: "20px",
      }}
    >
      <HeaderBox title="Configure" onClose={onClose} />

      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            marginTop: "20px",
          }}
        >
          <Typography variant="h4" sx={{ fontSize: "1.8rem" }}>
            {agentName}
          </Typography>
          <Switch
            checked={isActive}
            onChange={() => setIsActive(!isActive)}
            sx={{
              "& .MuiSwitch-switchBase": {
                color: "#FFFFFF",
                "& + .MuiSwitch-track": {
                  backgroundColor: "#CCCCCC",
                },
              },
              "& .MuiSwitch-switchBase.Mui-checked": {
                color: "#FFFFFF",
                "& + .MuiSwitch-track": {
                  backgroundColor: "#00AC4F",
                },
              },
            }}
          />
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            Agent is <span style={{ fontWeight: "bold" }}>{isActive ? "on" : "off"}</span>
          </Box>
        </div>
        <Typography variant="h6" sx={{ fontSize: "1.1rem", color: Colors.primary, textTransform: "capitalize", marginTop: "-5px" }}>
          {agentType}
        </Typography>

        <Box>
          <Box
            mt={3}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box sx={{ marginBottom: "20px" }}>
              <Typography variant="h6" mb={1}>
                Agent Info
              </Typography>

              <Box
                style={{
                  background: Colors.bg2,
                  padding: "15px",
                  borderRadius: "20px",
                }}
              >
                <Typography display={"flex"} justifyContent={"space-between"} mb={1.5}>
                  Agent ID: <span>{agent.agentId}</span>
                </Typography>

                <SettingsTextInput label="Agent name" mb={1} value={agentName} onChange={(e) => setAgentName(e.target.value)} />

                <Tooltip title="The phone number that will be read of to the user.">
                  <SettingsTextInput
                    label="Callback number"
                    mb={1}
                    value={callbackNumber || ""}
                    onChange={(e) => setCallbackNumber(e.target.value)}
                  />
                </Tooltip>

                <Tooltip title="This is the number that is redirected to if the patient calls the texting number back.">
                  <SettingsTextInput
                    label="Inbound redirect number"
                    mb={1}
                    value={inboundRedirectNumber || ""}
                    onChange={(e) => setInboundRedirectNumber(e.target.value)}
                  />
                </Tooltip>

                <Tooltip title="This is the number that is redirected to in the middle of a call if the call is going wrong or at the patient's request.">
                  <SettingsTextInput
                    label="Emergency transfer number"
                    mb={1}
                    value={emergencyTransferNumber || ""}
                    onChange={(e) => setEmergencyRedirectNumber(e.target.value)}
                  />
                </Tooltip>

                <Typography variant="body1" style={{ margin: "10px 0 10px 0" }}>
                  Working hours
                </Typography>
                <Box display="flex" flexDirection="row" gap="20px">
                  <TimePicker
                    label="Start Time"
                    value={workingHoursStart}
                    onChange={setWorkingHoursStart}
                    disabled={settingNumbersLoading}
                    sx={InputStyles}
                  />
                  <TimePicker
                    label="End Time"
                    value={workingHoursEnd}
                    onChange={setWorkingHoursEnd}
                    disabled={settingNumbersLoading}
                    sx={InputStyles}
                  />
                </Box>

                {errorMessage && (
                  <Typography color="error" sx={{ marginTop: "5px", fontSize: "0.9rem" }}>
                    {errorMessage}
                  </Typography>
                )}
              </Box>
            </Box>

            <Box
              style={{
                background: Colors.bg2,
                padding: "15px",
                borderRadius: "20px",
              }}
            >
              <Typography variant="body1" fontWeight={"bold"} fontSize={"1.1rem"} mb={1.5}>
                Agent Settings
              </Typography>

              {agentType === AgentType.waitlist && (
                <React.Fragment>
                  <Box display="flex" alignItems="center" margin="0 0 5px -10px">
                    <Checkbox
                      checked={autoStartWaitlistRuns}
                      onClick={() => setAutoStartWaitlistRuns(!autoStartWaitlistRuns)}
                      sx={{ color: Colors.grey2 }}
                    />
                    <Typography style={{ fontSize: "0.9rem" }}>Automatically start runs</Typography>
                  </Box>
                  <Typography mb={1}>
                    Expire runs
                    <SettingsNumberInput disabled={false} value={minutesToCutoffBeforeAppointment} setter={setMinutesToCutoffBeforeAppointment} />
                    minutes before appointment
                  </Typography>
                  <Typography>
                    Check for availabilities
                    <SettingsNumberInput disabled={false} value={daysForwardToCheckAvailableSlots} setter={setDaysForwardToCheckAvailableSlots} />
                    days forward
                  </Typography>
                </React.Fragment>
              )}
            </Box>

            <Typography variant="h6" mt={2}>
              Communication settings
            </Typography>

            <Box display="flex" alignItems="center" margin="0 0 5px -10px">
              <Checkbox checked={enableCalling} onClick={() => setEnableCalling(!enableCalling)} sx={{ color: Colors.grey2 }} />
              <Typography style={{ fontSize: "0.9rem" }}>Phone call</Typography>
            </Box>

            <Box
              style={{
                background: Colors.bg2,
                padding: "15px",
                borderRadius: "20px",
              }}
            >
              <Typography variant="body1" fontWeight={"bold"} fontSize={"1.1rem"} mb={1.5}>
                Phone call settings{" "}
              </Typography>

              <Typography mb={1.5} variant="body1">
                Phone number: <strong>{agent.phoneNumber}</strong>
                <StyledLink
                  href="https://calendly.com/penciled/shawn"
                  target="_blank"
                  style={{
                    fontSize: "0.8rem",
                    marginLeft: "10px",
                    color: Colors.link,
                    cursor: "pointer",
                  }}
                >
                  Request new phone number
                </StyledLink>
              </Typography>

              <Typography mb={1.5} variant="body1">
                Spam risk prevention: <strong style={{ color: "#00AC4F", marginLeft: "15px" }}>Complete</strong>
              </Typography>

              <Typography
                variant="body1"
                fontSize={"1rem"}
                mb={1}
                ml={0}
                sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                Consent message:
                <HelpTooltip title={"This clause is required by law."} />
                <TextField
                  multiline={true}
                  value={consentRequest || ""}
                  variant="outlined"
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={{
                    width: "100%",
                    marginLeft: "8px",
                    "& .MuiInputBase-input": {
                      color: "#FFF",
                    },
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",
                      background: "rgba(255, 255, 255, 0.04)",
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "6px 15px",
                    },
                  }}
                />
              </Typography>

              <Typography
                variant="body1"
                fontSize={"1rem"}
                mb={1}
                ml={0}
                sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                Intro:
                <HelpTooltip
                  title={
                    <div>
                      This is among the first sentences the agent will say to the patient.
                      <br />
                      <br />
                      You can use the following variables in the first sentence:
                      <br />
                      - &#123;&#123; agentName &#125;&#125; : The agent's name
                      <br />
                      - &#123;&#123; organizationName &#125;&#125;: The organization's name
                      <br />
                      - &#123;&#123; patientFirstName &#125;&#125;: The patient's first name
                      <br />
                      - &#123;&#123; patientLastName &#125;&#125;: The patient's last name
                      <br />
                      - &#123;&#123; appointmentDate &#125;&#125;: The appointment date and time
                      <br />
                      - &#123;&#123; pcpName &#125;&#125;: The primary care provider's name
                      <br />
                      - &#123;&#123; appointmentTypeName &#125;&#125;: The appointment type
                      <br />
                      - &#123;&#123; contactType &#125;&#125;: The contact type (e.g. In Person)
                      <br />
                      <br />
                      Make sure to include the brackets.
                      <br />
                      An example first sentence could be: <br />
                      Hello &#123;&#123; patientFirstName &#125;&#125;, this is &#123;&#123; agentName &#125;&#125; from &#123;&#123; organizationName
                      &#125;&#125;. I'm calling because an appointment with &#123;&#123; pcpName &#125;&#125; opened up on &#123;&#123;
                      appointmentDate &#125;&#125;. Would you like to book it?
                    </div>
                  }
                />
                <TextField
                  multiline={true}
                  value={intro || ""}
                  onChange={(e) => setIntro(e.target.value)}
                  variant="outlined"
                  sx={{
                    width: "100%",
                    marginLeft: "8px",
                    "& .MuiInputBase-input": {
                      color: "#FFF",
                    },
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",
                      background: Colors.textfield,
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "6px 15px",
                    },
                  }}
                />
              </Typography>

              <Box mb={1.5} display="flex" alignItems="center" marginLeft={"-10px"}>
                <Checkbox
                  checked={enableLeavingVoicemail}
                  onChange={() => setEnableLeavingVoicemail(!enableLeavingVoicemail)}
                  sx={{ color: Colors.grey2 }}
                />
                <Typography style={{ fontSize: "1rem" }}>Leave voicemail?</Typography>
              </Box>

              {enableCalling && enableTexting && (
                <Typography>
                  Start calling
                  <SettingsNumberInput
                    disabled={!enableTexting || !enableCalling}
                    value={minutesToWaitBeforeCalling}
                    setter={setMinutesToWaitBeforeCalling}
                  />
                  minutes after sending texts
                </Typography>
              )}
            </Box>

            {agent.agentType === AgentType.waitlist && (
              <Box>
                <Box display="flex" alignItems="center" margin="15px 0 5px -10px">
                  <Checkbox checked={enableTexting} onClick={() => setEnableTexting(!enableTexting)} sx={{ color: Colors.grey2 }} />
                  <Typography fontSize={"0.9rem"}>Text message</Typography>
                </Box>
              </Box>
            )}

            {/* Question Bank Section */}
            <Box display="flex" flexDirection="column" mt={3} width="100%">
              <Box display={"flex"} alignItems={"baseline"} gap="10px">
                <Typography variant="h6" marginTop={"10px"}>
                  Question Bank
                </Typography>
                {loadingQuestionsAndAnswers && <CircularProgress color="primary" size={20} thickness={5} />}
              </Box>
              <Typography variant="body2" fontSize={"0.9rem"} marginTop={"5px"} color={Colors.info}>
                You can create some custom replies for the AI agents to utilize when conversing with patients. You can always manage this question
                bank in your settings.
              </Typography>

              <Box display="flex" flexDirection="column" mt={2} gap={2} sx={{ marginBottom: "10px" }}>
                {questionAnswerPairs.map((pair, index) => {
                  const isEditing = editingIndex === index;

                  return (
                    <Box
                      key={index}
                      sx={{
                        background: Colors.bg2,
                        padding: "15px",
                        borderRadius: "15px",
                        boxShadow: "0 1px 2px rgba(0, 0, 0, 0.2)",
                        marginBottom: "10px",
                      }}
                    >
                      {isEditing ? (
                        <>
                          <Box display={"flex"} justifyContent={"space-between"} alignItems={"baseline"}>
                            <Typography variant={"body1"} fontWeight="bold" color="#FFFFFF" mb={1}>
                              Question:
                            </Typography>
                            <StyledTextField
                              value={pair.question}
                              onChange={(e) =>
                                setQuestionAnswerPairs(questionAnswerPairs.map((p, i) => (i === index ? { ...p, question: e.target.value } : p)))
                              }
                              style={{ maxWidth: 300 }}
                            />
                          </Box>

                          <Box display={"flex"} justifyContent={"space-between"} alignItems={"baseline"}>
                            <Typography variant={"body1"} fontWeight="bold" color="#FFFFFF" mb={1} mt={2}>
                              Answer:
                            </Typography>
                            <StyledTextField
                              value={pair.answer}
                              onChange={(e) =>
                                setQuestionAnswerPairs(questionAnswerPairs.map((p, i) => (i === index ? { ...p, answer: e.target.value } : p)))
                              }
                              style={{ maxWidth: 300 }}
                            />
                          </Box>

                          <Box display={"flex"} justifyContent={"space-between"} alignItems={"baseline"}>
                            <Typography variant={"body1"} fontWeight="bold" color={Colors.info} mt={2} mb={1}>
                              Resource Link (optional):
                            </Typography>
                            <StyledTextField
                              placeholder="eg. https://www.example.com"
                              value={pair.source}
                              onChange={(e) =>
                                setQuestionAnswerPairs(questionAnswerPairs.map((p, i) => (i === index ? { ...p, source: e.target.value } : p)))
                              }
                              style={{ maxWidth: 300 }}
                            />
                          </Box>
                        </>
                      ) : (
                        <>
                          <Typography variant={"body2"} fontWeight="bold" color="#FFFFFF" mb={1}>
                            {pair.question}
                          </Typography>
                          <Typography variant={"body2"} color="#FFFFFF" mb={1}>
                            {pair.answer}
                          </Typography>
                          {pair.source && (
                            <Typography variant={"body2"} color={Colors.info} mb={1}>
                              {pair.source}
                            </Typography>
                          )}
                        </>
                      )}

                      <Box display="flex" gap={1} marginLeft="auto" marginTop="10px">
                        <Typography
                          variant="body2"
                          onClick={() => {
                            if (isEditing) handleUpdateQuestionAnswer(index);
                            setEditingIndex(isEditing ? null : index);
                          }}
                          sx={{ fontWeight: "bold", color: Colors.primary, cursor: "pointer" }}
                        >
                          {loadingQuestionsAndAnswersMsg === "Updating question..." && isEditing ? (
                            <CircularProgress size={20} />
                          ) : isEditing ? (
                            "Update"
                          ) : (
                            "Edit"
                          )}
                        </Typography>
                        <Typography
                          color="secondary"
                          onClick={() => handleDeleteQuestionAnswer(index)}
                          sx={{ padding: "0 5px", fontSize: "0.85rem", fontWeight: "bold", color: Colors.error, cursor: "pointer" }}
                        >
                          {loadingQuestionsAndAnswersMsg === "Deleting question..." ? <CircularProgress size={20} /> : "Delete"}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
              </Box>

              {/* New Question Form */}
              <Box
                sx={{
                  background: Colors.bg2,
                  padding: "15px",
                  borderRadius: "15px",
                  boxShadow: "0 1px 2px rgba(0, 0, 0, 0.2)",
                }}
              >
                <Typography fontWeight="bold" color="#FFFFFF" mb={1}>
                  Question:
                </Typography>
                <StyledTextField
                  placeholder="eg. How can I locate your office?"
                  value={newQuestion}
                  onChange={(e) => setNewQuestion(e.target.value)}
                />

                <Typography fontWeight="bold" color="#FFFFFF" mt={2} mb={1}>
                  Answer:
                </Typography>
                <StyledTextField placeholder="Enter your answer" value={newAnswer} onChange={(e) => setNewAnswer(e.target.value)} />

                <Typography fontWeight="bold" color={Colors.info} mt={2} mb={1}>
                  Resource Link (optional):
                </Typography>
                <StyledTextField placeholder="eg. https://www.example.com" value={newSource} onChange={(e) => setNewSource(e.target.value)} />

                <PrimaryButton
                  onClick={handleAddQuestionAnswer}
                  sx={{ padding: "5px 10px", fontSize: "0.85rem", marginTop: "15px", marginRight: "auto" }}
                >
                  {loadingQuestionsAndAnswersMsg === "Adding questions and answers..." ? <CircularProgress size={20} color="secondary" /> : "Add"}
                </PrimaryButton>
              </Box>
            </Box>
          </Box>

          <Box sx={{ textAlign: "left", display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <Typography variant="h6" textAlign={"center"} mt={2}>
              OR
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "flex-end", paddingTop: "5px" }}>
              <UploadCSVModal onFileUpload={handleFileChange} />
            </Box>
          </Box>

          {uploadCsvErrorMsg && (
            <Typography color="error" sx={{ marginTop: "5px", fontSize: "0.9rem" }}>
              {uploadCsvErrorMsg}
            </Typography>
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginTop: { xs: "15px", sm: "5px" },
              fontSize: { xs: "0.9rem", sm: "1rem" },
            }}
          >
            <Typography variant="h6" mb={1} mt={3}>
              Integrations
            </Typography>
            <Typography sx={{ fontSize: { xs: "0.9rem", sm: "1rem" } }}>
              EHR: <strong style={{ marginLeft: "10px" }}>Healthie</strong>
              {organization?.integrations?.includes(EHR.healthie) ? (
                <strong style={{ color: "#00AC4F", marginLeft: "20px" }}>Connected</strong>
              ) : (
                <strong style={{ color: "#DA001A", marginLeft: "20px" }}>Not set up</strong>
              )}
            </Typography>

            <Box
              mt={2}
              style={{
                background: Colors.bg2,
                padding: "15px",
                borderRadius: "20px",
              }}
            >
              <Typography variant="body1" fontWeight={"bold"} fontSize={"1.1rem"} mb={2}>
                Integration settings
              </Typography>

              <Box display="flex" alignItems="center" justifyContent={"space-between"} marginBottom={1}>
                <Typography fontSize={"0.9rem"} mr={1}>
                  EHR:
                </Typography>
                <FormControl
                  variant="outlined"
                  size="small"
                  sx={{
                    width: "100%",
                    background: Colors.textfield,
                    borderRadius: "10px",
                    "& .MuiInputBase-input": {
                      color: "#FFF",
                    },
                  }}
                >
                  <InputLabel style={{ color: Colors.grey1 }}>Select an option</InputLabel>
                  <Select value={agent?.ehr || EHR.none} onChange={handleEhrChange} label="Select an option">
                    {Object.values(EHR).map((ehr) => (
                      <MenuItem key={ehr} value={ehr}>
                        {convertSnakeCaseToOfficial(ehr)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              {agent.ehr === EHR.healthie && agent.agentType === AgentType.waitlist && (
                <Box mt={2}>
                  <Typography variant="body1" fontWeight={"bold"}>
                    Webhook Listeners
                  </Typography>
                  {healthieEventTypeOptions.map((option) => (
                    <Box display="flex" alignItems="center" marginBottom={1} key={option.value}>
                      <Checkbox
                        name={option.value}
                        checked={healthieEventTypes.indexOf(option.value) > -1}
                        onChange={handleHealthieEventTypeChange}
                        style={{ color: Colors.grey2 }}
                      />
                      <Typography style={{ fontSize: "1rem" }}>{option.label}</Typography>
                    </Box>
                  ))}
                </Box>
              )}

              {agent.ehr === EHR.healthie &&
                agent.agentType !== AgentType.waitlist &&
                (loadingProviders || loadingAppointments ? (
                  <LoadingWithMessage
                    message="Loading Healthie providers and appointment types..."
                    size={10}
                    customStyles={{ fontSize: "0.9rem", width: "95%" }}
                  />
                ) : (
                  <>
                    {providers.length !== 0 && (
                      <Box display="flex" alignItems="center" justifyContent={"space-between"} marginBottom={1} gap={2}>
                        <Typography fontSize="0.9rem" mb={0} sx={{ display: "flex", alignItems: "center" }}>
                          Provider name:
                        </Typography>
                        <FormControl variant="outlined" size="small" sx={{ minWidth: 200 }}>
                          <Dropdown label="Select an option" options={providerOptions} selectedValue={ehrProviderId} onChange={setEhrProviderId} />
                        </FormControl>
                      </Box>
                    )}

                    {appointments.length !== 0 && (
                      <Box display="flex" alignItems="center" justifyContent={"space-between"} marginBottom={1}>
                        <Typography fontSize="0.9rem" mb={0} sx={{ display: "flex", alignItems: "center" }}>
                          Appointment type:
                        </Typography>
                        <FormControl variant="outlined" size="small" sx={{ minWidth: 200 }}>
                          <Dropdown label="Select an option" options={appointmentOptions} selectedValue={ehrApptTypeId} onChange={setEhrApptTypeId} />
                        </FormControl>
                      </Box>
                    )}
                  </>
                ))}
            </Box>
          </Box>
        </Box>

        {/* {(uploadingLoading || settingNumbersLoading) && (
          <LoadingWithMessage message="Updating phone numbers..." size={10} customStyles={{ fontSize: "1.2rem" }} />
        )} */}

        <FooterBox onClick={handleSave} loading={uploadingLoading || settingNumbersLoading} buttonText="Save" />
      </>
    </Box>
    // </Modal>
  );
};

export default ConfigureForm;
