import React from "react";
import { Box } from "@mui/material";
import OnboardingSteps from "../forms/OnboardingSteps";

const GetStarted: React.FC = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "10vh" }}>
          <OnboardingSteps />
    </Box>
  );
}

export default GetStarted;