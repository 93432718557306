import React, { useEffect, useState } from "react";
import { Box, Typography, TextField, IconButton, InputAdornment, Tooltip, Switch } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import DeleteIcon from "@mui/icons-material/Delete";
import { Webhook, FrontendUser } from "../types";
import { RootState, useAppDispatch } from "../store";
import { useDispatch, useSelector } from "react-redux";
import { PrimaryButton } from "../subcomponents/CustomButton";
import {
  clearWebhooks,
  refreshWebhook,
  addWebhook,
  fetchWebhooks,
  deleteWebhook,
  toggleWebhookActivity,
  toggleWebhookRecording,
} from "../slices/WebhookSlice";
import LoadingWithMessage from "../subcomponents/LoadingWithMessage";
import AddWebhookModal from "../forms/AddWebhookModal";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useSnackbar } from "../providers/SnackbarProvider";
import { TextFieldWithVerticalPadding } from "../styles";

const Webhooks: React.FC = () => {
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const loading: boolean = useSelector((state: RootState) => state.webhooks.loading);
  const loadingMsg: string | undefined = useSelector((state: RootState) => state.webhooks.loadingMsg);
  const errorMsg: string | undefined = useSelector((state: RootState) => state.webhooks.errorMsg);
  const dispatch = useAppDispatch();
  const [newWebhookName, setNewWebhookName] = useState("");
  const [newWebhookUrl, setNewWebhookUrl] = useState("");
  const [newRecordingEnabled, setNewRecordingEnabled] = useState(false);
  const { showMessage } = useSnackbar();

  const webhooks: Webhook[] | undefined = useSelector((state: RootState) => state.webhooks.webhooks);

  useEffect(() => {
    if (user && user.token) {
      dispatch(fetchWebhooks({ token: user.token }));
    }
  }, [dispatch, user]);

  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    // when component dismounts, clear the webhooks
    return () => {
      dispatch(clearWebhooks());
    };
  }, [dispatch]);

  useEffect(() => {
    if (newWebhookName && newWebhookUrl) {
      dispatch(addWebhook({ url: newWebhookUrl, name: newWebhookName, recordingEnabled: newRecordingEnabled }));
    }
    setNewWebhookName("");
    setNewWebhookUrl("");
  }, [newWebhookName, newWebhookUrl]);

  const handleRefreshWebhook = async (name: string) => {
    if (!user || !user.token) {
      return;
    }

    if (!name) {
      return;
    }

    dispatch(refreshWebhook({ name, token: user?.token as string }));
  };

  const handleDeleteWebhook = async (name: string) => {
    if (!user || !user.token) {
      return;
    }

    if (!name) {
      return;
    }

    dispatch(deleteWebhook({ name, token: user?.token as string }));
  };

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
        <Typography variant="h6">Webhooks</Typography>
        <PrimaryButton
          onClick={() => setModalOpen(true)}
          sx={{
            marginLeft: "20px",
            padding: "5px 15px",
            fontSize: "0.9rem",
          }}
        >
          Add Webhook
        </PrimaryButton>
      </Box>

      <AddWebhookModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onSubmit={(webhookUrl: string, name: string, recordingEnabled: boolean) => {
          setNewWebhookUrl(webhookUrl);
          setNewWebhookName(name);
          setNewRecordingEnabled(recordingEnabled);
        }}
      />

      {loading && <LoadingWithMessage message={loadingMsg ?? "Loading..."} size={20} />}

      {webhooks &&
        webhooks.length > 0 &&
        webhooks.map((webhook: Webhook) => (
          <React.Fragment key={webhook.name}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "10px" }}>
              <Typography>{webhook.active ? "Active" : "Inactive"}</Typography>
              <Switch
                checked={webhook.active}
                onChange={() => dispatch(toggleWebhookActivity({ name: webhook.name }))}
                color="primary"
                inputProps={{ "aria-label": "toggle webhook activity" }}
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "10px" }}>
              <Typography>Recording {webhook.recordingEnabled ? "enabled" : "disabled"}</Typography>
              <Switch
                checked={webhook.recordingEnabled}
                onChange={() => dispatch(toggleWebhookRecording({ name: webhook.name }))}
                color="primary"
                inputProps={{ "aria-label": "toggle recording activity" }}
              />
            </Box>
            <Box sx={{ margin: "20px 0" }}>
              <TextFieldWithVerticalPadding
                fullWidth
                label={<Typography sx={{ color: "#FFF" }}>{webhook.name}</Typography>}
                value={webhook.url}
                InputProps={{
                  readOnly: true,
                  sx: {
                    color: "#FFF", // Make the input text color brighter
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Copy webhook URL">
                        <IconButton
                          aria-label="copy webhook URL"
                          onClick={() => {
                            navigator.clipboard.writeText(webhook.url);
                            showMessage("Webhook URL copied to clipboard");
                          }}
                        >
                          <ContentCopyIcon sx={{ color: "#FFF" }} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete webhook">
                        <IconButton aria-label="delete webhook" onClick={() => handleDeleteWebhook(webhook.name)}>
                          <DeleteIcon sx={{ color: "#FFF" }} />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
              <TextFieldWithVerticalPadding
                fullWidth
                label={<Typography sx={{ color: "#FFF" }}>Signing key</Typography>}
                value={webhook.signingKey ? webhook.signingKey : "***************************************"}
                InputProps={{
                  readOnly: true,
                  sx: {
                    color: "#FFF", // Make the input text color brighter
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      {webhook.signingKey && (
                        <Tooltip title="Copy signing key">
                          <IconButton
                            aria-label="copy signing key"
                            onClick={() => {
                              navigator.clipboard.writeText(webhook.signingKey!);
                              showMessage("Signing key copied to clipboard");
                            }}
                          >
                            <ContentCopyIcon sx={{ color: "#FFF" }} />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip title="Replace signing key">
                        <IconButton aria-label="replace signing key" onClick={() => handleRefreshWebhook(webhook.name)}>
                          <RefreshIcon sx={{ color: "#FFF" }} />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
              />
            </Box>
          </React.Fragment>
        ))}
      {errorMsg && <Typography color="error">{errorMsg}</Typography>}
    </Box>
  );
};

export default Webhooks;
