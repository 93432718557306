// src/slices/AuthSlice.tsx

import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { AuthUserModel, FrontendUser, NewUserData, TemporaryUserData, User, PartialFrontendUser, Organization, OnboardingStep, EHR } from "../types";
import { CognitoUser, AuthenticationDetails, ISignUpResult, CognitoUserAttribute } from "amazon-cognito-identity-js";
import { userPool } from "../AuthUtils/CognitoConfig"; // Import userPool from your Cognito configuration
import axios from "axios";
import { sessionExpired } from "./SessionSlice";

const clientId = process.env.REACT_APP_CLIENT_ID;

if (!clientId) {
  throw new Error("Cognito Client ID must be defined");
}

export interface AuthState {
  user: FrontendUser | null;
  organization: Organization | null;
  authUserModel: AuthUserModel | null;
  token: string | null;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  message: string;
  checkingAuth: boolean;
  signUpSuccess?: boolean;
  completedWalkthrough: boolean;
  gettingSessionLoading: boolean;
  updatingOrganizationLoading: boolean;
  organizationUpdateError?: string;
  onboardingStep: OnboardingStep;
  orgInfoCompleted: boolean;
  agentSetupCompleted: boolean;
  patientsAdded: boolean;
  agentSetupPartial: boolean;
  isOnboardingComplete: boolean;
  settingPhoneNumbersLoading: boolean;
}

const initialUserModel: AuthUserModel = {
  agentType: null,
  schedulingType: null,
  enableTexting: false,
  enableCalling: false,
  ehr: null,
  patientFinding: null,
  patientInfoRetrieval: null,
  agentName: null,
  callbackNumber: null,
  orgName: null,
  orgAreaCode: null,
  cloudFaxProvider: null,
};

const initialState: AuthState = {
  user: null,
  organization: null,
  authUserModel: initialUserModel,
  token: null,
  isLoading: true,
  isError: false,
  isSuccess: false,
  message: "",
  checkingAuth: true,
  completedWalkthrough: false,
  gettingSessionLoading: false,
  updatingOrganizationLoading: false,
  onboardingStep: OnboardingStep.init,
  orgInfoCompleted: false,
  agentSetupCompleted: false,
  patientsAdded: false,
  agentSetupPartial: false,
  isOnboardingComplete: false,
  settingPhoneNumbersLoading: false,
};

export const updateOrganizationAttribute = createAsyncThunk<void, { attribute: string; value: any }, { rejectValue: Error }>(
  "auth/organizationAttribute",
  async ({ attribute, value }, { getState, rejectWithValue, dispatch }) => {
    const state = getState() as RootState;
    const user = state.auth.user;
    if (!user) {
      return rejectWithValue(new Error("User not authenticated"));
    }
    try {
      await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/api/organizations`,
        { [attribute]: value },
        {
          headers: { Authorization: `Bearer ${user.token}`, "Content-Type": "application/json" },
        }
      );

      dispatch(setOrganizationAttribute({ attribute, value }));
    } catch (error) {
      return rejectWithValue(new Error((error as Error).message || "Failed to update user attribute"));
    }
  }
);

export const setInboundRedirectNumber = createAsyncThunk<void, { inboundRedirectNumber: string }, { rejectValue: Error }>(
  "organization/setInboundRedirectNumber",
  async ({ inboundRedirectNumber }, { getState, rejectWithValue, dispatch }) => {
    const state = getState() as RootState;
    const user: FrontendUser | null = state.auth.user;

    if (!user) {
      return rejectWithValue(new Error("User not authenticated"));
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/telephony/set-inbound-redirect`,
        { inboundRedirectNumber },
        {
          headers: { Authorization: `Bearer ${user.token}`, "Content-Type": "application/json" },
        }
      );

      if (response.status === 200) {
        dispatch(setOrganizationAttribute({ attribute: "inboundRedirectNumber", value: inboundRedirectNumber }));
      }
    } catch (error) {
      return rejectWithValue(new Error((error as Error).message || "Failed to set inbound redirect number"));
    }
  }
);

export const updateUserAttribute = createAsyncThunk<void, { attribute: string; value: any }, { rejectValue: Error }>(
  "auth/updateUserAttribute",
  async ({ attribute, value }, { getState, rejectWithValue, dispatch }) => {
    const state = getState() as RootState;
    const user = state.auth.user;
    if (!user) {
      return rejectWithValue(new Error("User not authenticated"));
    }
    try {
      await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/users/update`,
        { [attribute]: value },
        {
          headers: { Authorization: `Bearer ${user.token}`, "Content-Type": "application/json" },
        }
      );
      // Dispatch the synchronous action to update the user model
      dispatch(setUserAttribute({ attribute, value }));
    } catch (error) {
      return rejectWithValue(new Error((error as Error).message || "Failed to update user attribute"));
    }
  }
);

export const fetchWalkthroughStatus = createAsyncThunk<boolean, void, { rejectValue: Error }>(
  "auth/fetchWalkthroughStatus",
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const user = state.auth.user;
    if (!user) {
      return rejectWithValue(new Error("User not authenticated"));
    }
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/session`, {
        headers: { Authorization: `Bearer ${user.token}` },
      });

      return response.data.completedWalkthrough;
    } catch (error) {
      return rejectWithValue(new Error((error as Error).message || "Failed to fetch walkthrough status"));
    }
  }
);

export const fetchOnboardingStatus = createAsyncThunk<boolean, void, { rejectValue: Error }>(
  "auth/fetchOnboardingStatus",
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const user = state.auth.user;
    if (!user) {
      return rejectWithValue(new Error("User not authenticated"));
    }
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/session`, {
        headers: { Authorization: `Bearer ${user.token}` },
      });

      return response.data.user.completedOnboarding;
    } catch (error) {
      return rejectWithValue(new Error((error as Error).message || "Failed to fetch onboarding status"));
    }
  }
);

export const getSession = createAsyncThunk<{ user: FrontendUser; organization: Organization }, void, { rejectValue: Error }>(
  "auth/getSession",
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      let user: FrontendUser | null = state.auth.user;

      if (!user) {
        throw new Error("No user found.");
      }

      const token = user.token;

      if (!token) {
        throw new Error("No token associated to user.");
      }

      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/session`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      user = { ...response.data.user, token: token };
      const organization: Organization = response.data.organization;

      if (!user) {
        throw new Error("Invalid user data");
      }

      dispatch(updateUserAndOrganization({ user, organization }));

      // dispatch(checkHealthieIntegration());

      return { user, organization };
    } catch (error) {
      return rejectWithValue(new Error((error as Error).message || "Failed to fetch user session"));
    }
  }
);

export const completeWalkthrough = createAsyncThunk<void, void, { rejectValue: Error }>(
  "auth/completeWalkthrough",
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const user = state.auth.user;
    if (!user) {
      return rejectWithValue(new Error("User not authenticated"));
    }
    try {
      await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/users/update`,
        { completedWalkthrough: true },
        {
          headers: { Authorization: `Bearer ${user.token}`, "Content-Type": "application/json" },
        }
      );
    } catch (error) {
      return rejectWithValue(new Error((error as Error).message || "Failed to update walkthrough status"));
    }
  }
);

export const completeOnboarding = createAsyncThunk<void, void, { rejectValue: Error }>(
  "auth/completeOnboarding",
  async (_, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const user = state.auth.user;
    if (!user) {
      return rejectWithValue(new Error("User not authenticated"));
    }
    try {
      await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/users/update`,
        { completedOnboarding: true },
        {
          headers: { Authorization: `Bearer ${user.token}`, "Content-Type": "application/json" },
        }
      );

      dispatch(setOnboardingComplete(true));
      dispatch(updateOnboardingStep(OnboardingStep.end));
    } catch (error) {
      return rejectWithValue(new Error((error as Error).message || "Failed to update onboarding status"));
    }
  }
);

export const signIn = createAsyncThunk<{ user: FrontendUser; organization: Organization }, TemporaryUserData, { rejectValue: Error }>(
  "auth/signIn",
  async (userData: TemporaryUserData, { rejectWithValue }) => {
    try {
      // Define authentication details
      const authenticationDetails = new AuthenticationDetails({
        Username: userData.email,
        Password: userData.password,
      });

      // Define Cognito user
      const cognitoUser = new CognitoUser({
        Username: userData.email,
        Pool: userPool,
      });

      // Return a new promise for the authentication process
      return await new Promise<{ user: FrontendUser; organization: Organization }>((resolve, reject) => {
        // Authenticate the user
        cognitoUser.authenticateUser(authenticationDetails, {
          onSuccess: async (session) => {
            try {
              const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/session`, {
                headers: {
                  Authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
                },
              });

              if (response.status !== 200) {
                // Check if the response status is not OK
                reject(new Error("Failed to fetch user data"));
              }

              const userData: User = response.data.user;

              if (!userData || !userData.userId) {
                console.error("Invalid userData: ", userData);
                reject(new Error("Invalid user data"));
              }

              const organization: Organization = response.data.organization;

              if (!organization || !organization.organizationId) {
                console.error("Invalid organization data: ", organization);
                reject(new Error("Invalid organization data"));
              }

              // Construct the user object
              const user: FrontendUser = {
                ...userData,
                token: session.getIdToken().getJwtToken(),
              };

              resolve({ user: user, organization: organization });
            } catch (error) {
              console.error("Error fetching user data:", error);
              reject(error);
            }
          },
          onFailure: (error) => {
            console.error("Sign-in error:", error);
            reject(error);
          },
        });
      });
    } catch (error) {
      console.error("Error during sign-in:", error);
      if ((error as Error).message) {
        return rejectWithValue(new Error((error as Error).message));
      }
      return rejectWithValue(new Error("An unknown error occurred"));
    }
  }
);

export const signOut = createAsyncThunk<void, void, { rejectValue: Error }>("auth/signOut", async (_, { rejectWithValue }) => {
  try {
    const user = userPool.getCurrentUser();
    if (user) {
      user.signOut();
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
    }
  } catch (error) {
    if ((error as Error).message) {
      return rejectWithValue(new Error((error as Error).message));
    }
    return rejectWithValue(new Error("An unknown error occurred"));
  }
});

export const deleteCognitoAccount = async (cognitoUser: CognitoUser) => {
  return new Promise<void>((resolve, reject) => {
    cognitoUser.deleteUser((err) => {
      if (err) {
        reject(err);
      } else {
        resolve();
      }
    });
  });
};

export const deleteUserAccount = createAsyncThunk<void, void, { state: RootState; rejectValue: Error }>(
  "auth/deleteUserAccount",
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const user = state.auth.user;

    if (!user) {
      return rejectWithValue(new Error("User not authenticated"));
    }

    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/users/delete-account`, {
        headers: { Authorization: `Bearer ${user.token}` },
      });

      return;
    } catch (error) {
      return rejectWithValue(new Error((error as Error).message || "Failed to delete account"));
    }
  }
);

export const signUp = createAsyncThunk<PartialFrontendUser, NewUserData, { rejectValue: Error }>(
  "auth/signUp",
  async (userData: NewUserData, { rejectWithValue }) => {
    try {
      const attributeList: CognitoUserAttribute[] = [new CognitoUserAttribute({ Name: "email", Value: userData.email })];

      const signUpResult: ISignUpResult = await new Promise((resolve, reject) => {
        userPool.signUp(userData.email, userData.password, attributeList, [], (err, result) => {
          if (err) {
            console.error("Sign up error:", err);
            reject(err);
          } else {
            resolve(result as ISignUpResult);
          }
        });
      });

      return {
        email: userData.email,
        firstName: userData.firstName,
        lastName: userData.lastName,
        userSub: signUpResult.userSub,
        password: userData.password,
      };
    } catch (error) {
      console.error("Error during sign-up:", error);
      return rejectWithValue(new Error((error as Error).message));
    }
  }
);

export const verifyEmail = createAsyncThunk<
  { user: FrontendUser; organization: Organization },
  { email: string; code: string; userData: PartialFrontendUser },
  { rejectValue: Error }
>("auth/verifyEmail", async ({ email, code, userData }, { rejectWithValue, dispatch }) => {
  try {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    await new Promise((resolve, reject) => {
      cognitoUser.confirmRegistration(code, true, (err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    });

    const authenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: userData.password,
    });

    const user = await new Promise<{ user: FrontendUser; organization: Organization }>((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: async (session) => {
          try {
            const putUserResponse = await axios.post(
              `${process.env.REACT_APP_BACKEND_URL}/api/users`,
              {
                userId: userData.userSub,
                email: userData.email,
                firstName: userData.firstName,
                lastName: userData.lastName,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
                },
              }
            );

            if (putUserResponse.status !== 200) {
              throw new Error("Failed to store user data");
            }

            const getUserResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/session`, {
              headers: {
                Authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
              },
            });

            if (getUserResponse.status !== 200) {
              throw new Error("Failed to retrieve user session data");
            }

            const user: FrontendUser = getUserResponse.data.user;

            const frontendUser: FrontendUser = {
              ...user,
              token: session.getIdToken().getJwtToken(),
            };

            const organization: Organization = getUserResponse.data.organization;

            if (!organization || !organization.organizationId) {
              throw new Error("Invalid organization data");
            }

            dispatch(setUser(frontendUser));
            dispatch(setOrganization(organization));

            resolve({ user: frontendUser, organization: organization });
          } catch (err) {
            reject(err);
          }
        },
        onFailure: (err) => {
          reject(err);
        },
      });
    });

    return user;
  } catch (error) {
    console.error("Error during email verification or user data storage:", error);
    return rejectWithValue(new Error((error as Error).message));
  }
});

export const resendVerificationCode = createAsyncThunk<void, { email: string }, { rejectValue: Error }>(
  "auth/resendVerificationCode",
  async ({ email }, { rejectWithValue }) => {
    try {
      const cognitoUser = new CognitoUser({
        Username: email,
        Pool: userPool,
      });

      return await new Promise<void>((resolve, reject) => {
        cognitoUser.resendConfirmationCode((err) => {
          if (err) {
            reject(err);
          } else {
            resolve();
          }
        });
      });
    } catch (error) {
      return rejectWithValue(new Error((error as Error).message || "Failed to resend verification code"));
    }
  }
);

export const recoverPassword = createAsyncThunk<void, string, { rejectValue: Error }>(
  "auth/recoverPassword",
  async (email: string, { rejectWithValue }) => {
    try {
      return new Promise<void>((resolve, reject) => {
        const cognitoUser = new CognitoUser({ Username: email, Pool: userPool });
        cognitoUser.forgotPassword({
          onSuccess: () => resolve(),
          onFailure: (err) => reject(err),
        });
      });
    } catch (error) {
      return rejectWithValue(new Error((error as Error).message));
    }
  }
);

export const confirmUser = createAsyncThunk<
  string, // Success result type
  { email: string; code: string }, // Parameters type
  { rejectValue: Error } // RejectValue type
>("auth/confirmUser", async ({ email, code }, { rejectWithValue }) => {
  try {
    const userData = { Username: email, Pool: userPool };
    const cognitoUser = new CognitoUser(userData);

    return await new Promise<string>((resolve, reject) => {
      cognitoUser.confirmRegistration(code, true, (err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve(result); // Typically 'SUCCESS'
        }
      });
    });
  } catch (error) {
    return rejectWithValue(new Error((error as Error).message || "An error occurred during confirmation."));
  }
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    },
    setUser: (state, action: PayloadAction<FrontendUser>) => {
      state.user = action.payload;
      state.isError = false;
      state.isSuccess = true;
      state.isLoading = false;
    },
    setOrganization: (state, action: PayloadAction<Organization>) => {
      state.organization = action.payload;
      state.isError = false;
      state.isSuccess = true;
      state.isLoading = false;
    },
    clearUser: (state) => {
      state.user = null;
      state.organization = null;
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
      if (state.user) {
        state.user.token = action.payload;
      }
    },
    setCheckingAuth: (state, action: PayloadAction<boolean>) => {
      state.checkingAuth = action.payload;
    },
    setUserAttribute(state, action: PayloadAction<{ attribute: string; value: any }>) {
      if (state.user) {
        state.user = { ...state.user, [action.payload.attribute]: action.payload.value };
      }
    },
    setOrganizationAttribute(state, action: PayloadAction<{ attribute: string; value: any }>) {
      if (state.organization) {
        state.organization = { ...state.organization, [action.payload.attribute]: action.payload.value };
      }
    },
    updateWalkthroughUser: (state, action: PayloadAction<Partial<AuthUserModel>>) => {
      if (state.authUserModel) {
        state.authUserModel = { ...state.authUserModel, ...action.payload };
      }
    },
    updateUserAndOrganization: (state, action: PayloadAction<{ user: FrontendUser; organization: Organization }>) => {
      state.user = action.payload.user;
      state.organization = action.payload.organization;
    },
    updateOnboardingStep: (state, action: PayloadAction<OnboardingStep>) => {
      state.onboardingStep = action.payload;
    },
    setOrgInfoCompleted(state, action: PayloadAction<boolean>) {
      state.orgInfoCompleted = action.payload;
    },
    setAgentSetupCompleted(state, action: PayloadAction<boolean>) {
      state.agentSetupCompleted = action.payload;
    },
    setAgentSetupPartial(state, action: PayloadAction<boolean>) {
      state.agentSetupPartial = action.payload;
    },
    setPatientsAdded(state, action: PayloadAction<boolean>) {
      state.patientsAdded = action.payload;
    },
    setOnboardingComplete(state, action: PayloadAction<boolean>) {
      state.isOnboardingComplete = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(completeOnboarding.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(completeOnboarding.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(completeOnboarding.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(setInboundRedirectNumber.pending, (state) => {
        state.settingPhoneNumbersLoading = true;
      })
      .addCase(setInboundRedirectNumber.fulfilled, (state) => {
        state.settingPhoneNumbersLoading = false;
      })
      .addCase(setInboundRedirectNumber.rejected, (state) => {
        state.settingPhoneNumbersLoading = false;
      })
      .addCase(getSession.pending, (state) => {
        state.gettingSessionLoading = true;
      })
      .addCase(getSession.fulfilled, (state, action) => {
        state.gettingSessionLoading = false;
        state.user = action.payload.user;
        state.organization = action.payload.organization;
        state.checkingAuth = false;
      })
      .addCase(getSession.rejected, (state) => {
        state.gettingSessionLoading = false;
        state.checkingAuth = false;
      })
      .addCase(signIn.pending, (state) => {
        state.isLoading = true;
        state.message = "";
        state.isError = false;
      })
      .addCase(signIn.fulfilled, (state, action: PayloadAction<{ user: FrontendUser; organization: Organization }>) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload.user;
        state.organization = action.payload.organization;
        sessionExpired(false);
        localStorage.setItem("cognitoUserChange", Date.now().toString());
      })
      .addCase(signIn.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload?.message || action.error.message || "An unknown error occurred";
      })
      .addCase(signOut.fulfilled, (state) => {
        state.user = null;
        state.token = null;
        state.isError = false;
        state.isSuccess = false;
        state.isLoading = false;
        localStorage.setItem("cognitoUserChange", Date.now().toString());
      })
      .addCase(signUp.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = "";
      })
      .addCase(signUp.fulfilled, (state) => {
        state.isLoading = false;
        state.signUpSuccess = true;
        state.isError = false;
        //state.message = 'Sign up successful. Please verify your email and log in.';
        localStorage.setItem("cognitoUserChange", Date.now().toString());
      })
      .addCase(signUp.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.user = null;
        state.message = action.error.message || "Failed to sign up";
      })
      .addCase(recoverPassword.pending, (state) => {
        state.isLoading = true;
        state.message = "";
        state.isError = false;
      })
      .addCase(recoverPassword.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "Verification code sent to your email";
      })
      .addCase(recoverPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload?.message || action.error.message || "Failed to send password reset email";
      })
      // verify email
      .addCase(verifyEmail.pending, (state) => {
        state.isLoading = true;
        state.message = "";
        state.isError = false;
      })
      .addCase(verifyEmail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        localStorage.setItem("cognitoUserChange", Date.now().toString());
      })
      .addCase(verifyEmail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload?.message || action.error.message || "Failed to verify email";
      })
      .addCase(deleteUserAccount.fulfilled, (state) => {
        state.user = null;
        state.organization = null;
        state.token = null;
        state.isSuccess = true;
        state.isLoading = false;
      })
      .addCase(deleteUserAccount.rejected, (state, action) => {
        state.isError = true;
        state.message = action.payload?.message || "Failed to delete account";
        state.isLoading = false;
      })
      .addCase(updateOrganizationAttribute.pending, (state) => {
        state.updatingOrganizationLoading = true;
      })
      .addCase(updateOrganizationAttribute.fulfilled, (state) => {
        state.updatingOrganizationLoading = false;
      })
      .addCase(updateOrganizationAttribute.rejected, (state, action) => {
        state.updatingOrganizationLoading = false;
        state.organizationUpdateError = action.payload?.message || action.error.message || "Failed to update organization";
      });
  },
});

export const {
  reset,
  setUser,
  setOrganization,
  clearUser,
  setToken,
  setCheckingAuth,
  updateWalkthroughUser,
  setUserAttribute,
  updateUserAndOrganization,
  updateOnboardingStep,
  setAgentSetupCompleted,
  setOrgInfoCompleted,
  setPatientsAdded,
  setAgentSetupPartial,
  setOrganizationAttribute,
  setOnboardingComplete,
} = authSlice.actions;
export const selectAuth = (state: RootState) => state.auth;
export default authSlice.reducer;
