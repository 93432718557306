import React, { useEffect, useState } from "react";
import { RootState, useAppDispatch } from "../store";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Typography, Box, List, ListItem, ListItemIcon, ListItemText, Modal } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress } from "@mui/material";
import { Agent, AgentTemplate, AgentType, EHR, OnboardingStep, Organization } from "../types";
import AgentCardSelections from "../components/modals/AgentCardSelections";
import OrgInfo from "../components/modals/OrgInfo";
import { getAgentTemplates } from "../slices/AgentTemplates";
import {
  updateOnboardingStep,
  setAgentSetupCompleted,
  setOrgInfoCompleted,
  setPatientsAdded,
  setOnboardingComplete,
  completeOnboarding,
  fetchOnboardingStatus,
  getSession,
} from "../slices/AuthSlice";
import Loader from "../subcomponents/Loader";
import { Sidebar } from "../styles/GeneralStyles";
import { FrontendUser } from "../types";
import { PrimaryButton } from "../subcomponents/CustomButton";
import { BorderBottom } from "@mui/icons-material";
import RadioButtonUncheckedOutlinedIcon from "@mui/icons-material/RadioButtonUncheckedOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Colors } from "../Colors";
import AddPatient from "../components/modals/AddPatient";
import axios from "axios";

const OnboardingSteps: React.FC = () => {
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const agents: Agent[] = useSelector((state: RootState) => state.agents.agents);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const gettingSessionLoading: boolean = useSelector((state: RootState) => state.auth.gettingSessionLoading);
  const organization: Organization | null = useSelector((state: RootState) => state.auth.organization);
  const onboardingStep: OnboardingStep = useSelector((state: RootState) => state.auth.onboardingStep);
  const agentTemplates: AgentTemplate[] = useSelector((state: RootState) => state.agentTemplates.templates);

  const orgInfoCompleted = useSelector((state: RootState) => state.auth.orgInfoCompleted);
  const agentSetupCompleted = useSelector((state: RootState) => state.auth.agentSetupCompleted);
  const patientsAdded = useSelector((state: RootState) => state.auth.patientsAdded);
  const agentSetupPartial = useSelector((state: RootState) => state.auth.agentSetupPartial);

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [selectedAgentTemplate, setSelectedAgentTemplate] = useState<AgentTemplate | null>(null);
  const [agentTypeModalOpen, setAgentTypeModalOpen] = useState<boolean>(false);
  const [orgInfoModalOpen, setOrgInfoModalOpen] = useState<boolean>(false);
  const [addPatientModalOpen, setAddPatientModalOpen] = useState<boolean>(false);

  const [integrated, setIntegrated] = useState(false);

  const [showCongrats, setShowCongrats] = useState(false);

  const [patients, setPatients] = useState<any[]>([]);
  const [onboardingComplete, setOnboardingComplete] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkOnboardingStatus = async () => {
      try {
        await dispatch(getSession());

        const result = await dispatch(fetchOnboardingStatus());
        if (result.payload) {
          setOnboardingComplete(true);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching onboarding status:", error);
      }
    };

    checkOnboardingStatus();
  }, [dispatch]);

  useEffect(() => {
    if (onboardingComplete) {
      navigate(`/dashboard/agents/${agents[0]?.agentId}`);
    }
  }, [onboardingComplete, navigate, agents]);

  useEffect(() => {
    const getWaitlistPatients = async () => {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/patients/waitlist`, {
        headers: { Authorization: `Bearer ${user?.token}` },
      });
      setPatients(response.data);
    };

    getWaitlistPatients();
  }, [user]);

  useEffect(() => {
    if (organization) {
      setIntegrated(Object.keys(organization.integrations ?? {}).includes(EHR.healthie));
    }
  }, [organization]);

  useEffect(() => {
    // console.log("Current onboarding step: ", onboardingStep);
    if (onboardingStep === OnboardingStep.init && organization?.name && organization?.phoneNumber) {
      dispatch(updateOnboardingStep(OnboardingStep.orgInfo));
    }
  }, [organization, onboardingStep, dispatch]);

  useEffect(() => {
    if (agentTemplates.length === 0) {
      dispatch(getAgentTemplates());
    }
  }, [agentTemplates, dispatch]);

  const handleOrgInfoComplete = () => {
    // console.log("Organization info completed");
    dispatch(setOrgInfoCompleted(true));
    // dispatch(updateOnboardingStep(OnboardingStep.createAgent));
    setOrgInfoModalOpen(false);
  };

  const handleAgentCreationComplete = () => {
    // console.log("Agent creation completed");
    dispatch(setAgentSetupCompleted(true));
    // dispatch(updateOnboardingStep(OnboardingStep.makeCall));
    setAgentTypeModalOpen(false);
  };

  const handlePatientsAdded = () => {
    // console.log("Patients added");
    dispatch(setPatientsAdded(true));
    setAddPatientModalOpen(false);

    if (isOrgInfoComplete && isAgentSetupComplete && !showCongrats) {
      setShowCongrats(true);
    }
  };

  const handleOnboardingComplete = async () => {
    try {
      await dispatch(completeOnboarding());
      setOnboardingComplete(true);
    } catch (error) {
      console.error("Failed to update onboarding status:", error);
    }
  };

  const handleContinue = () => {
    handleOnboardingComplete();
    setShowCongrats(false);
    navigate(`/dashboard/agents/${agents[0].agentId}`);
  };

  const isOrgInfoComplete =
    orgInfoCompleted || (organization?.name && organization?.phoneNumber && organization?.workingHoursStart && organization?.workingHoursEnd);
  const isAgentSetupPartial =
    agentSetupPartial || (agents.length > 0 && agents[0].areaCode && agents[0].phoneNumber && agents[0].ehr === EHR.healthie && !integrated);
  const isAgentSetupComplete =
    agentSetupCompleted ||
    (agents.length > 0 &&
      agents[0].areaCode &&
      agents[0].phoneNumber &&
      ((agents[0].ehr === EHR.healthie && integrated) || agents[0].ehr === EHR.none) &&
      !isAgentSetupPartial);
  const isPatientsAdded = patientsAdded || patients.length > 0;

  const totalSteps = 4;
  const completedSteps = 1 + (isOrgInfoComplete ? 1 : 0) + (isAgentSetupComplete ? 1 : 0) + (isPatientsAdded ? 1 : 0);
  const progress = (completedSteps / totalSteps) * 100;

  const [firstWaitlistAgent, setFirstWaitlistAgent] = useState(agents.find((agent: Agent) => agent.agentType === AgentType.waitlist));

  useEffect(() => {
    if (onboardingStep === OnboardingStep.end) {
      navigate(`/dashboard/agents/${firstWaitlistAgent?.agentId}`);
    }
  }, [firstWaitlistAgent, onboardingStep]);

  useEffect(() => {
    if (agents) {
      setFirstWaitlistAgent(agents.find((agent: Agent) => agent.agentType === AgentType.waitlist));
    }
  }, [agents]);

  useEffect(() => {
    if (isOrgInfoComplete && isAgentSetupComplete && isPatientsAdded) {
      dispatch(updateOnboardingStep(OnboardingStep.end));
    }
  }, [isOrgInfoComplete, isAgentSetupComplete, isPatientsAdded, dispatch]);

  if (loading) {
    return <Loader message="" size={60} />;
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <Box>
        <Typography variant="h4" sx={{ color: "#FFF", textAlign: "center" }} gutterBottom>
          Welcome {user?.firstName} 👋
        </Typography>
        <Typography variant="body1" sx={{ color: "#FFF", textAlign: "center" }} gutterBottom>
          Before we create your first agent, we need you to complete a few things.
        </Typography>

        {gettingSessionLoading && <Loader message="Loading..." size={60} />}
        <List sx={{ color: "#FFF", background: "#151A32", borderRadius: "15px", marginTop: "30px", width: "100%" }}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{ padding: "5px 20px", paddingBottom: "5px", borderBottom: "1px solid #1C2138" }}
          >
            <Typography variant="h6" sx={{ color: "#FFF", fontSize: "1.1rem" }}>
              Onboarding checklist
            </Typography>

            <Box position="relative" display="inline-flex">
              <CircularProgress variant="determinate" value={progress} size={45} thickness={6} />
              <Box top={0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
                <Typography variant="caption" component="div" fontWeight={"bold"} color="white">{`${Math.round(progress)}%`}</Typography>
              </Box>
            </Box>
          </Box>
          <ListItem sx={{ margin: "5px 0 0 0", borderBottom: "1px solid #1C2138" }}>
            <ListItemIcon>
              <CheckCircleIcon style={{ color: "green" }} />
            </ListItemIcon>
            <ListItemText
              primary={<span style={{ textDecoration: "line-through", color: Colors.info, textDecorationThickness: "1px" }}>Create an account</span>}
              primaryTypographyProps={{ fontWeight: "bold", marginBottom: "5px" }}
            />
          </ListItem>

          <ListItem sx={{ margin: "0 0 5px 0", borderBottom: "1px solid #1C2138" }}>
            <ListItemIcon>
              {isOrgInfoComplete ? (
                <CheckCircleIcon style={{ color: "green" }} />
              ) : (
                <span style={{ color: Colors.primary, border: "1px dashed", borderRadius: "50%", width: "1.2rem", height: "1.2rem" }} />
              )}
            </ListItemIcon>
            <Box>
              <ListItemText
                primary={
                  <span
                    style={{
                      textDecoration: isOrgInfoComplete ? "line-through" : "none",
                      color: isOrgInfoComplete ? Colors.info : "#FFF",
                      textDecorationThickness: "1px",
                    }}
                  >
                    Business setup
                  </span>
                }
                secondary="Add your business name, callback number, and working hours."
                primaryTypographyProps={{ fontWeight: "bold", marginBottom: "8px" }}
                secondaryTypographyProps={{ fontSize: "0.9rem", color: Colors.info, marginBottom: "8px" }}
              />
              {!isOrgInfoComplete && (
                <PrimaryButton
                  onClick={() => setOrgInfoModalOpen(true)}
                  sx={{ fontSize: "0.9rem", whiteSpace: "nowrap", padding: "5px 15px", marginBottom: "10px" }}
                >
                  Setup Business
                </PrimaryButton>
              )}
            </Box>
          </ListItem>

          <ListItem sx={{ margin: "5px 0", borderBottom: "1px solid #1C2138" }}>
            <ListItemIcon>
              {isAgentSetupComplete ? (
                <CheckCircleIcon style={{ color: "green" }} />
              ) : isAgentSetupPartial ? (
                <span style={{ color: Colors.warning, border: "1px dashed", borderRadius: "50%", width: "1.2rem", height: "1.2rem" }} />
              ) : isOrgInfoComplete ? (
                <span style={{ color: Colors.primary, border: "1px dashed", borderRadius: "50%", width: "1.2rem", height: "1.2rem" }} />
              ) : (
                <span style={{ color: "#2F3658", border: "1px dashed", borderRadius: "50%", width: "1.2rem", height: "1.2rem" }} />
              )}
            </ListItemIcon>
            <Box>
              <ListItemText
                primary={
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <span
                      style={{
                        textDecoration: isAgentSetupComplete ? "line-through" : "none",
                        color: isAgentSetupComplete ? Colors.info : "#FFF",
                        textDecorationThickness: "1px",
                      }}
                    >
                      Customize your waitlist AI agent
                    </span>
                    {isAgentSetupPartial && !isAgentSetupComplete && (
                      <span
                        style={{
                          color: Colors.warning,
                          fontSize: "0.8rem",
                          marginLeft: "10px",
                          background: "#2C2A1A",
                          borderRadius: "15px",
                          padding: "2px 10px",
                        }}
                      >
                        Partial
                      </span>
                    )}
                  </span>
                }
                secondary="Customize your agent and integrate with EHR."
                primaryTypographyProps={{ fontWeight: "bold", marginBottom: "8px" }}
                secondaryTypographyProps={{ fontSize: "0.9rem", color: Colors.info, marginBottom: "8px" }}
              />
              {isOrgInfoComplete && !isAgentSetupComplete && agentTemplates.length > 0 && (
                <>
                  {isAgentSetupPartial && (
                    <Box
                      sx={{
                        background: Colors.bg3,
                        padding: "12px 15px",
                        borderRadius: "10px",
                        width: { xs: "100%", md: "60%" },
                        marginBottom: "15px",
                      }}
                    >
                      <Typography variant="body2" fontSize={"0.85rem"}>
                        An API key should appear on your Healthie dashboard within 24-48 hours. Need further assistance?{" "}
                        <a href="https://calendly.com/penciled/shawn" style={{ color: Colors.primary }}>
                          contact us
                        </a>
                      </Typography>
                    </Box>
                  )}

                  <PrimaryButton
                    onClick={() => setAgentTypeModalOpen(true)}
                    sx={{ fontSize: "0.9rem", whiteSpace: "nowrap", padding: "5px 15px", marginBottom: "10px" }}
                  >
                    {isAgentSetupPartial ? "Continue Agent setup" : "Customize Agent"}
                  </PrimaryButton>
                </>
              )}
            </Box>
          </ListItem>

          <ListItem sx={{ margin: "5px 0" }}>
            <ListItemIcon>
              {isPatientsAdded ? (
                <CheckCircleIcon style={{ color: "green" }} />
              ) : isAgentSetupComplete ? (
                <span style={{ color: Colors.primary, border: "1px dashed", borderRadius: "50%", width: "1.2rem", height: "1.2rem" }} />
              ) : (
                <span style={{ color: "#2F3658", border: "1px dashed", borderRadius: "50%", width: "1.2rem", height: "1.2rem" }} />
              )}
            </ListItemIcon>
            <Box>
              <ListItemText
                primary={
                  <span
                    style={{
                      textDecoration: isPatientsAdded ? "line-through" : "none",
                      color: isPatientsAdded ? Colors.info : "#FFF",
                      textDecorationThickness: "1px",
                    }}
                  >
                    Add patients to waitlist
                  </span>
                }
                secondary="Add manually or import waitlist patient details from your EHR. Start rescuing last minute cancellations!"
                primaryTypographyProps={{ fontWeight: "bold", marginBottom: "8px" }}
                secondaryTypographyProps={{ fontSize: "0.9rem", color: Colors.info, marginBottom: "8px" }}
              />

              {isAgentSetupComplete && !isPatientsAdded && (
                <PrimaryButton
                  onClick={() => setAddPatientModalOpen(true)}
                  sx={{ fontSize: "0.9rem", whiteSpace: "nowrap", padding: "5px 15px", marginBottom: "10px" }}
                >
                  Add Patients
                </PrimaryButton>
              )}
            </Box>
          </ListItem>
        </List>

        {/* ORG INFO */}
        <Modal open={orgInfoModalOpen} onClose={() => setOrgInfoModalOpen(false)}>
          <Sidebar className={orgInfoModalOpen ? "open" : ""}>
            <OrgInfo onClick={handleOrgInfoComplete} onClose={() => setOrgInfoModalOpen(false)} />
          </Sidebar>
        </Modal>

        {/* CHOOSE AGENT TYPE */}
        <Modal open={agentTypeModalOpen} onClose={() => setAgentTypeModalOpen(false)}>
          <Sidebar className={agentTypeModalOpen ? "open" : ""}>
            <AgentCardSelections
              selectedAgentTemplate={selectedAgentTemplate}
              setSelectedAgentTemplate={setSelectedAgentTemplate}
              onClick={() => {}}
              onComplete={handleAgentCreationComplete}
              onClose={() => setAgentTypeModalOpen(false)}
            />
          </Sidebar>
        </Modal>

        {/* ADD PATIENT */}
        <Modal open={addPatientModalOpen} onClose={() => setAddPatientModalOpen(false)}>
          <Sidebar className={addPatientModalOpen ? "open" : ""}>
            <AddPatient onClick={() => handlePatientsAdded()} onClose={() => setAddPatientModalOpen(false)} onUpload={() => handlePatientsAdded()} />
          </Sidebar>
        </Modal>

        {/* CONGRATULATORY MODAL */}
        <Modal open={showCongrats} onClose={() => setShowCongrats(false)}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              textAlign: "center",
              borderRadius: "10px",
              outline: 0,
            }}
          >
            {/* <Confetti />  Confetti animation */}
            <Typography variant="h5" sx={{ mb: 2 }}>
              Congratulations!
            </Typography>
            <Typography variant="body1" sx={{ mb: 3 }}>
              You've completed all the onboarding steps.
            </Typography>
            <PrimaryButton onClick={handleContinue}>Continue</PrimaryButton>
          </Box>
        </Modal>
      </Box>
    </Box>
  );
};

export default OnboardingSteps;
