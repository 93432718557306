import React, { useState } from "react";
import {
  Typography,
  Box,
  FormControl,
  OutlinedInput,
  CircularProgress,
} from "@mui/material";
import { useAppDispatch } from "../../store";
import { verifyEmail, resendVerificationCode } from "../../slices/AuthSlice";
import { PartialFrontendUser } from "../../types";
import { useSnackbar } from "../../providers/SnackbarProvider";
import { PrimaryButton } from "../../subcomponents/CustomButton";
import { Colors } from "../../Colors";
import styled from "styled-components";

const ModalBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 35%;
  margin: auto;
  background-color: #1b1f32;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);

  @media (max-width: 1100px) {
    width: 80%;
    padding: 20px;
    margin: 10% auto;
  }

  @media (max-width: 768px) {
    margin: 5% auto;
  }

  @media (max-width: 480px) {
    padding: 30px 15px;
  }

  @media (max-width: 380px) {
    padding: 15px 20px;
  }
`;

interface VerifyEmailFormProps {
  email: string;
  userData: PartialFrontendUser;
  onSuccess: () => void;
  onLoading: (loading: boolean) => void;
}

const VerifyEmailForm: React.FC<VerifyEmailFormProps> = ({ email, userData, onSuccess, onLoading }) => {
  const [verificationCode, setVerificationCode] = useState(new Array(6).fill(''));
  const [verificationError, setVerificationError] = useState("");
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);

  const dispatch = useAppDispatch();
  const { showMessage } = useSnackbar();

  const handleVerifyEmail = async (verificationCode: string) => {
    if (!userData) return;

    setVerifyLoading(true);
    onLoading(true);

    try {
      const action = await dispatch(
        verifyEmail({
          email,
          code: verificationCode,
          userData,
        })
      );

      if (action.type === "auth/verifyEmail/rejected") {
        throw new Error(`Email verification failed: ${(action.payload as Error).message}`);
      }

      showMessage("Email verified successfully", "", "success");
      onSuccess();
    } catch (error) {
      console.error("Verification failed:", error);
      setVerificationError((error as Error).message || "Verification failed.");
      showMessage((error as Error).message || "Verification failed.", "", "error");
    } finally {
      setVerifyLoading(false);
      onLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const value = e.target.value;
    if (/^[0-9]?$/.test(value)) {
      const newCode = [...verificationCode];
      newCode[index] = value;
      setVerificationCode(newCode);
      if (value && index < 5) {
        const nextInput = document.getElementById(`verification-code-${index + 1}`);
        if (nextInput) {
          (nextInput as HTMLInputElement).focus();
        }
      } else if (!value && index > 0) {
        const prevInput = document.getElementById(`verification-code-${index - 1}`);
        if (prevInput) {
          (prevInput as HTMLInputElement).focus();
        }
      }
      if (newCode.every((digit) => digit !== '')) {
        const fullCode = newCode.join('');
        handleVerifyEmail(fullCode);
      }
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pasteData = e.clipboardData.getData('text');
    if (/^[0-9]{6}$/.test(pasteData)) {
      const newCode = pasteData.split('');
      setVerificationCode(newCode);
      handleVerifyEmail(pasteData);
    }
  };

  const handleResendCode = async () => {
    setResendLoading(true);

    try {
      const action = await dispatch(resendVerificationCode({ email }));

      if (action.type === "auth/resendVerificationCode/rejected") {
        throw new Error(`Failed to resend code: ${(action.payload as Error).message}`);
      }

      showMessage("Verification code resent successfully", "success");
    } catch (error) {
      console.error("Resend code failed:", error);
      showMessage((error as Error).message || "Resend code failed.", "error");
    } finally {
      setResendLoading(false);
    }
  };

  return (
    <ModalBox>
      <FormControl fullWidth sx={{ marginBottom: { xs: "10px", sm: "20px" } }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {verificationCode.map((code, index) => (
            <FormControl key={index} sx={{ width: '15%' }}>
              <OutlinedInput
                id={`verification-code-${index}`}
                value={code}
                onChange={(e) => handleChange(e, index)}
                onPaste={handlePaste}
                inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
                sx={{
                  height: 'auto',
                  margin: '0 5px',
                  backgroundColor: '#2A2A3E',
                  color: '#FFFFFF',
                  '& input': {
                    padding: '10px',
                  },
                  '& fieldset': {
                    borderColor: 'grey',
                  },
                  '&:hover fieldset': {
                    borderColor: '#FFFFFF',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#FFFFFF',
                  },
                }}
              />
            </FormControl>
          ))}
        </Box>
      </FormControl>

      <Typography
        variant="body1"
        sx={{ color: Colors.primary, textAlign: 'center', cursor: 'pointer' }}
        onClick={handleResendCode}
      >
        {(verifyLoading || resendLoading) ? <CircularProgress size={24} color="primary" /> : 'Resend code'}
      </Typography>

      {verificationError && (
        <Typography variant="body2" sx={{ color: Colors.error, marginTop: '20px' }}>
          {verificationError}
        </Typography>
      )}
    </ModalBox>
  );
};

export default VerifyEmailForm;
