import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Box from "@mui/material/Box";
import { Typography, Switch, Modal, Tooltip, Button, Avatar } from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";
import SettingsIcon from "@mui/icons-material/Settings";
import { useNavigate } from "react-router-dom";
import SignoutHeader from "../subcomponents/SignoutHeader";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../store";
import { Agent, EHR, FrontendUser, OnboardingStep, Organization } from "../types";
import { PrimaryButton } from "../subcomponents/CustomButton";
import ConfigureForm from "../forms/ConfigureForm";
import { selectAgents, updateAgentThunk } from "../slices/AgentSlice";
import { useSnackbar } from "../providers/SnackbarProvider";
import Loader from "../subcomponents/Loader";
import CreateAgentModal from "../forms/CreateAgentModal";
import OnboardingSteps from "../forms/OnboardingSteps";
import { updateOnboardingStep } from "../slices/AuthSlice";
import { Colors } from "../Colors";
import { Sidebar } from "../styles/GeneralStyles";

const Container = styled(Box)`
  padding-bottom: 40px;

  @media (max-width: 768px) {
    padding: 10px;
    padding-bottom: 0;
  }
`;

const Header = styled(Typography)`
  margin: 20px 0;

  @media (max-width: 768px) {
    margin: 70px 0 20px 0;
    font-size: 1.6rem;
  }
`;

const AgentsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  @media (max-width: 1100px) {
    justify-content: center;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
  }
`;

const AgentTileBox = styled(Box)`
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  margin: 5px 10px;
  width: 30%;
  padding: 20px 25px;
  color: #fff;
  background-color: ${Colors.bg2};

  @media (max-width: 1100px) {
    width: 45%;
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`;

const AgentHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AgentInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
`;

const AgentRole = styled(Typography)`
  color: ${Colors.primary};
  font-family: Konnect-SemiBold;
  font-size: 0.8rem;
  text-transform: capitalize;
`;

const AgentDescription = styled(Typography)`
  color: var(--primary-color);
  line-height: 1;
  padding-top: 10px;
  font-size: 1.1rem;
`;

const CommunicationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
`;

const CommunicationsCount = styled(Typography)`
  font-size: 6.5rem;
`;

const CommunicationsText = styled(Typography)`
  width: 160px;
  margin-top: -15px;
`;

const ViewAgentButton = styled.div`
  margin-top: 25px;
`;

const AddAgentBox = styled(Button)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 70px;
  margin: 5px 10px;
  width: 100%;
  background: #fff;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const AgentTile: React.FC<{ agent: Agent }> = ({ agent }) => {
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const [isActive, setIsActive] = useState<boolean>(agent.isActive);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { showMessage } = useSnackbar();

  const handleToggle = () => {
    if (!user?.token) {
      showMessage("Session is expired. Please refresh.", "error");
      return;
    }
    dispatch(updateAgentThunk({ agent: { agentId: agent.agentId, isActive: !isActive } }));
    setIsActive(!isActive);
  };

  const handleViewAgent = () => {
    navigate(`/dashboard/agents/${agent.agentId}`);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // const communications = agent.numCalls;

  return (
    <AgentTileBox key={agent.agentId}>
      <AgentHeader>
        <AgentInfo>
          <Typography variant="h6">{agent.name}</Typography>
          <Switch
            checked={isActive}
            onChange={handleToggle}
            sx={{
              "& .MuiSwitch-switchBase": {
                color: "#FFFFFF",
                "& + .MuiSwitch-track": {
                  backgroundColor: "#CCCCCC",
                },
              },
              "& .MuiSwitch-switchBase.Mui-checked": {
                color: "#FFFFFF",
                "& + .MuiSwitch-track": {
                  backgroundColor: Colors.primary,
                },
              },
            }}
          />
        </AgentInfo>
        <SettingsIcon sx={{ cursor: "pointer" }} onClick={handleOpenModal} />
      </AgentHeader>
      <AgentRole fontWeight={"bold"} marginTop={"-5px"}>
        {agent.agentType}
      </AgentRole>

      {/* <AgentDescription>{agent.description}</AgentDescription> */}

      <CommunicationsContainer>
        <Avatar sx={{ width: 100, height: 100, backgroundColor: "#fff", color: "#000", overflow: "hidden" }}>
          <img src={agent.avatarUrl} alt={agent.name} style={{ width: "100%", height: "100%", objectFit: "cover" }} />
        </Avatar>
      </CommunicationsContainer>
      <ViewAgentButton>
        <PrimaryButton onClick={handleViewAgent} fullWidth={true}>
          View agent
        </PrimaryButton>
      </ViewAgentButton>

      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Sidebar className={isModalOpen ? "open" : ""}>
          <ConfigureForm agent={agent} isModalOpen={isModalOpen} onClose={handleCloseModal} />
        </Sidebar>
      </Modal>
    </AgentTileBox>
  );
};

const Agents: React.FC = () => {
  const agents: Agent[] = useSelector(selectAgents);
  const loading = useSelector((state: RootState) => state.agents.loading);
  const [isDisabled, setIsDisabled] = useState(false);
  const [tooltipMessage, setTooltipMessage] = useState("");
  const [isCreateAgentModalOpen, setIsCreateAgentModalOpen] = useState(false);
  const [integrated, setIntegrated] = useState<boolean>(false);
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const organization: Organization | null = useSelector((state: RootState) => state.auth.organization);
  const onboardingStep: OnboardingStep = useSelector((state: RootState) => state.auth.onboardingStep);
  const dispatch = useAppDispatch();

  const gettingSessionLoading: boolean = useSelector((state: RootState) => state.auth.gettingSessionLoading);

  const handleOpenCreateAgentModal = () => {
    setIsCreateAgentModalOpen(true);
  };

  const handleCloseCreateAgentModal = () => {
    setIsCreateAgentModalOpen(false);
  };

  useEffect(() => {
    if (user && organization) {
      setIntegrated(Object.keys(organization.integrations ?? {}).includes(EHR.healthie));
    }
  }, [user, organization]);

  useEffect(() => {
    if (!integrated) {
      setIsDisabled(true);
      setTooltipMessage("EHR integration required. Please integrate at the Integrations page.");
    } else {
      setIsDisabled(false);
      setTooltipMessage("");
    }
  }, [integrated]);

  // useEffect(() => {
  //   if (agents.length === 0) {
  //     dispatch(updateOnboardingStep(OnboardingStep.init));
  //   }
  // }, [agents.length]);

  // useEffect(() => {
  //   if (gettingSessionLoading) { return; }
  //   if (onboardingStep === OnboardingStep.end || onboardingStep === OnboardingStep.ehrToken) { return; }
  //   if (onboardingStep > OnboardingStep.orgInfo) { return; }
  //   if (agents.length === 0 && (!organization || !organization.name)) {
  //     dispatch(updateOnboardingStep(OnboardingStep.init));
  //   } else if (agents.length === 0) {
  //     dispatch(updateOnboardingStep(OnboardingStep.orgInfo));
  //   }

  //   if (agents.length > 0) {
  //     dispatch(updateOnboardingStep(OnboardingStep.makeCall));
  //   }
  // }, [agents.length, organization?.name, onboardingStep, gettingSessionLoading]);

  if (loading && agents.length == 0) {
    return <Loader message="" size={60} />;
  }

  return (
    <Container>
      <SignoutHeader />
      <Box sx={{ marginTop: { xs: "75px", lg: "20px" } }}>
        <Header variant="h5" sx={{ color: "#FFF", fontSize: { xs: "1.3rem", sm: "1.8rem" }, marginBottom: "10px" }}>
          Agents
        </Header>
        <AgentsContainer>
          {/* { onboardingStep !== OnboardingStep.end && (
            <OnboardingSteps />
          )} */}
          {/* { onboardingStep === OnboardingStep.end && ( */}
          <>
            {agents.map((agent) => (
              <AgentTile key={agent.agentId} agent={agent} />
            ))}
          </>
          {/* )} */}
        </AgentsContainer>
      </Box>
      <Modal open={isCreateAgentModalOpen} onClose={handleCloseCreateAgentModal}>
        <CreateAgentModal onClose={handleCloseCreateAgentModal} />
      </Modal>
    </Container>
  );
};

export default Agents;
