import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../store";
import { ApiKey } from "../types";

export interface ApiKeyState {
  apiKeys: ApiKey[] | undefined;
  loading: boolean;
  loadingMsg: string | undefined;
  errorMsg: string | undefined;
}

const initialState: ApiKeyState = {
  apiKeys: undefined,
  loading: false,
  loadingMsg: "",
  errorMsg: "",
};

export const makeApiKey = createAsyncThunk<
  { key: string, name: string }, // Ensure the payload includes both key and name
  { token: string, name: string },
  { rejectValue: Error }
>(
  "apiKeys/makeApiKey",
  async ({ token, name }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/keys`,
        { name: name },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status !== 200) {
        return rejectWithValue(new Error("Failed to create API key"));
      }

      return { key: response.data.key, name: name }; // Return both apiKey and name
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(
        new Error("Failed to create API key: " + (error as any)?.response?.data?.error || (error as Error).message || "Unknown error")
      );
    }
  }
);

export const refreshApiToken = createAsyncThunk<
  { apiKey: string, name: string }, // Ensure the payload includes both apiKey and name
  { name: string, token: string },
  { rejectValue: Error }
>(
  "apiKeys/refreshApiToken", // Ensure the prefix matches the slice name
  async ({ name, token }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/keys/refresh`,
        { name },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status !== 200) {
        return rejectWithValue(new Error("Failed to refresh API key"));
      }

      return { apiKey: response.data.apiKey as string, name }; // Return both apiKey and name
    } catch (error) {
      return rejectWithValue(
        new Error("Failed to refresh API key: " + (error as any)?.response?.data?.error || (error as Error).message || "Unknown error")
      );
    }
  }
);

export const deleteApiKey = createAsyncThunk<
  { message: string },
  { token: string, name: string },
  { rejectValue: Error }
>(
  "apiKeys/deleteApiKey",
  async ({ token, name }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/keys`,
        {
          params: { name },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status !== 200) {
        return rejectWithValue(new Error("Failed to delete API key"));
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(
        new Error("Failed to delete API key: " + (error as any)?.response?.data?.error || (error as Error).message || "Unknown error")
      );
    }
  }
);

export const fetchApiKeys = createAsyncThunk<
  string[], 
  { token: string }, 
  { rejectValue: string }
>(
  'apiKeys/fetchApiKeys',
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/keys`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status !== 200) {
        return rejectWithValue('Failed to fetch API keys');
      }

      return response.data;
    } catch (error) {
      new Error("Failed to fetch API key: " + (error as any)?.response?.data?.error || (error as Error).message || "Unknown error")
    }
  }
);

const apiKeysSlice = createSlice({
  name: "apiKeys",
  initialState,
  reducers: {
    setApiKeys: (
      state,
      action: PayloadAction<{ apiKeys: ApiKey[] | undefined }>
    ) => {
      state.apiKeys = action.payload.apiKeys;
    },
    clearApiKeys: (state) => {
      state.apiKeys = state.apiKeys?.map((key) => ({
        ...key,
        apiKey: undefined,
      }));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchApiKeys.pending, (state) => {
        state.loading = true;
        state.loadingMsg = "Getting API keys...";
        state.errorMsg = undefined; // Clear previous error message on new request
      })
      .addCase(fetchApiKeys.fulfilled, (state, action: PayloadAction<string[]>) => {
        state.apiKeys = action.payload.map((name: string) => ({ name, key: undefined }));
        state.loadingMsg = undefined;
        state.loading = false;
      })
      .addCase(fetchApiKeys.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.loading = false;
        state.loadingMsg = "";
        state.errorMsg = action.payload || 'Failed to fetch API keys'; // Capture the error message
      })
      .addCase(makeApiKey.pending, (state) => {
        state.loading = true;
        state.loadingMsg = "Creating API key...";
        state.errorMsg = undefined; // Clear previous error message on new request
      })
      .addCase(makeApiKey.fulfilled, (state, action) => {
        state.apiKeys = state.apiKeys ? [...state.apiKeys, action.payload] : [action.payload];
        state.loadingMsg = undefined;
        state.loading = false;
      })
      .addCase(makeApiKey.rejected, (state, action) => {
        state.loading = false;
        state.loadingMsg = undefined;
        state.errorMsg = (action.payload as Error)?.message || "Failed to create API key"; // Capture the error message
      })
      .addCase(refreshApiToken.pending, (state) => {
        state.loading = true;
        state.loadingMsg = "Refreshing API key...";
        state.errorMsg = undefined;
      })
      .addCase(refreshApiToken.fulfilled, (state, action) => {
        state.loading = false;
        if (state.apiKeys) {
          const index = state.apiKeys.findIndex(
            (apiKey) => apiKey.name === action.meta.arg.name
          );
          if (index !== -1) {
            state.apiKeys[index].key = action.payload.apiKey;
          }
        }
      })
      .addCase(refreshApiToken.rejected, (state, action) => {
        state.loading = false;
        state.loadingMsg = "";
        state.errorMsg = (action.payload as Error)?.message || "Failed to create API key";
      })
      .addCase(deleteApiKey.pending, (state) => {
        state.loading = true;
        state.loadingMsg = "Deleting API key...";
        state.errorMsg = undefined;
      })
      .addCase(deleteApiKey.fulfilled, (state, action) => {
        state.loading = false;
        if (state.apiKeys) {
          state.apiKeys = state.apiKeys.filter(
            (apiKey) => apiKey.name !== action.meta.arg.name
          );
        }
        state.loadingMsg = undefined;
        state.errorMsg = undefined;
      })
      .addCase(deleteApiKey.rejected, (state, action) => {
        state.loading = false;
        state.loadingMsg = undefined;
        state.errorMsg = (action.payload as Error)?.message || "Failed to create API key";
      });
  },
});

export const { setApiKeys, clearApiKeys } = apiKeysSlice.actions;

export const selectApiKeys = (state: RootState) => state.apiKeys;

export default apiKeysSlice.reducer;
