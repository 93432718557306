import React, { FocusEventHandler, useCallback, useEffect, useState } from "react";
import { Modal, Box, Typography, Table, TableHead, TableRow, TableCell, TableBody, Button, IconButton, Icon, TextField } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseIcon from "@mui/icons-material/Close";
import { ModalBox } from "../styles/GeneralStyles";
import { Colors } from "../Colors";
import { HeaderBox } from "./SidebarComponents";

interface NumberTextFieldProps {
  minValue: number;
  maxValue: number;
  defaultValue?: number;
  onChange: (value: number) => void;
}

const NumberTextField: React.FC<NumberTextFieldProps> = ({ minValue, maxValue, defaultValue = 0, onChange }) => {
  const [value, setValue] = useState<number | string>(defaultValue);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleBlur = () => {
    const numericValue = parseFloat(value as string);

    if (isNaN(numericValue)) {
      // If the value is not a number, reset it to the default or minValue
      setValue(defaultValue);
      onChange(defaultValue);
    } else if (numericValue < minValue) {
      setValue(minValue);
      onChange(minValue);
    } else if (numericValue > maxValue) {
      setValue(maxValue);
      onChange(maxValue);
    } else {
      setValue(numericValue);
      onChange(numericValue);
    }
  };

  return (
    <TextField
      type="number"
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      variant="outlined"
      sx={{
        width: "80px",
        background: Colors.textfield,
        borderRadius: "10px",
        "& .MuiInputBase-input": {
          color: "#FFFFFF",
        },
        "& .MuiInputLabel-root": {
          color: Colors.info,
        },
        "& .MuiSvgIcon-root": {
          color: Colors.info,
        },
      }}
    />
  );
};

type PatientPriorityProps = {
  sortedPatients: any[];
  open: boolean;
  onClose: (newPatients: any[]) => void;
};

const PatientPriority: React.FC<PatientPriorityProps> = ({ sortedPatients, open, onClose }) => {
  const [patients, setPatients] = useState(sortedPatients);

  function moveElement<T>(array: T[], fromIndex: number, toIndex: number): T[] {
    const newArray = [...array]; // Create a copy of the array
    const [element] = newArray.splice(fromIndex, 1); // Remove the element from the fromIndex
    newArray.splice(toIndex, 0, element); // Insert the element at the toIndex
    console.log(newArray);
    return newArray;
  }

  const moveRowUp = (index: number) => {
    if (index === 0) return;
    setPatients(moveElement(patients, index, index - 1));
  };

  const moveRowDown = (index: number) => {
    if (index === patients.length - 1) return;
    setPatients(moveElement(patients, index, index + 1));
  };

  const handlePriorityChange = (fromIndex: number, toIndex: number) => {
    if (toIndex < 0 || toIndex >= patients.length) return;
    setPatients(moveElement(patients, fromIndex, toIndex));
  };

  const columnTypes = ["Priority", "Name"];

  return (
    <Box
      sx={{
        overflowY: "auto",
        height: "92%",
        paddingBottom: "40px",
        paddingRight: "20px",
        background: "transparent",
        borderRadius: "10px",
      }}
    >

      <HeaderBox title="Patient Priority" onClose={() => onClose(patients)} />

      <Table
        sx={{
          width: "100%",
          marginTop: "30px",
          background: Colors.bg2, 
          borderRadius: "15px",
          "& .MuiTableCell-root": {
            color: "#FFF",
            borderColor: "rgba(255, 255, 255, 0.1)",
          },
        }}
      >
        <TableHead>
          <TableRow>
            {columnTypes.map((type, index) => (
              <TableCell
                key={index}
                sx={{ color: "#FFF", fontWeight: "bold", borderRadius: "8px 8px 0 0" }}
              >
                {type}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {patients.map((patient, index) => (
            <TableRow
              key={patient.patientId}
            >
              <TableCell>
                <NumberTextField
                  key={index}
                  minValue={1}
                  maxValue={patients.length}
                  defaultValue={index + 1}
                  onChange={(value) => handlePriorityChange(index, value - 1)}
                />
                <IconButton
                  onClick={() => moveRowUp(index)}
                  disabled={index === 0}
                  sx={{
                    color: index === 0 ? "#555" : "#FFF",
                  }}
                >
                  <KeyboardArrowUpIcon />
                </IconButton>
                <IconButton
                  onClick={() => moveRowDown(index)}
                  disabled={index === patients.length - 1}
                  sx={{
                    color: index === patients.length - 1 ? "#555" : "#FFF",
                  }}
                >
                  <KeyboardArrowDownIcon />
                </IconButton>
              </TableCell>
              <TableCell>{patient.firstName} {patient.lastName}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default PatientPriority;
