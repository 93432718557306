import React from "react";

export enum PageType {
  single = "single",
  multiple = "multiple",
}

// agentType: AgentType | null;
//   schedulingType: SchedulingType | null;
//   schedulingSoftware: SchedulingSoftware | null;
//   patientFinding: PatientFinding | null;
//   patientInfoRetrieval: PatientInfoRetrieval | null;
//   agentName: string | null;
//   callbackNumber: string | null;
//   orgName: string | null;
//   orgAreaCode: string | null;
//   cloudFaxProvider?: CloudFaxProvider | null;

export enum CardTypes {
  agentType = "agentType",
  schedulingType = "schedulingType",
  ehr = "ehr",
  patientFinding = "patientFinding",
  patientInfoRetrieval = "patientInfoRetrieval",
  cloudFaxProvider = "cloudFaxProvider",
}

export type ChoiceCardType = {
  icon: React.JSX.Element;
  title: string;
  description: string;
  nextRoute: string;
  type: CardTypes;
  typeValue: AgentType | SchedulingType | EHR | PatientFinding | PatientInfoRetrieval | CloudFaxProvider;
};

export enum AgentType {
  scheduling = "scheduling",
  confirmation = "confirmation",
  reminder = "reminder",
  waitlist = "waitlist",
  other = "other",
  inbound = "inbound",
}

export const FrontendAgentType = {
  [AgentType.scheduling]: "Scheduling",
  [AgentType.confirmation]: "Confirmation",
  [AgentType.reminder]: "Reminder",
  [AgentType.waitlist]: "Waitlist",
  [AgentType.other]: "Other",
  [AgentType.inbound]: "Inbound",
};

export enum SchedulingType {
  text = "text",
  email = "email",
  phone = "call",
  other = "other",
}

export const FrontendSchedulingType = {
  [SchedulingType.text]: "Text",
  [SchedulingType.email]: "Email",
  [SchedulingType.phone]: "Phone",
  [SchedulingType.other]: "Other",
};

export enum PatientFinding {
  selfReferral = "self-referral",
  referral = "referral",
  other = "other",
}

export const FrontendPatientFinding = {
  [PatientFinding.selfReferral]: "Self-referral",
  [PatientFinding.referral]: "Referral",
  [PatientFinding.other]: "Other",
};

export enum PatientInfoRetrieval {
  spreadsheet = "spreadsheet",
  cloudFax = "cloud-fax",
  other = "other",
}

export const FrontendPatientInfoRetrieval = {
  [PatientInfoRetrieval.spreadsheet]: "Spreadsheet",
  [PatientInfoRetrieval.cloudFax]: "Cloud Fax",
  [PatientInfoRetrieval.other]: "Other",
};

export enum CloudFaxProvider {
  faxPlus = "fax-plus",
  other = "other",
}

export const FrontendCloudFaxProvider = {
  [CloudFaxProvider.faxPlus]: "Fax Plus",
  [CloudFaxProvider.other]: "Other",
};

export type AuthUserModel = {
  agentType: AgentType | null;
  schedulingType: SchedulingType | null;
  enableTexting: boolean;
  enableCalling: boolean;
  ehr: EHR | null;
  patientFinding: PatientFinding | null;
  patientInfoRetrieval: PatientInfoRetrieval | null;
  agentName: string | null;
  callbackNumber: string | null;
  orgName: string | null;
  orgAreaCode: string | null;
  cloudFaxProvider?: CloudFaxProvider | null;
};

export enum SubscriptionPlans {
  freePlan = "Free plan",
}

export enum SubscriptionStep {
  initial = "initial",
  paymentMethod = "paymentMethod",
  selectPlan = "selectPlan",
}

export enum WebsocketActions {
  subscribe = "subscribe",
  unsubscribe = "unsubscribe",
}

export interface StripePlan {
  id: string;
  unit_amount: number;
  recurring: {
    interval: "day" | "week" | "month" | "year";
  };
  metadata: {
    name: string;
    price: string;
    units: string;
  };
}

export type AgentTemplate = {
  templateId: string;
  avatarUrl: string;
  name: string;
  promptId: string;
  purpose: string;
  stripeProductId: string;
  type: AgentType;
  ehr: EHR;
  active: boolean;
};

export type User = {
  userId: string;
  email: string;
  phoneNumber: string;
  lastModified: string; // ISO 8601 format date string
  organizationId: string;
  firstName: string;
  lastName: string;
  freeCalls: number;
  stripeCustomerId: string;
  completedWalkthrough: boolean;
  completedOnboarding: boolean;
};

export enum WebsocketEvents {
  calls = "calls",
  scheduledCalls = "scheduledCalls",
  waitlistRuns = "waitlistRuns",
}

export enum StreamType {
  INSERT = "INSERT",
  MODIFY = "MODIFY",
  REMOVE = "REMOVE",
}

export interface PartialFrontendUser {
  email: string;
  firstName: string;
  lastName: string;
  // organizationName?: string;
  // callbackNumber?: string;
  userSub: string;
  password: string;
}

export type FrontendUser = User & {
  token?: string;
};

export enum ScheduledCallStatus {
  scheduling = "scheduling",
  scheduled = "scheduled",
  queued = "queued",
  calling = "calling",
  executed = "executed",

  // error cases
  schedulingError = "scheduling-error",
  queuingError = "queueing-error",
  callingError = "calling-error",
}

type ScheduledCall = {
  scheduledCallId: string;
  agentId: string;
  organizationId: string;
  scheduledDate: string;
  enqueuedDate?: string;
  executedDate?: string;
  redials?: number;
  error?: string;
  schedulingStatus: ScheduledCallStatus;
  overrideUsageLimit?: boolean;

  // essential patient information
  patientPhoneNumber: string;
  patientFirstName: string;
  patientLastName: string;
};

export type FrontendScheduledCall = ScheduledCall & {
  [key: string]: string;
};

export type NewUserData = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  // organizationName: string;
  // callbackNumber: string;
};

export type TemporaryUserData = {
  // not saved in the database! Just used for authentication
  email: string;
  password: string;
};

export interface AuthState {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  user: FrontendUser | null;
  authUserModel: AuthUserModel | null;
  message: string;
  signUpResult?: SignUpResult; // Add this line
}

export interface SignUpResult {
  email: string;
  status: string; // 'Pending Verification'
}

export type WaitlistTextState =
  | WaitlistSlotState.notContacted
  | WaitlistSlotState.accepted
  | WaitlistSlotState.declined
  | WaitlistSlotState.bookingFailed
  | WaitlistSlotState.cannotContact
  | WaitlistSlotState.contacted
  | WaitlistSlotState.inProgress
  | WaitlistSlotState.noResponse
  | WaitlistSlotState.unknown
  | WaitlistSlotState.error;

export type WaitlistCallState =
  | WaitlistSlotState.notContacted
  | WaitlistSlotState.accepted
  | WaitlistSlotState.declined
  | WaitlistSlotState.bookingFailed
  | WaitlistSlotState.cannotContact
  | WaitlistSlotState.noResponse
  | WaitlistSlotState.unknown
  | WaitlistSlotState.error
  | WaitlistSlotState.noLongerAvailable;

export type WaitlistSlot = {
  waitlistRunId: string;
  waitlistSlotId: string;
  patientId: string;
  state: WaitlistSlotState;
  stateJustification: string;
  callEnabled: boolean;
  textEnabled: boolean;
  textId?: string;
  callId?: string;
  callState?: WaitlistCallState;
  callStateJustification?: string;
  textState?: WaitlistTextState;
  textStateJustification?: string;
  patient: Patient;
};

export enum WaitlistSlotState {
  notContacted = "not-contacted",
  accepted = "accepted",
  declined = "declined",
  bookingFailed = "booking-failed", // failed to book an appointment (e.g. already booked)
  cannotContact = "cannot-contact", // patient does not want to be contacted

  contacted = "contacted", // initial text (or voicemail for hybrid campaigns)
  inProgress = "in-progress", // received at least one response text
  unknown = "unknown", // Call with unknown outcome (call-only campaign), or waitlist run expired with a response but the outcome was not clear
  noResponse = "no-response", // Call went to voicemail (call-only campaign), or waitlist run expired without response
  error = "error",
  expired = "expired",
  noLongerAvailable = "no-longer-available",
}

export type WaitlistRun = {
  waitlistRunId: string;
  organizationId: string;
  agentId: string;
  appointmentDate: string;
  state: WaitlistState;
  stateJustification: string;
  waitlistSlotIds: string[];
  bookedBy?: Patient;
  callEnabled: boolean;
  textEnabled: boolean;
  expirationDate: string;

  ehrProviderId?: string;
  pcpName?: string;
  ehrApptTypeId?: string;
  appointmentTypeName?: string;
  appointmentTypeDuration?: number;
  ehrContactType?: string;

  // type
  ehr: EHR;

  timeToFillMinutes?: number;
  slots: WaitlistSlot[];
  createdAt: string;
};

export enum WaitlistTextStates {
  firstMessageSent = "first-message-sent",
  inProgress = "in-progress",
  accepted = "accepted",
  declined = "declined",
  notContacted = "not-contacted",
  expired = "expired",
}

export type HealthieProvider = {
  type: string;
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  brandName: string;
};

export type HealthieAppointmentType = {
  id: string;
  name: string;
};

export type HealthieAppointment = {
  id: string;
  name: string;
  length: number;
  available_contact_types: string[];
  is_group: boolean;
};

export type TextThreadMessage = {
  threadMessageId: string;
  date: string;
  message: string;
  role: "agent" | "patient";
  state?: WaitlistTextStates;
  stateJustification?: string;
};

export type FrontendText = {
  textId: string;
  organizationId: string;
  waitlistRunId: string;
  thread: TextThreadMessage[];
  patientPhoneNumber: string;
  agentPhoneNumber: string;
  variables: Record<string, unknown>;
  state: string;
  stateJustification: string;
  waitlistPatientId: string;
  manualOverride: boolean;
};

export enum Integrations {
  csv = "csv",
  fax = "fax",
  waitlist = "waitlist",
}

export type Organization = {
  adminId: string;
  organizationId: string;
  name: string;
  integrations: EHR[];
  timezone: string;
  phoneNumber: string;
  textingPhoneNumber: string;
  createdAt: string; // ISO 8601 format date string
  lastModified: string;
  inboundRedirectNumber: string; // E.164 format
  emergencyTransferNumber: string;
  workingHoursStart: string;
  workingHoursEnd: string;
};

export enum OnboardingStep {
  init = 0,
  orgInfo = 1,
  createAgent = 2,
  ehrToken = 3,
  makeCall = 4,
  end = 5,
}

export enum HealthieEventType {
  appointmentDeleted = "appointment-deleted",
  appointmentRescheduled = "appointment-rescheduled",
  appointmentCancelled = "appointment-cancelled",
  appointmentLateCancellation = "appointment-late-cancellation",
}

export enum CalendlyStatus {
  connected = "connected",
  inProgress = "in-progress",
  notFound = "not-found",
}

export type Agent = {
  agentId: string;
  agentType: AgentType;
  isActive: boolean;
  language: string;
  organizationId: string;
  voiceId: string;
  purpose: string;
  outcomes: string[];
  avatarFilename: string;
  createdAt: Date;
  lastModified: string;
  title: string;
  description: string;
  provider: "vapi" | "retell" | "bland";
  integrations: Integrations[];
  workflowType: Integrations.csv | Integrations.fax | Integrations.waitlist | null;
  waitlistColumnRankings?: string[];
  variableSubRankings?: Record<string, string[]>;

  areaCode: string;

  // Prompt variables
  promptId: string;
  intro?: string;
  firstText?: string;

  // Communication settings
  enableTexting: boolean;
  enableCalling: boolean;

  // Text message settings
  enableAutomaticTextResponses: boolean;

  // Phone call settings
  phoneNumber: string;
  spamRiskPrevention: boolean;
  callerId: string | null;
  enableRedialing: boolean;
  maxNumberOfRedials: number;
  redialGapMinutes: number;

  // Messaging settings
  name: string;
  enableLeavingVoicemail: boolean;

  // Waitlist settings
  minutesToCutoffBeforeAppointment?: number;
  minutesToWaitBeforeCalling?: number;
  healthieEventTypes?: HealthieEventType[];
  autoStartWaitlistRuns: boolean;
  daysForwardToCheckAvailableSlots: number;
  interruptionThreshold?: number;

  // Healthie settings
  ehrProviderId?: string;
  ehrApptTypeId?: string;
  ehr: EHR;

  avatarUrl: string;

  stripeProductId: string;

  namespaceId?: string; // the pinecone namespace
} & Partial<AgentStats>;

export type AgentStats = {
  bookedApptVolume: number;
  bookedApptRate: number;
  avgTimeToFillAppt: number;
};

export enum EHR {
  healthie = "healthie",
  none = "none",
}

export enum HealthieContactTypes {
  videoCall = "Video Call",
  phoneCall = "Phone Call",
  inPerson = "In Person",
}

export enum CallStatuses {
  calling = "calling",
  complete = "complete",
  error = "error",
  voicemail = "voicemail",
  /*
  new = "new",
  queued = "queued",
  allocated = "allocated",
  started = "started",
  complete = "complete",
  pre_queue_error = "pre_queue_error",
  queue_error = "queue_error",
  call_error = "call_error",
  complete_error = "complete_error",
  */
}

export type Call = {
  startedAt: string;
  endedAt: string;
  callLength: number;
  to: string;
  from: string;
  inbound: boolean;
  errorMessage: string | null;
  answeredBy: string;
  summary: string;
  callEndedBy: string;
  callId: string;
  patientFirstName: string;
  patientLastName: string;
  patientEmail: string;
  patientPhoneNumber: string;
  outcome: string;
  outcomeSentiment: OutcomeSentiment; // "positive" | "negative" | "neutral"
  outcomeJustification: string;
  status: CallStatuses;
  outcomeDetails: string;
  transcript: string;
  appointmentDate: string;
  recordingUrl: string;
  agentId: string;
  scheduledCallId: string;
  scheduledDate?: string;

  // for frontend only
  agentName: string;
};

export enum OutcomeSentiment {
  positive = "positive",
  negative = "negative",
  neutral = "neutral",
}

export type HealthieWaitlistPatient = Patient & RawHealthiePatient & WaitlistPatient;

export type RawHealthiePatient = {
  healthiePatientId: string;
  firstName: string;
  lastName: string;
  dob: string;
  gender: string;
  phoneNumber: string;
  email: string;
  nextApptDate: string;
  preferredLanguageCode: string;
  timezone: string;
};

export type Patient = {
  patientId: string;
  organizationId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email?: string;
  timezone: string;
  language: string;
  callEnabled: boolean;
  textEnabled: boolean;
  deletedTimestamp?: string; // ISO 8601 UTC format date string
  ehrParams: Record<EHR, Record<string, string>>;
};

export type WaitlistPatient = {
  patientId: string; // primary key
  organizationId: string;
  availableDates: string[];
  priority: number;
};

export type CSVPatient = {
  patientFirstName: string;
  patientLastName: string;
  patientPhoneNumber: string;
  scheduledDate?: string;
};

export type ApiKey = {
  key: string | undefined;
  name: string; // names are unique for each org
};

export type Webhook = {
  url: string;
  signingKey: string | undefined;
  name: string; // names are unique for each org
  active: boolean;
  recordingEnabled: boolean;
};

export type VariableDetail = EssentialVariableDetail | AuxillaryVariableDetail | SpecialVariableDetail;

export type AuxillaryVariableDetail = {
  required: boolean;
  type: AuxillaryVariableTypes;
  metadata: Record<string, string | number>;
};

export type EssentialVariableDetail = {
  required: true;
  type: EssentialVariables;
  metadata: Record<string, string | number>;
};

export type SpecialVariableDetail = {
  required: false;
  type: SpecialVariables;
  metadata: Record<string, string | number>;
};

export type AuxillaryVariableTypes = SingleTypes | ArrayTypes;

export enum SpecialVariables {
  liveTransferNumber = "live_transfer_number",
  patientFirstName = "patient_first_name",
  patientLastName = "patient_last_name",
  preferredContactMethod = "preferred_contact_method",
  preferredLanguage = "preferred_language",
  email = "email",
}

export enum EssentialVariables {
  patientPhoneNumber = "patient_phone_number",
}

export enum SingleTypes {
  date = "date",
  phoneNumber = "phone_number",
  string = "string",
  number = "number",
}

export enum ArrayTypes {
  dateArray = "date[]",
  phoneNumberArray = "phone_number[]",
  stringArray = "string[]",
  numberArray = "number[]",
}

export type QuestionAnswerPair = {
  id: string;
  question: string;
  answer: string;
  source?: string;
};

export enum WaitlistState {
  notStarted = "not-started",
  ongoing = "ongoing",
  accepted = "accepted",
  declined = "declined",

  bookingFailed = "booking-failed",
  noValidPatients = "no-valid-patients",
  expired = "expired",
}
