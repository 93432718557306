import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../store";
import axios from "axios";
import { sessionExpired } from "./SessionSlice";
import { DateTime } from "luxon";
import { FrontendUser, WaitlistRun } from "../types";

export interface WaitlistRunsSlice {
  waitlistRuns: WaitlistRun[];
  fetchingRunsLoading: boolean;
  newestRunIds: string[];
  agentId: string | null;
  inProgress: number;
}

const initialState: WaitlistRunsSlice = {
  waitlistRuns: [],
  fetchingRunsLoading: true,
  newestRunIds: [],
  agentId: null,
  inProgress: 0,
};

export const fetchWaitlistRuns = createAsyncThunk<{ waitlistRuns: WaitlistRun[] }, { agentId: string }, { rejectValue: Error }>(
  "waitlist/fetchWaitlistRuns",
  async ({ agentId }, { getState, rejectWithValue }) => {
    try {
      const user: FrontendUser | null = (getState() as RootState).auth.user;

      if (!user || !user.token) {
        throw new Error("User not logged in");
      }

      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/waitlist/${agentId}/runs`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      if (response.data) {
        return { waitlistRuns: response.data };
      }
      throw new Error("Invalid response data");
    } catch (error: any) {
      if (error.response?.status === 401) {
        sessionExpired(true);
      }
      return rejectWithValue(new Error("Failed to fetch waitlist runs."));
    }
  }
);

export const executeRun = createAsyncThunk<void, { runId: string }, { rejectValue: Error }>(
  "waitlist/executeRun",
  async ({ runId }, { dispatch, getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const user = state.auth.user;

      if (!user || !user.token) {
        throw new Error("User not logged in");
      }

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/waitlist/runs/${runId}/start`,
        {},
        {
          headers: { Authorization: `Bearer ${user?.token}` },
        }
      );

      if (response.status !== 200) {
        throw new Error("Failed to execute run");
      }

      await dispatch(fetchWaitlistRuns({ agentId: state.waitlistRuns.agentId! }));
    } catch (error: any) {
      if (error.response?.status === 401) {
        sessionExpired(true);
      }
      return rejectWithValue(new Error("Failed to execute run."));
    }
  }
);

export const deleteRun = createAsyncThunk<void, { runId: string }, { rejectValue: Error }>(
  "waitlist/deleteRun",
  async ({ runId }, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const user = state.auth.user;

      if (!user || !user.token) {
        throw new Error("User not logged in");
      }

      const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/waitlist/runs/${runId}`, {
        headers: { Authorization: `Bearer ${user?.token}` },
      });

      if (response.status !== 200) {
        throw new Error("Failed to delete run");
      }
    } catch (error: any) {
      if (error.response?.status === 401) {
        sessionExpired(true);
      }
      return rejectWithValue(new Error("Failed to delete run."));
    }
  }
);

export const createRun = createAsyncThunk<void, { params: any }, { rejectValue: Error }>(
  "waitlist/createRun",
  async ({ params }, { dispatch, getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const user = state.auth.user;

      if (!user || !user.token) {
        throw new Error("User not logged in");
      }

      const agentId = state.waitlistRuns.agentId;

      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/waitlist/${agentId}/runs`, ...params, {
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      });

      if (response.status !== 200) {
        throw new Error("Failed to create run");
      }

      await dispatch(fetchWaitlistRuns({ agentId: agentId! }));
    } catch (error: any) {
      if (error.response?.status === 401) {
        sessionExpired(true);
      }
      return rejectWithValue(new Error("Failed to create run."));
    }
  }
);

const waitlistRunsSlice = createSlice({
  name: "waitlistRuns",
  initialState,
  reducers: {
    setNewestRunIds: (state, action: PayloadAction<any[]>) => {
      state.newestRunIds = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWaitlistRuns.pending, (state, action) => {
        if (action.meta.arg.agentId !== state.agentId) {
          state.waitlistRuns = [];
        }
        state.fetchingRunsLoading = true;
        state.inProgress += 1;
      })
      .addCase(fetchWaitlistRuns.fulfilled, (state, action) => {
        state.waitlistRuns = action.payload.waitlistRuns;
        state.agentId = action.meta.arg.agentId; // Get agentId from thunk arguments
        state.inProgress -= 1;
        if (state.inProgress === 0) {
          state.fetchingRunsLoading = false;
        }
      })
      .addCase(fetchWaitlistRuns.rejected, (state, action) => {
        state.inProgress -= 1;
        if (state.inProgress === 0) {
          state.fetchingRunsLoading = false;
        }
      })
      .addCase(executeRun.pending, (state) => {
        state.fetchingRunsLoading = true;
        state.inProgress += 1;
      })
      .addCase(executeRun.fulfilled, (state) => {
        state.inProgress -= 1;
        if (state.inProgress === 0) {
          state.fetchingRunsLoading = false;
        }
      })
      .addCase(executeRun.rejected, (state, action) => {
        state.inProgress -= 1;
        if (state.inProgress === 0) {
          state.fetchingRunsLoading = false;
        }
      })
      .addCase(deleteRun.pending, (state) => {
        state.fetchingRunsLoading = true;
        state.inProgress += 1;
      })
      .addCase(deleteRun.fulfilled, (state, action) => {
        const runId = action.meta.arg.runId; // Access the runId from the thunk args
        state.waitlistRuns = state.waitlistRuns.filter((run) => run.waitlistRunId !== runId);
        state.inProgress -= 1;
        if (state.inProgress === 0) {
          state.fetchingRunsLoading = false;
        }
      })
      .addCase(deleteRun.rejected, (state, action) => {
        state.inProgress -= 1;
        if (state.inProgress === 0) {
          state.fetchingRunsLoading = false;
        }
      })
      .addCase(createRun.pending, (state) => {
        state.fetchingRunsLoading = true;
        state.inProgress += 1;
      })
      .addCase(createRun.fulfilled, (state) => {
        state.inProgress -= 1;
        if (state.inProgress === 0) {
          state.fetchingRunsLoading = false;
        }
      })
      .addCase(createRun.rejected, (state, action) => {
        state.inProgress -= 1;
        if (state.inProgress === 0) {
          state.fetchingRunsLoading = false;
        }
      });
  },
});

export const { setNewestRunIds } = waitlistRunsSlice.actions;

export const selectWaitlistRuns = (state: RootState) => state.waitlistRuns.waitlistRuns;

export default waitlistRunsSlice.reducer;
