import styled from 'styled-components';
import { Typography, ToggleButton, TextField, Button, Box, FormControl, OutlinedInput } from '@mui/material';
import { Colors } from './Colors';

export const StyledToggleButtonVariantOne = styled(ToggleButton)`
  padding-right: 20px; // Space between toggle button and message

  &.MuiToggleButton-root {
    padding: 0px; // Adjust padding as needed
  }

  .MuiSvgIcon-root { // Target the icons within the toggle button
    font-size: 3rem; // Increase icon size
  }
`;

export const StatusMessageVariantOne = styled(Typography)({
  // paddingTop: '16px',
  paddingLeft: '10px',
  display: 'inline-flex',
  alignItems: 'center',
  fontSize: '1rem',
  fontWeight: 'bold',
  textTransform: 'none',
  color: '#FFFFFF',
});

export const StyledProgressContainer = styled.div`
  position: relative;
  width: 100%;  // Full width of the container to ensure centering
  height: 40px; // Fixed height for vertical space
  display: flex;
  justify-content: center; // Horizontally center the spinner
  align-items: center; // Vertically center the spinner
  margin-bottom: 20px; // Space between the spinner and the message
`;

export const TextFieldWithBottomPadding = styled(TextField)`
  padding-bottom: 10px;
  color: #FFFFFF;
  border-radius: 10px;
  background: Colors.textfield;
  & input {
    padding: 15px; 
    color: #FFFFFF;
    border-radius: 10px;
  }
`;

export const SubmitTokenButton = styled(Button)`
  background-color: ${Colors.primary}; // Assuming you want a specific blue color, which seems incorrectly set previously
  color: "white"; // This should be a contrasting color to the background for better visibility
  margin-top: 5px;
  margin-bottom: 25px;
  margin-left: 10px; // Margin to separate the button from the text field
  padding: 8px 16px; // Adequate padding for the button content
  border-radius: 4px; // Optional: if you want rounded corners

  &:hover {
    background-color: "#4866c4"; // Slightly darker shade on hover
  }
  &:active {
    background-color: "#3a4e96"; // Even darker on active
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0,123,255,0.5); // Ensuring the outline is around the padding
  }
`;


export const StyledPasswordTextField = styled(TextField)`
  background-color: Colors.textfield; 
  color: #FFFFFF;
  & input {
    letter-spacing: 0.5em; 
    color: #FFFFFF;; /* Make text color transparent */
    text-shadow: 0 0 0 #FFFFFF;; /* Create fake text color for dots */
    caret-color: black; /* Set caret color to black or to match your design */
     padding: 15px; // Padding for the text field content
    ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
      color: #FFFFFF;
    }
    :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
      color: #FFFFFF;
      opacity: 1;
    }
    ::-moz-placeholder { /* Mozilla Firefox 19+ */
      color: #FFFFFF;
      opacity: 1;
    }
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #FFFFFF;
    }
    ::placeholder { /* Modern browsers */
      color: #FFFFFF;
    }
  }
`;

export const DisconnectIntegrationButton = styled.button`
  background-color: #d32f2f;
  padding-top: 10px;
  margin-top: 10px; 
  height: 40px; 
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #b71c1c; // Darker shade on hover
  }

  &:active {
    background-color: #7f0000; // Even darker on active
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0,123,255,0.5); // Adding a focus outline for accessibility
  }
`;

export const StyledCard = styled.div`
  position: relative;
  width: 100%;
  border-radius: 20px;
  padding: 16px;
  background: #1C2138;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  cursor: pointer;

  h2, p {
    margin: 0;
  }

  img {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 0.3rem;
  }

  &:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    transform: scale(1.02);
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  }
`;

export const StyledFormBox = styled(Box)`
  margin-top: 10px;
  padding-top: 10px;
  min-width: 120px; // Apply the minimum width here as well
  margin-bottom: 3px;
  padding-bottom: 3px;
`;

export const TextFieldWithVerticalPadding = styled(TextField)`
  padding-top: 5px !important;
  margin-top: 5px !important;
  padding-bottom: 5px !important;
  margin-bottom: 5px !important;
`;

export const FormControlWithHorizontalPadding = styled(FormControl)`
  padding-left: 10px !important;
  padding-right: 10px !important;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const LogoContainer = styled.div`
  margin-bottom: 24px;

  svg {
    width: 100px;
    height: auto;
  }
`;
export const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  padding-top: 30px;
  padding-right: 100px;
  padding-left: 100px;

  @media (max-width: 1250px) {
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 40px;
  }
`;

export const StyledBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 1% auto;

  @media (max-width: 600px) {
    width: 95%;
    paddingbottom: 50px;
  }
`;

export const FormBox = styled(Box)`
  display: flex;
  flex-direction: column;
  background-color: #f7f9fe;
  padding: 5%;
  border-radius: 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);

  @media (max-width: 600px) {
    padding: 10%;
  }
`;

export const FormInput = styled(OutlinedInput)`
  background-color: #ffffff;
  color: #646769;
  border-radius: 10px;
  margin-bottom: 5%;
`;

export const RowBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  gap: 15%;
  margin-bottom: 0px;

  @media (max-width: 920px) {
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }
`;

export const StyledButtonBox = styled.div`
  display: flex;
  justify-content: left;
  width: 42.5%;
  margin-top: 20px;

  @media (max-width: 600px) {
    justify-content: center;
    width: 100%;
  }
`;
