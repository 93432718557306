import React, { useState, useEffect } from "react";
import { CognitoUser } from "amazon-cognito-identity-js";
import { userPool } from "../AuthUtils/CognitoConfig";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../store"; // Ensure this is the correct path to your store
import { Box, Typography, FormControl, OutlinedInput, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { WhitePenciledLogo, StyledPenciledTextLogo } from "../styles/HeaderStyles";
import { PrimaryButton, SecondaryButton } from "../subcomponents/CustomButton";
import { usePostHog } from "posthog-js/react";
import { useSnackbar } from "../providers/SnackbarProvider";
import { reset, selectAuth } from "../slices/AuthSlice";
import Review from "../assets/Review.png";
import renewPTImage from "../assets/nicoleRenew.jpg";
import { Colors } from "../Colors";

const ForgotPasswordPage: React.FC = () => {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>(); // Type your dispatch
  const posthog = usePostHog();
  const { showMessage } = useSnackbar();
  const { isLoading, message, isSuccess, isError } = useSelector(selectAuth);

  useEffect(() => {
    if (isError && message) {
      showMessage(message, "error");
      dispatch(reset());
    }
    if (isSuccess && step === 1) {
      showMessage(message, "success");
      setStep(2);
      dispatch(reset());
    } else if (isSuccess && step === 2) {
      setStep(3);
      dispatch(reset());
    }
  }, [isError, isSuccess, message, step, dispatch, showMessage]);

  const sendVerificationCode = () => {
    const userData = {
      Username: email,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);
    cognitoUser.forgotPassword({
      onSuccess: (data) => {
        showMessage("Verification code sent to your email", "success");
        setStep(2); // Move to enter verification code and new password
      },
      onFailure: (err) => {
        alert(err.message || JSON.stringify(err));
      },
    });
  };

  const resetPassword = () => {
    const userData = {
      Username: email,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);
    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess: () => {
        showMessage("Password reset successful", "success");
        setStep(3); // Move to success message
      },
      onFailure: (err) => {
        alert(err.message || JSON.stringify(err));
      },
    });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (step === 1) {
      posthog?.capture("password_reset_button_clicked_send_code");
      sendVerificationCode();
    } else if (step === 2) {
      posthog?.capture("password_reset_button_clicked_reset_password_with_code");
      resetPassword();
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        sx={{
          width: { xs: "100%", md: "60%" },
          background: Colors.bg,
          height: "100vh",
          display: { xs: "none", md: "flex" },
          flexDirection: "column",
          justifyContent: "left",
          alignItems: "left",
        }}
      >
        <WhitePenciledLogo style={{ width: "250px", margin: "auto", marginTop: "50px" }} />
        <div style={{ display: "flex", flexDirection: "column", margin: "auto 100px 10px 100px" }}>
          <img src={Review} alt="Review" style={{ width: "150px" }} />
          <Typography variant="body1" color="#FFF" mt={2} style={{ textAlign: "left" }}>
            &quot;Penciled developed an innovative, clinic-specific solution to help us optimize waitlist backfilling and scheduling, ensuring our
            patients receive the care they need more efficiently.&quot;
          </Typography>
          <div style={{ display: "flex", marginBottom: "30px" }}>
            <img
              src={renewPTImage}
              alt="Review"
              style={{ width: "50px", height: "50px", borderRadius: "50%", marginTop: "10px", marginRight: "10px" }}
            />
            <div>
              <Typography variant="body1" color="#FFF" mt={2} style={{ textAlign: "left" }}>
                Nicole Sabes
              </Typography>
              <Typography variant="body2" color="#FFF" mt={0} style={{ textAlign: "left", marginBottom: "40px" }}>
                Clinic Director at Renew Physical Therapy
              </Typography>
            </div>
          </div>
        </div>
      </Box>
      <Box
        sx={{
          padding: "4% 5%",
          display: "flex",
          flexDirection: "column",
          width: { xs: "100%", md: "60%" },
          justifyContent: "center",
        }}
      >
        <Box sx={{ display: { xs: "flex", md: "none" }, marginBottom: "45%", marginTop: "10px", width: { xs: "100%" } }}>
          <StyledPenciledTextLogo />
        </Box>
        <Box>
          {step !== 3 ? (
            <>
              <Typography variant="h4" sx={{ mb: 1, textAlign: "left" }}>
                {step === 1 ? "Forgot Password?" : "Set New Password"}
              </Typography>
              <Typography variant="body1" mb={8}>
                {step === 1
                  ? "Enter your email to receive a password reset link."
                  : "Enter the verification code sent to your email and your new password."}
              </Typography>
              <form onSubmit={handleSubmit}>
                {step === 1 ? (
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <Typography variant="h6" sx={{ marginBottom: "10px" }}>
                      Email Address
                    </Typography>
                    <OutlinedInput placeholder="Enter your email address" value={email} onChange={(e) => setEmail(e.target.value)} required />
                  </FormControl>
                ) : (
                  <>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <Typography variant="h6" sx={{ marginBottom: "10px" }}>
                        Verification Code
                      </Typography>
                      <OutlinedInput
                        placeholder="Enter the verification code"
                        value={verificationCode}
                        onChange={(e) => setVerificationCode(e.target.value)}
                        required
                      />
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                      <Typography variant="h6" sx={{ marginBottom: "10px" }}>
                        New Password
                      </Typography>
                      <OutlinedInput
                        type="password"
                        placeholder="Enter your new password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                      />
                    </FormControl>
                  </>
                )}
                <PrimaryButton type="submit" fullWidth variant="contained" color="primary" sx={{ mt: 1 }} disabled={isLoading}>
                  {isLoading ? <CircularProgress size={24} color="inherit" /> : step === 1 ? "Send Code" : "Reset Password"}
                </PrimaryButton>
              </form>
            </>
          ) : (
            <>
              <Typography variant="h5" sx={{ mb: 2, textAlign: "center" }}>
                Password Reset Successful!
              </Typography>
              <Typography sx={{ mb: 2, textAlign: "center" }}>
                Your password has been successfully reset. You can now use your new password to log in.
              </Typography>
              <PrimaryButton
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => {
                  posthog?.capture("password_successfully_reset_go_to_login_button_clicked");
                  navigate("/login");
                }}
              >
                Go to Login
              </PrimaryButton>
            </>
          )}
          {message && (
            <Typography variant="body2" color="error" sx={{ marginTop: "10px" }}>
              {message}
            </Typography>
          )}
          <SecondaryButton onClick={() => navigate("/login")} sx={{ marginTop: "10px", width: "100%" }}>
            Back to Login
          </SecondaryButton>
        </Box>
      </Box>
    </Box>
  );
};

export default ForgotPasswordPage;
