import React, { useState } from "react";
import { Box, Typography, Checkbox, FormControlLabel, useMediaQuery, IconButton } from "@mui/material";
import styled from "styled-components";
import { DateTime } from "luxon";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";
import { PickersShortcutsItem } from "@mui/x-date-pickers/PickersShortcuts";
import { DateRange } from "@mui/x-date-pickers-pro/models";
import { PrimaryButton } from "../subcomponents/CustomButton";
import { RootState } from "../store";
import { Agent } from "../types";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";

const ModalBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 40%;
  margin: 3% auto;
  background-color: #fff;
  padding: 25px 5%;
  border-radius: 35px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);

  @media (max-width: 1250px) {
    width: 60%;
    padding: 20px 5%;
    margin: 5% auto;
  }

  @media (max-width: 480px) {
    width: 90%;
    padding: 20px 8%;
    margin: 3% auto;
  }
`;

const AgentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const shortcutsItems: PickersShortcutsItem<DateRange<DateTime>>[] = [
  {
    label: "This Week",
    getValue: () => {
      const today = DateTime.local();
      return [today.startOf("week"), today.endOf("week")];
    },
  },
  {
    label: "Last Week",
    getValue: () => {
      const today = DateTime.local();
      const prevWeek = today.minus({ weeks: 1 });
      return [prevWeek.startOf("week"), prevWeek.endOf("week")];
    },
  },
  {
    label: "Last 7 Days",
    getValue: () => {
      const today = DateTime.local();
      return [today.minus({ days: 7 }), today];
    },
  },
  {
    label: "Current Month",
    getValue: () => {
      const today = DateTime.local();
      return [today.startOf("month"), today.endOf("month")];
    },
  },
  {
    label: "Next Month",
    getValue: () => {
      const today = DateTime.local();
      const startOfNextMonth = today.endOf("month").plus({ days: 1 });
      return [startOfNextMonth, startOfNextMonth.endOf("month")];
    },
  },
  { label: "Reset", getValue: () => [null, null] },
];

type FilterFormProps = {
  agentsSelector: boolean;
  setFilter: (agents: string[], dates: [DateTime | null, DateTime | null]) => void;
  agentsFilter: string[] | undefined;
  datesFilter: [DateTime | null, DateTime | null] | undefined;
  onClose: () => void;
};

const FilterForm: React.FC<FilterFormProps> = ({ agentsSelector, setFilter, agentsFilter, datesFilter, onClose }) => {
  const agents: Agent[] = useSelector((state: RootState) => state.agents.agents);
  const [dateRange, setDateRange] = useState<[DateTime | null, DateTime | null]>(datesFilter || [null, null]);
  const isLargeScreen = useMediaQuery("(min-width:800px)");

  const [selectedAgents, setSelectedAgents] = useState<{ [key: string]: boolean }>(() => {
    const agentsObj: { [key: string]: boolean } = {};
    agents.forEach((agent) => {
      agentsObj[agent.agentId] = agentsFilter ? agentsFilter.includes(agent.agentId) : true;
    });
    return agentsObj;
  });

  const handleAgentChange = (agent: string) => {
    setSelectedAgents((prev) => ({
      ...prev,
      [agent]: !prev[agent],
    }));
  };

  const handleDateChange = (value: [DateTime | null, DateTime | null]) => {
    const [start, end] = value;
    setDateRange([start, end && end.plus({ days: 1 })]);
  };

  const handleApply = () => {
    const agentIds = Object.keys(selectedAgents).filter((agent) => selectedAgents[agent]);
    setFilter(agentIds, dateRange);
  };

  return (
    <Box 
      sx={{
        overflowY: 'auto',
        height: 'auto',
        paddingBottom: '60px',   
        paddingRight: '20px',  
      }}    
    >
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          padding: '12px 25px',
          width: '100%',
          display: 'flex',
          borderBottom: '2px solid Colors.textfield',
          background: '#151A33', 
          justifyContent: 'space-between',
          alignItems: 'center',
          zIndex: 1000,
        }}
      >
        <Box display={'flex'} gap={'10px'}>
          <Typography variant={'body2'} fontSize={'1.1rem'} fontWeight={'bold'}>Filter</Typography>
        </Box>
        <IconButton onClick={onClose} sx={{color: '#FFFFFF'}}>
          <CloseIcon />
        </IconButton>
      </Box>


        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <StaticDateRangePicker
            slotProps={{
              shortcuts: isLargeScreen ? { items: shortcutsItems } : undefined,
              actionBar: { actions: [] },
            }}
            calendars={1}
            onChange={handleDateChange}
            sx={{
              "@media (max-width: 768px)": {
                display: "flex",
                flexDirection: "column",
                marginLeft: "-20px",
              },
            }}
            value={dateRange}
          />
        </LocalizationProvider>

      {agentsSelector && (
        <>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline", gap: "5px" }}>
            <Typography variant="h6"> Agents </Typography>
            <Typography variant="subtitle2"> (select multiple) </Typography>
          </div>
          <AgentsContainer>
            {agents.map((agent) => (
              <FormControlLabel
                key={agent.agentId}
                control={
                  <Checkbox checked={selectedAgents[agent.agentId]} onChange={() => handleAgentChange(agent.agentId)} color="primary" />
                }
                label={agent.name}
              />
            ))}
          </AgentsContainer>
        </>
      )}

        <PrimaryButton variant="contained" onClick={handleApply}>
          Apply
        </PrimaryButton>
    </Box>
  );
};

export default FilterForm;
