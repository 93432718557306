import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { EHR, FrontendUser, HealthieAppointment, RawHealthiePatient, HealthieProvider } from "../types";
import { sessionExpired } from "./SessionSlice";
import { getSession } from "./AuthSlice";
import { RootState } from "../store";

export interface HealthieIntegrationState {
  providers: HealthieProvider[];
  appointments: HealthieAppointment[];
  patients: RawHealthiePatient[];
  error: string | null;
  loadingUploadingToken: boolean;
  loadingDeletingIntegration: boolean;
  loadingProviders: boolean;
  loadingAppointments: boolean;
  loadingPatients: boolean;
}

const initialState: HealthieIntegrationState = {
  providers: [],
  appointments: [],
  patients: [],
  error: null,
  loadingUploadingToken: false,
  loadingDeletingIntegration: false,
  loadingProviders: false,
  loadingAppointments: false,
  loadingPatients: false,
};

export const fetchProviders = createAsyncThunk<{ providers: HealthieProvider[] }, void, { rejectValue: Error }>(
  "integrations/fetchProviders",
  async (_, { getState, rejectWithValue }) => {
    try {
      const user: FrontendUser | null = (getState() as RootState).auth.user;

      if (!user || !user.token) {
        throw new Error("User not found");
      }

      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/integrations/healthie/providers`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      return { providers: response.data };
    } catch (error) {
      if ((error as AxiosError).response?.status === 401) {
        sessionExpired(true);
      }
      return rejectWithValue(new Error("Failed to fetch providers: " + (error as Error).message));
    }
  }
);

export const fetchAppointments = createAsyncThunk<{ appointments: HealthieAppointment[] }, { providerIds: string[] }, { rejectValue: Error }>(
  "integrations/fetchAppointments",
  async ({ providerIds }, { getState, rejectWithValue }) => {
    try {
      const user: FrontendUser | null = (getState() as RootState).auth.user;

      if (!user || !user.token) {
        throw new Error("User not found");
      }

      if (providerIds.length > 0) {
        const providerId = providerIds[0];
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/integrations/healthie/appointmentTypes?providerId=${providerId}`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
        return { appointments: response.data as HealthieAppointment[] };
      } else {
        return { appointments: [] };
      }
    } catch (error) {
      if ((error as AxiosError).response?.status === 401) {
        sessionExpired(true);
      }
      return rejectWithValue(new Error("Failed to fetch appointments: " + (error as Error).message));
    }
  }
);

export const fetchHealthiePatients = createAsyncThunk<{ patients: RawHealthiePatient[] }, void, { rejectValue: Error }>(
  "/integrations/fetchPatients",
  async (_, { getState, rejectWithValue }) => {
    try {
      const user = (getState() as RootState).auth.user;

      if (!user || !user.token) {
        throw new Error("User not found");
      }

      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/integrations/healthie/patients`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      return { patients: response.data };
    } catch (error) {
      if ((error as AxiosError).response?.status === 401) {
        sessionExpired(true);
      }
      return rejectWithValue(new Error("Failed to fetch patients: " + (error as Error).message));
    }
  }
);

export const handleTokenUpload = createAsyncThunk<void, { ehrToken: string; ehr: EHR }, { rejectValue: Error }>(
  "integrations/handleTokenUpload",
  async ({ ehrToken, ehr }, { dispatch, getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const user = state.auth.user;

      if (!ehrToken) {
        console.error("Token is empty");
        return;
      }

      if (!user?.token) {
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/integrations/${ehr}`,
        { ehrToken: ehrToken },
        { headers: { Authorization: `Bearer ${user?.token}` } }
      );

      if (response.status === 200) {
        dispatch(getSession());
      }
    } catch (error) {
      console.error(`Error updating ${ehr} token:`, error);
      return rejectWithValue(new Error(`Error updating ${ehr} token: ${error}`));
    }
  }
);

export const handleDeleteIntegration = createAsyncThunk<void, { ehr: EHR }, { rejectValue: Error }>(
  "integrations/handleDeleteIntegration",
  async ({ ehr }, { dispatch, getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const user = state.auth.user;

      if (!user?.token) {
        throw new Error("User not found");
      }

      const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/integrations/${ehr}`, {
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      });

      if (response.status === 200) {
        dispatch(clearIntegration());
        dispatch(getSession());
      }
    } catch (error) {
      console.error(`Error disconnecting from ${ehr}:`, error);
      return rejectWithValue(new Error(`Error disconnecting from ${ehr}: ${error}`));
    }
  }
);

const integrationsSlice = createSlice({
  name: "integrations",
  initialState,
  reducers: {
    clearIntegration: (state) => {
      state.providers = [];
      state.appointments = [];
      state.error = null;
      state.loadingProviders = false;
      state.loadingAppointments = false;
      state.loadingPatients = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProviders.pending, (state) => {
        state.loadingProviders = true;
      })
      .addCase(fetchProviders.fulfilled, (state, action) => {
        state.providers = action.payload.providers;
        state.loadingProviders = false;
      })
      .addCase(fetchProviders.rejected, (state, action) => {
        state.error = action.error.message || "Failed to fetch providers";
        state.loadingProviders = false;
      })
      .addCase(fetchAppointments.pending, (state) => {
        state.loadingAppointments = true;
      })
      .addCase(fetchAppointments.fulfilled, (state, action) => {
        state.appointments = action.payload.appointments;
        state.loadingAppointments = false;
      })
      .addCase(fetchAppointments.rejected, (state, action) => {
        state.error = action.error.message || "Failed to fetch appointments";
        state.loadingAppointments = false;
      })
      .addCase(fetchHealthiePatients.pending, (state) => {
        state.loadingPatients = true;
      })
      .addCase(fetchHealthiePatients.fulfilled, (state, action) => {
        state.patients = action.payload.patients;
        state.loadingPatients = false;
      })
      .addCase(fetchHealthiePatients.rejected, (state, action) => {
        state.error = action.error.message || "Failed to fetch patients";
        state.loadingPatients = false;
      })
      .addCase(handleTokenUpload.pending, (state) => {
        state.loadingUploadingToken = true;
      })
      .addCase(handleTokenUpload.fulfilled, (state) => {
        state.loadingUploadingToken = false;
      })
      .addCase(handleTokenUpload.rejected, (state, action) => {
        state.error = action.error.message || "Failed to upload token";
        state.loadingUploadingToken = false;
      })
      .addCase(handleDeleteIntegration.pending, (state) => {
        state.loadingDeletingIntegration = true;
      })
      .addCase(handleDeleteIntegration.fulfilled, (state) => {
        state.loadingDeletingIntegration = false;
      })
      .addCase(handleDeleteIntegration.rejected, (state, action) => {
        state.error = action.error.message || "Failed to delete integration";
        state.loadingDeletingIntegration = false;
      });
  },
});

export const { clearIntegration } = integrationsSlice.actions;

export default integrationsSlice.reducer;
