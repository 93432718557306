import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { AgentType, Agent, FrontendUser, EHR, WaitlistState, HealthieWaitlistPatient, WaitlistRun } from "../types";
import { Typography, Switch, Modal, Tooltip, Button, IconButton, Tabs, Tab, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SettingsIcon from "@mui/icons-material/Settings";
import SignoutHeader from "../subcomponents/SignoutHeader";
import ConfigureForm from "../forms/ConfigureForm";
import { PrimaryButton, SecondaryButton, TransparentButton } from "../subcomponents/CustomButton";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../store";
import { Colors } from "../Colors";
import { selectAgents, updateAgentThunk } from "../slices/AgentSlice";
import { useSnackbar } from "../providers/SnackbarProvider";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import ConversationQueue from "../components/ConversationPage";
import { usePostHog } from "posthog-js/react";
import styled from "styled-components";
import LoadingWithMessage from "../subcomponents/LoadingWithMessage";
import Loader from "../subcomponents/Loader";
import AddPatientModal from "../forms/AddPatientModal";
import { Add } from "@mui/icons-material";
import axios from "axios";
import { DateTime } from "luxon";
import { DateTimePicker } from "@mui/x-date-pickers";
import { ModalBox } from "../styles/GeneralStyles";
import { convertCamelCaseToOfficial, convertKebabCaseToOfficial, formatIsoToCustomDateStringWithEEEE } from "../utils/utils";
import { InfoTooltip } from "../subcomponents/InfoTooltip";
import { createRun, deleteRun, executeRun, fetchWaitlistRuns } from "../slices/WaitlistRunsSlice";
import { Sidebar } from "../styles/GeneralStyles";
import DeleteIcon from "@mui/icons-material/Delete";
import UpdatePatientModal from "../forms/UpdatePatientModal";
import PatientPriority from "../subcomponents/PatientPriority";
import AddIcon from "@mui/icons-material/Add";
import CircleIcon from "@mui/icons-material/Circle";
import CloseIcon from "@mui/icons-material/Close";
import { deletePatient, getWaitlistPatient, getWaitlistPatients, updatePriorities } from "../slices/PatientSlice";
import TimerIcon from "@mui/icons-material/Timer";
import CheckIcon from "@mui/icons-material/Check";
import EventNoteIcon from "@mui/icons-material/EventNote";

const StyledLine = styled(Box)`
  border-left: 1px solid #e0e0e0;
  height: 90px;
  align-self: center;

  @media (max-width: 750px) {
    height: auto;
    border-left: none;
    border-top: 1px solid #e0e0e0;
    width: 90%;
    margin: 15px 0;
  }
`;

type AddRunModalProps = {
  open: boolean;
  onClose: () => void;
};

const AddRunModal: React.FC<AddRunModalProps> = ({ open, onClose }) => {
  const user = useSelector((state: RootState) => state.auth.user);
  const { agentId } = useParams<{ agentId: string }>();
  const [localErrorMessage, setLocalErrorMessage] = React.useState<string>("");
  const [appointmentDate, setAppointmentDate] = React.useState<DateTime | null>(null);
  const [providerId, setProviderId] = React.useState<string>("");
  const [appointmentTypeId, setAppointmentTypeId] = React.useState<string>("");
  const [contactType, setContactType] = React.useState<string>("");
  const dispatch = useAppDispatch();

  const providers = useSelector((state: RootState) => state.healthieIntegration.providers);
  const appointmentTypes = useSelector((state: RootState) => state.healthieIntegration.appointments);
  const contactTypes =
    (appointmentTypeId && appointmentTypes.find((appointment) => appointment.id === appointmentTypeId)?.available_contact_types) || [];

  const handleCreateRun = async () => {
    const provider = providers.find((provider) => provider.id === providerId);
    const appointmentType = appointmentTypes.find((appointment) => appointment.id === appointmentTypeId);
    if (!provider || !appointmentType) {
      setLocalErrorMessage("Please select a provider and appointment type.");
      return;
    }
    await dispatch(
      createRun({
        params: {
          appointmentDate: appointmentDate?.toISO() ?? "",
          appointmentLength: 60, // TODO
          cutOffDate: appointmentDate?.plus({ days: 1 }).toISO(), // TODO
          ehrParams: {
            providerId: providerId,
            pcpName: provider.firstName + " " + provider.lastName,
            appointmentTypeId: appointmentTypeId,
            appointmentTypeName: appointmentType.name,
            appointmentTypeDuration: appointmentType.length,
            contactType: contactType,
          },
        },
      })
    );
  };

  // TODO (Hizami): Make this better
  return (
    <Modal open={open} onClose={onClose}>
      <ModalBox>
        <h1>Create Run</h1>
        <label>Select a time</label>
        <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between", mb: 2 }}>
          <DateTimePicker label="Appointment date" value={appointmentDate} onChange={setAppointmentDate} sx={{ flexGrow: 1 }} />
        </Box>
        <label>Select a provider</label>
        <select
          value={providerId}
          onChange={(e) => {
            setProviderId(e.target.value);
          }}
        >
          <option value="">Select a provider</option>
          {providers.map((provider) => (
            <option key={provider.id} value={provider.id}>
              {provider.firstName} {provider.lastName}
            </option>
          ))}
        </select>

        <label>Select an appointment type</label>
        <select
          value={appointmentTypeId}
          onChange={(e) => {
            setAppointmentTypeId(e.target.value);
          }}
        >
          <option value="">Select an appointment type</option>
          {appointmentTypes.map((appointment) => (
            <option key={appointment.id} value={appointment.id}>
              {appointment.name}
            </option>
          ))}
        </select>

        <label>Select a contact type</label>
        <select
          value={contactType}
          onChange={(e) => {
            setContactType(e.target.value);
          }}
        >
          <option value="">Select a contact type</option>
          {contactTypes.map((contact) => (
            <option key={contact} value={contact}>
              {contact}
            </option>
          ))}
        </select>

        <button onClick={handleCreateRun}>Add Run</button>
      </ModalBox>
    </Modal>
  );
};

const WaitlistRunRow: React.FC<{ run: WaitlistRun; executeRun: any; deleteRun: any }> = ({ run, executeRun, deleteRun }) => {
  const newestRunIds = useSelector((state: RootState) => state.waitlistRuns.newestRunIds);

  // TODO (Hizami): Use the actual enum values
  const { agentId } = useParams<{ agentId: string }>();
  const navigate = useNavigate();

  const runDateTime = DateTime.fromISO(run.appointmentDate);
  const runDay = runDateTime.toFormat("EEEE");
  const runDate = runDateTime.toFormat("MMMM d");
  const runTime = runDateTime.toFormat("h:mm a");

  const stateColor =
    run.state === "accepted"
      ? Colors.success
      : run.state === "declined" || run.state === "no-valid-patients" || run.state === "booking-failed" || run.state === "expired"
      ? Colors.error
      : Colors.grey1;
  let backgroundColor = "white";
  if (newestRunIds.includes(run.waitlistRunId)) {
    if (run.state === "accepted") {
      backgroundColor = "#99DCB2"; // TODO: Hard-coded
    } else if (run.state === "declined") {
      backgroundColor = "#FABBBB"; // TODO: Hard-coded
    } else if (run.state === "ongoing") {
      backgroundColor = "#EAEAEA"; // TODO: Hard-coded
    }
  }

  const states = {
    [WaitlistState.accepted]: "Booked",
    [WaitlistState.declined]: "Declined",
    [WaitlistState.ongoing]: "Ongoing...",
    [WaitlistState.notStarted]: "Not started",

    [WaitlistState.noValidPatients]: "No valid patients",
    [WaitlistState.bookingFailed]: "Booking failed",
    [WaitlistState.expired]: "Expired",
  };

  const status = states[run.state as WaitlistState];

  return (
    <Box
      onClick={() => navigate(`/dashboard/agents/${agentId}/waitlist-runs/${run.waitlistRunId}`)}
      key={run.waitlistRunId}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: "20px",
        backgroundColor: Colors.textfield,
        padding: { xs: "10px 15px", sm: "20px" },
        borderRadius: "25px",
        alignItems: "start",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "rgba(255, 255, 255, 0.08)",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Typography fontSize={"0.85rem"}>{runDay}</Typography>
        <Typography variant="h6" fontSize={"0.9rem"}>
          {runDate}, {runTime}
        </Typography>
        <Typography variant="body1" fontSize={"0.9rem"}>
          with {run.pcpName}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
        }}
      >
        <Box
          sx={{
            border: `1px solid rgba(${parseInt(stateColor.slice(1, 3), 16)}, ${parseInt(stateColor.slice(3, 5), 16)}, ${parseInt(
              stateColor.slice(5, 7),
              16
            )}, 0.08)`,
            borderRadius: "15px",
            padding: "4px 10px",
            background: `rgba(${parseInt(stateColor.slice(1, 3), 16)}, ${parseInt(stateColor.slice(3, 5), 16)}, ${parseInt(
              stateColor.slice(5, 7),
              16
            )}, 0.1)`,
            width: "fit-content",
          }}
        >
          <Tooltip title={run.stateJustification} arrow>
            <Typography variant="body2" color={stateColor}>
              {status}
            </Typography>
          </Tooltip>
        </Box>
        {run.bookedBy && (
          <React.Fragment>
            <Typography variant="body1" fontSize={"0.9rem"} mt={1}>
              Booked by:
            </Typography>
            <Typography variant="body1" fontSize={"0.9rem"} style={{ fontWeight: "bold" }}>
              {run.bookedBy.firstName} {run.bookedBy.lastName}
            </Typography>
          </React.Fragment>
        )}
        {run.state == "not-started" && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              executeRun(run.waitlistRunId);
            }}
          >
            Execute Run
          </button>
        )}
      </Box>

      {true && (
        <button
          onClick={(e) => {
            e.stopPropagation();
            deleteRun(run.waitlistRunId);
          }}
        >
          Delete Run
        </button>
      )}
    </Box>
  );
};

const PatientCard: React.FC<{ patient: HealthieWaitlistPatient; updatePatient: (id: string) => void }> = ({ patient, updatePatient }) => (
  <Box
    sx={{
      backgroundColor: "rgba(256, 256, 256, 0.04)",
      borderRadius: "15px",
      padding: "12px",
      margin: "20px 0",
      position: "relative",
      "&:hover .close-icon": {
        visibility: "visible",
      },
    }}
  >
    <Typography variant="h6" fontWeight="bold" fontSize="1rem">
      {patient.firstName} {patient.lastName}
    </Typography>
    <Box color={Colors.info} display="flex" alignItems="center" gap="10px">
      <Typography variant="body2">{patient.phoneNumber}</Typography>
      <CircleIcon sx={{ fontSize: "5px" }} />
      <Typography variant="body2">{`ID: ${patient.healthiePatientId}`}</Typography>
    </Box>
    <Typography variant="body2" fontWeight="bold" mt={2}>
      Availability:
    </Typography>
    <Typography variant="body2" color={Colors.info}>
      {patient.availableDates.map((date) => formatIsoToCustomDateStringWithEEEE(date)).join(", ")}
    </Typography>
    {patient.availableDates.length === 0 && (
      <Typography variant="body2" color={Colors.info}>
        No available dates
      </Typography>
    )}
    <Typography
      onClick={() => updatePatient(patient.patientId)}
      color="primary"
      fontWeight="bold"
      sx={{ fontSize: "0.8rem", marginTop: "10px", cursor: "pointer" }}
    >
      Update Patient
    </Typography>
  </Box>
);

const WaitlistAgentDisplay: React.FC<{ agent: Agent }> = ({ agent }) => {
  const dispatch = useAppDispatch();
  const user = useSelector((state: RootState) => state.auth.user);
  const waitlistRuns = useSelector((state: RootState) => state.waitlistRuns.waitlistRuns);
  const loading = useSelector((state: RootState) => state.waitlistRuns.fetchingRunsLoading);

  const [addPatientModalOpen, setAddPatientModalOpen] = React.useState<boolean>(false);
  const [runModalOpen, setRunModalOpen] = React.useState(false);
  const [patientPriorityModalOpen, setPatientPriorityModalOpen] = React.useState(false);
  const patients = useSelector((state: RootState) => state.patients.patients);
  const [updateOpen, setUpdateOpen] = React.useState(false);
  const [selectedPatient, setSelectedPatient] = React.useState<any | undefined>(undefined);

  useEffect(() => {
    dispatch(fetchWaitlistRuns({ agentId: agent.agentId }));
    dispatch(getWaitlistPatients());
  }, []);

  const onRunClose = () => {
    setRunModalOpen(false);
  };

  const onPatientPriorityClose = async (patients: any) => {
    setPatientPriorityModalOpen(false);
    await updatePatientPriorities(patients);
    await dispatch(getWaitlistPatients());
  };

  const updatePatientPriorities = async (patients: any) => {
    const priorities = patients.map((patient: any, index: number) => {
      return { patientId: patient.patientId, priority: index + 1 };
    });
    await dispatch(updatePriorities({ patients: priorities }));
  };

  const updatePatient = async (patientId: string) => {
    setSelectedPatient(patients.find((patient: any) => patient.patientId === patientId));
    setUpdateOpen(true);
  };

  const successfulRuns = waitlistRuns.filter((run) => run.state === WaitlistState.accepted);
  const failedRuns = waitlistRuns.filter((run) =>
    [WaitlistState.declined, WaitlistState.noValidPatients, WaitlistState.bookingFailed, WaitlistState.expired].includes(run.state)
  );
  const ongoingRuns = waitlistRuns.filter((run) => run.state === WaitlistState.ongoing);
  const notStartedRuns = waitlistRuns.filter((run) => run.state === WaitlistState.notStarted);

  const [selectedTab, setSelectedTab] = React.useState(0);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const renderTabContent = (type: "outcomes" | "queue" | "patients") => {
    if (type === "outcomes") {
      return (
        <Box sx={tabBoxStyles}>
          <Typography variant="h6" sx={{ marginBottom: "15px" }}>
            Waitlist Run Outcomes
          </Typography>

          {successfulRuns.concat(failedRuns).length > 0 ? (
            successfulRuns
              .concat(failedRuns)
              .map((run: any) => (
                <WaitlistRunRow
                  key={run.waitlistRunId}
                  run={run}
                  executeRun={() => dispatch(executeRun({ runId: run.waitlistRunId }))}
                  deleteRun={() => dispatch(deleteRun({ runId: run.waitlistRunId }))}
                />
              ))
          ) : (
            <Typography variant="body1" sx={{ textAlign: "center", color: Colors.info }}>
              No waitlist runs completed yet
            </Typography>
          )}
        </Box>
      );
    } else if (type === "queue") {
      return (
        <Box sx={tabBoxStyles}>
          <Typography variant="h6" sx={{ marginBottom: "15px" }}>
            Waitlist Run Queue
          </Typography>

          {ongoingRuns.concat(notStartedRuns).length > 0 ? (
            ongoingRuns
              .concat(notStartedRuns)
              .map((run: any) => (
                <WaitlistRunRow
                  key={run.waitlistRunId}
                  run={run}
                  executeRun={() => dispatch(executeRun({ runId: run.waitlistRunId }))}
                  deleteRun={() => dispatch(deleteRun({ runId: run.waitlistRunId }))}
                />
              ))
          ) : (
            <Typography variant="body1" sx={{ textAlign: "center", color: Colors.info }}>
              No waitlist runs yet
            </Typography>
          )}
        </Box>
      );
    } else if (type === "patients") {
      return (
        <Box sx={tabBoxStyles}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6">Patients</Typography>
              <AddIcon onClick={() => setAddPatientModalOpen(true)} style={{ color: Colors.primary, cursor: "pointer", marginLeft: "5px" }} />
            </Box>
            <PrimaryButton onClick={() => setPatientPriorityModalOpen(true)} style={{ padding: "2px 10px", fontSize: "0.9rem" }}>
              Set Priority
            </PrimaryButton>
          </Box>
          {patients.map((patient: HealthieWaitlistPatient) => (
            <PatientCard key={patient.patientId} patient={patient} updatePatient={updatePatient} />
          ))}
        </Box>
      );
    }
    return null;
  };

  return (
    <Box sx={{ width: "100%", color: "#FFF" }}>
      {isSmallScreen ? (
        <>
          <Tabs value={selectedTab} onChange={(e, newValue) => setSelectedTab(newValue)} style={{ marginBottom: "10px" }} centered>
            <Tab label="Outcomes" style={{ textTransform: "none", color: "#FFF" }} />
            <Tab label="Queue" style={{ textTransform: "none", color: "#FFF" }} />
            <Tab label="Patients" style={{ textTransform: "none", color: "#FFF" }} />
          </Tabs>
          {renderTabContent(selectedTab === 0 ? "outcomes" : selectedTab === 1 ? "queue" : "patients")}
        </>
      ) : (
        <Box sx={{ display: "flex", gap: "20px" }}>
          {renderTabContent("outcomes")}
          {renderTabContent("queue")}
          {renderTabContent("patients")}
        </Box>
      )}

      <Modal open={addPatientModalOpen} onClose={() => setAddPatientModalOpen(false)}>
        <Sidebar className={addPatientModalOpen ? "open" : ""}>
          <AddPatientModal
            onClick={() => {}}
            onClose={() => {
              setAddPatientModalOpen(false);
            }}
          />
        </Sidebar>
      </Modal>

      {/* <PrimaryButton onClick={() => setRunModalOpen(true)}>Add Run</PrimaryButton> */}

      {selectedPatient && (
        <Sidebar className={updateOpen ? "open" : ""}>
          <UpdatePatientModal key={selectedPatient.patientId} open={updateOpen} onClose={() => setUpdateOpen(false)} patient={selectedPatient} />
        </Sidebar>
      )}

      <Sidebar className={patientPriorityModalOpen ? "open" : ""}>
        <PatientPriority key={patients.length} open={patientPriorityModalOpen} onClose={onPatientPriorityClose} sortedPatients={patients} />
      </Sidebar>

      <AddRunModal open={runModalOpen} onClose={onRunClose} />
    </Box>
  );
};

// Extract common styles for each tab box
const tabBoxStyles = {
  backgroundColor: Colors.bg2,
  borderRadius: "15px",
  padding: "20px",
  width: "100%",
  height: "100%",
};

const SchedulingAgentDisplay: React.FC = () => {
  return <ConversationQueue />;
};

const AgentInfoPage: React.FC = () => {
  const { agentId } = useParams<{ agentId: string }>();
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const agent: Agent | null = useSelector(selectAgents).find((agent) => agent.agentId === agentId) || null;
  const loading = useSelector((state: RootState) => state.agents.loading);

  const dispatch = useAppDispatch();

  const { bookedApptVolume, bookedApptRate, avgTimeToFillAppt } = agent || {};
  const posthog = usePostHog();

  const navigate = useNavigate();
  const { showMessage } = useSnackbar();

  const [isActive, setIsActive] = React.useState(agent?.isActive || false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const handleToggle = () => {
    if (!user?.token) {
      showMessage("Session is expired. Please refresh.", "error");
      return;
    }
    dispatch(updateAgentThunk({ agent: { agentId: agentId, isActive: !isActive } }));
    setIsActive(!isActive);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleViewAgentConvo = () => {
    navigate(`/dashboard/agents/${agentId}/agent-conversation-history`);
  };

  const handleBack = () => {
    posthog?.capture("Agent Info Page - Back");
    navigate(`/dashboard/agents`);
  };

  useEffect(() => {
    if (!loading && !agent) {
      navigate("/dashboard/agents");
    }
  }, [loading, agent]);

  return agent ? (
    <Box sx={{ margin: { xs: "70px 0", lg: "0" } }}>
      <SignoutHeader />

      <SecondaryButton onClick={handleBack} sx={{ display: { xs: "none", lg: "flex" }, alignItems: "center", margin: "20px 0" }}>
        <ArrowBackIcon sx={{ marginRight: "10px" }} /> Back
      </SecondaryButton>
      <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: { sm: "75px", lg: "0" } }}>
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: { xs: "5px", sm: "15px" } }}>
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "left", gap: 1 }}>
            <Typography variant="h4" sx={{ fontSize: { xs: "1.5rem", sm: "2.25rem" }, color: "#FFF" }}>
              {agent.name}
            </Typography>
          </Box>
          <Switch
            checked={isActive}
            onChange={handleToggle}
            sx={{
              "& .MuiSwitch-switchBase": {
                color: "#FFFFFF",
                "& + .MuiSwitch-track": {
                  backgroundColor: "#CCCCCC",
                },
              },
              "& .MuiSwitch-switchBase.Mui-checked": {
                color: "#FFFFFF",
                "& + .MuiSwitch-track": {
                  backgroundColor: "#00AC4F",
                },
              },
            }}
          />
          <Box sx={{ display: { xs: "none", sm: "block" }, color: "#FFF" }}>
            Agent is <span style={{ fontWeight: "bold" }}>{isActive ? "on" : "off"}</span>
          </Box>
        </Box>
        <SecondaryButton onClick={handleOpenModal} sx={{ padding: { xs: "0", sm: "10px 20px" } }}>
          <SettingsIcon sx={{ marginRight: { xs: "0", sm: "7px" } }} />
          <Typography variant="h6" sx={{ fontSize: { xs: "0.8rem", sm: "1rem" }, display: { xs: "none", sm: "block" } }}>
            {" "}
            Configure{" "}
          </Typography>
        </SecondaryButton>
      </Box>

      <Box sx={{ justifyContent: "space-between", alignItems: "center" }}>
        <Typography
          variant="h6"
          sx={{
            fontSize: { xs: "1rem", sm: "1.4rem" },
            lineHeight: { xs: "1", sm: "1.5" },
          }}
        >
          <span style={{ color: "grey", fontSize: "1.3rem" }}>{agent.description}</span>
        </Typography>
      </Box>

      <Typography
        onClick={handleViewAgentConvo}
        variant="body1"
        textAlign="right"
        sx={{ marginTop: "10px", color: Colors.primary, fontSize: { xs: "0.9rem", sm: "1rem" }, cursor: "pointer" }}
      >
        View conversation history
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "space-evenly" },
          flexDirection: { xs: "column", sm: "row" },
          borderRadius: "20px",
          margin: { xs: "10px 0px 25px 0px", md: "10px 10px 25px 10px" },
          padding: { xs: "20px 0", sm: "20px", md: "20px 0" },
          background: Colors.bg2,
          boxShadow: { xs: "0 4px 6px rgba(0, 0, 0, 0.15)", sm: "none" },
        }}
      >
        <Box sx={{ display: "flex", gap: { xs: "15%", sm: "7px", lg: "15px" }, alignItems: "center", marginLeft: { xs: "10%", sm: "0" } }}>
          <Box>
            <EventAvailableIcon sx={{ fontSize: "5rem", color: "#00AC4F", background: "#D3FFE7", padding: "20px", borderRadius: "50%" }} />
          </Box>
          <Box sx={{ lineHeight: "0" }}>
            <Typography variant="body1" sx={{ color: Colors.grey3, fontSize: { xs: "0.9rem", lg: "1rem" } }}>
              Booked appointments
            </Typography>
            <Typography variant="h6" sx={{ fontSize: "2.25rem", lineHeight: "1.2", color: "#FFF" }}>
              {bookedApptVolume}
            </Typography>
          </Box>
        </Box>
        <StyledLine />
        <Box sx={{ display: "flex", gap: { xs: "15%", sm: "7px", lg: "15px" }, alignItems: "center", marginLeft: { xs: "10%", sm: "0" } }}>
          <CheckIcon sx={{ fontSize: "5rem", color: "#0F5FC2", background: "#CAF1FF", padding: "20px", borderRadius: "50%" }} />
          <Box sx={{ lineHeight: "0" }}>
            <Typography variant="body1" sx={{ color: Colors.grey3, fontSize: { xs: "0.9rem", lg: "1rem" } }}>
              Booking Rate
            </Typography>
            <Typography variant="h6" sx={{ fontSize: "2.25rem", lineHeight: "1.2", color: "#FFF" }}>
              {bookedApptRate !== undefined ? (bookedApptRate * 100).toFixed(2) : "0.00"}%
            </Typography>
          </Box>
        </Box>
        <StyledLine />
        <Box sx={{ display: "flex", gap: { xs: "15%", sm: "7px", lg: "15px" }, alignItems: "center", marginLeft: { xs: "10%", sm: "0" } }}>
          <TimerIcon sx={{ fontSize: "5rem", color: "#FFA500", background: "#FFEFD5", padding: "20px", borderRadius: "50%" }} />
          <Box sx={{ lineHeight: "0" }}>
            <Typography variant="body1" sx={{ color: Colors.grey3, fontSize: { xs: "0.9rem", lg: "1rem" } }}>
              Average Time to Fill
            </Typography>
            <Typography variant="h6" sx={{ fontSize: "2.25rem", lineHeight: "1.2", color: "#FFF" }}>
              {avgTimeToFillAppt?.toFixed(2)} min
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        margin={0}
        sx={{
          width: "100%",
          "& > *": {
            margin: 0,
            flex: "1 1 0",
            borderRadius: "20px",
            padding: "10px",
          },
        }}
      >
        {agent.agentType !== AgentType.waitlist && agent.agentType !== AgentType.inbound && <SchedulingAgentDisplay />}
        {agent.agentType === AgentType.waitlist && <WaitlistAgentDisplay agent={agent} />}
      </Box>

      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Sidebar className={isModalOpen ? "open" : ""}>
          <ConfigureForm agent={agent} isModalOpen={isModalOpen} onClose={handleCloseModal} />
        </Sidebar>
      </Modal>
    </Box>
  ) : (
    <Box>
      <SignoutHeader />
      {loading ? (
        <Loader message="" size={60} customStyles={{ height: "100%", marginTop: "30vh" }} />
      ) : (
        <Typography variant="h2" textAlign="center">
          No agent found
        </Typography>
      )}
    </Box>
  );
};

export default AgentInfoPage;
