import React, { useState } from "react";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Autocomplete,
  TextField,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Typography,
  Button,
  Paper,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../store";
import { DateTime } from "luxon";
import { formatIsoToCustomDateStringWithEEEE } from "../utils/utils";
import { HealthieAppointment, RawHealthiePatient } from "../types";
import { HelpTooltip } from "../subcomponents/HelpTooltip";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import CircleIcon from "@mui/icons-material/Circle";
import Healthie from "../assets/HealthieWhiteLogo.png";
import CloseIcon from "@mui/icons-material/Close";
import { Colors } from "../Colors";
import { createPatient as createPatientThunk } from "../slices/PatientSlice";
import { dropdownStyles, menuPropsStyles } from "../styles/GeneralStyles";
import { HeaderBox, FooterBox } from "../subcomponents/SidebarComponents";
import CustomDatePicker from "../components/date-picker/DatePicker";

//const timezones = moment.tz.names();
const timezones = ["America/New_York", "America/Chicago", "America/Denver", "America/Los_Angeles"];

const timezoneOptions = timezones.map((tz) => {
  const dt = DateTime.now().setZone(tz);
  const offsetString = dt.toFormat("ZZ");
  const text = `${tz} (UTC${offsetString})`;

  return { value: tz, label: text, offset: dt.offset };
});

timezoneOptions.sort((a, b) => a.offset - b.offset);

const contactOptions = [
  { value: "call", label: "Call" },
  { value: "text", label: "Text" },
];

const languageOptions = [
  { label: "English", value: "en" },
  /*
  { label: "Spanish", value: "es" },
  { label: "French", value: "fr" },
  { label: "German", value: "de" },
  { label: "Chinese", value: "zh" },
   */
  // TODO (Hizami): Figure out what languages we want
];

// type AddPatientModalProps = {
//   open: boolean;
//   onClose: () => void;
// };

interface AddPatientModalProps {
  onClick: () => void;
  onClose: () => void;
}

const AddPatientModal: React.FC<AddPatientModalProps> = ({ onClick, onClose }) => {
  const user = useSelector((state: RootState) => state.auth.user);
  const healthiePatients = useSelector((state: RootState) => state.healthieIntegration.patients);
  const { agentId } = useParams<{ agentId: string }>();
  const [timezone, setTimezone] = React.useState<string>(DateTime.local().zoneName);
  const [language, setLanguage] = React.useState<string>("en"); // TODO (Hizami): This is hard coded because we aren't supporting multiple languages right now
  const [availableDates, setAvailableDates] = React.useState<Date[]>([]);
  const [selectedHealthiePatient, setSelectedHealthiePatient] = React.useState<RawHealthiePatient | undefined>(undefined);
  const [selectedProviders, setSelectedProviders] = React.useState<string[]>([]);
  const [selectedAppointmentTypes, setSelectedAppointmentTypes] = React.useState<string[]>([]);
  const [selectedContactTypes, setSelectedContactTypes] = React.useState<string[]>([]);
  const [textEnabled, setTextEnabled] = React.useState<boolean>(false);
  const [callEnabled, setCallEnabled] = React.useState<boolean>(false);
  const [showPatientDetails, setShowPatientDetails] = useState(false);
  const [loading, setLoading] = useState(false);

  const providers = useSelector((state: RootState) => state.healthieIntegration.providers);
  const appointments = useSelector((state: RootState) => state.healthieIntegration.appointments);
  const contactTypes = ["In Person", "Healthie Video Call", "Phone Call"]; // TODO (Hizami): This is hard-coded from healthie

  const [contactMode, setContactMode] = React.useState<"text" | "call" | "both" | "">("");

  const dispatch = useAppDispatch();

  const handleContactModeChange = (mode: "text" | "call" | "both") => {
    setContactMode(mode);
    setTextEnabled(mode === "text" || mode === "both");
    setCallEnabled(mode === "call" || mode === "both");
  };

  const createPatient = async () => {
    setLoading(true);
    try {
      if (!selectedHealthiePatient) {
        return;
      }
      await dispatch(
        createPatientThunk({
          selectedHealthiePatient,
          timezone,
          language,
          textEnabled,
          callEnabled,
          availableDates: availableDates.map((date) => date.toISOString()),
          selectedProviders,
          selectedAppointmentTypes,
          selectedContactTypes,
        })
      );

      onClick();
      onClose();
      setSelectedHealthiePatient(undefined);
      setAvailableDates([]);
    } catch (error) {
      // Handle any errors from the thunk if needed
      console.error("Error creating patient:", error);
    }
    setLoading(false);
  };

  const providerOptions = providers.map((provider) => ({ value: provider.id, label: provider.firstName + " " + provider.lastName }));
  const appointmentOptions = appointments.map((appointment) => ({ value: appointment.id, label: appointment.name }));
  const contactTypeOptions = contactTypes.map((contactType) => ({ value: contactType, label: contactType }));

  const healthiePatientOptions = healthiePatients.map((patient: RawHealthiePatient) => ({
    value: patient.healthiePatientId,
    label: `${patient.firstName} ${patient.lastName} (ID: ${patient.healthiePatientId})`,
  }));

  const handleHealthiePatientSelection = (healthieId: string) => {
    if (healthieId) {
      const healthiePatient = healthiePatients.find((patient) => patient.healthiePatientId === healthieId);
      setSelectedHealthiePatient(healthiePatient);
      setShowPatientDetails(true);
      if (healthiePatient?.timezone) {
        setTimezone(healthiePatient?.timezone);
      } else {
        setTimezone(DateTime.local().zoneName);
      }
      /*
      TODO (Hizami): Get language code from Healthie
      if (healthiePatient?.preferredLanguageCode) {
        setLanguage(healthiePatient.preferredLanguageCode);
      }
      */
    } else {
      setSelectedHealthiePatient(undefined);
      setShowPatientDetails(false);
    }
  };

  const handleCloseDetails = () => {
    setSelectedHealthiePatient(undefined);
    setShowPatientDetails(false);
  };

  const handleSelectAllProviders = () => setSelectedProviders(providers.map((provider) => provider.id));
  const handleDeselectAllProviders = () => setSelectedProviders([]);
  const handleSelectAllAppointmentTypes = () => setSelectedAppointmentTypes(appointments.map((appointment) => appointment.id));
  const handleDeselectAllAppointmentTypes = () => setSelectedAppointmentTypes([]);
  const handleSelectAllContactTypes = () => setSelectedContactTypes(contactTypes);
  const handleDeselectAllContactTypes = () => setSelectedContactTypes([]);

  const handleRemoveDate = (index: number) => {
    setAvailableDates((prevDates) => prevDates.filter((_, idx) => idx !== index));
  };

  return (
    <Box
      sx={{
        overflowY: "auto",
        height: "100%",
        paddingBottom: "60px",
        paddingRight: "20px",
      }}
    >
      <HeaderBox title="Add Patient" onClose={onClose} />

      <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} marginTop={"50px"}>
        <Typography margin={"10px 0 10px 0"} fontWeight={"bold"} fontSize={"1.2rem"}>
          Import from EHR
        </Typography>
        <img src={Healthie} alt="Healthie" style={{ width: "85px", height: "auto", marginTop: "15px", borderRadius: "0" }} />
      </Box>

      <Typography variant="body1">Patient</Typography>
      {showPatientDetails ? (
        <>
          {selectedHealthiePatient && (
            <Box
              sx={{
                padding: "10px",
                background: Colors.textfield,
                borderRadius: "10px",
                color: "#FFFFFF",
                marginTop: "5px",
                position: "relative",
                "&:hover .close-icon": {
                  visibility: "visible",
                },
              }}
            >
              <Typography variant="body1" fontWeight={"bold"} fontSize={"1rem"}>
                {`${selectedHealthiePatient?.firstName ?? ""} ${selectedHealthiePatient?.lastName ?? ""}`}
              </Typography>
              <Box
                sx={{
                  color: Colors.info,
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  alignItems: { xs: "left", sm: "center" },
                  gap: "10px",
                }}
                mt={0.5}
              >
                <Typography variant="body2">{`${selectedHealthiePatient?.phoneNumber ?? ""}`}</Typography>
                <CircleIcon sx={{ fontSize: "5px", display: { xs: "none", sm: "block" } }} />
                <Typography variant="body2">{`ID: ${selectedHealthiePatient?.healthiePatientId ?? ""}`}</Typography>
                <CircleIcon sx={{ fontSize: "5px", display: { xs: "none", sm: "block" } }} />
                <Typography variant="body2">{`${timezone}`}</Typography>
              </Box>

              <IconButton
                className="close-icon"
                sx={{
                  position: "absolute",
                  top: "5px",
                  right: "5px",
                  color: "#FFF",
                  visibility: "hidden",
                  padding: "5px",
                  zIndex: 1,
                }}
                onClick={handleCloseDetails}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          )}
        </>
      ) : (
        <Autocomplete
          options={healthiePatientOptions}
          getOptionLabel={(option) => `${option.label.split(" (ID:")[0]}   (ID: ${option.value})`}
          renderOption={(props, option) => (
            <li
              {...props}
              style={{
                display: "flex",
                justifyContent: "space-between",
                background: Colors.bg3,
                color: "#FFF",
                padding: "8px 16px",
              }}
            >
              <span>{option.label.split(" (ID:")[0]}</span>
              <span style={{ color: Colors.info }}>{`ID: ${option.value}`}</span>
            </li>
          )}
          renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Select patient" />}
          value={healthiePatientOptions.find((option) => option.value === selectedHealthiePatient?.healthiePatientId) || null}
          onChange={(e, value) => handleHealthiePatientSelection(value?.value || "")}
          fullWidth
          PaperComponent={(props) => (
            <Paper
              {...props}
              sx={{
                backgroundColor: Colors.bg3,
                color: "#FFF",
                margin: 0,
                borderRadius: "15px",
              }}
            />
          )}
          ListboxComponent={(props) => (
            <ul
              {...props}
              style={{
                padding: 0,
                margin: 0,
                background: Colors.bg3,
              }}
            />
          )}
          sx={{
            borderRadius: "10px",
            background: Colors.textfield,
            marginTop: "5px",
            "& .MuiInputBase-input": {
              padding: "0px",
              fontSize: "0.9rem",
              color: "#FFFFFF",
            },
            "& .MuiSvgIcon-root": {
              color: "#FFF",
            },
            "& .MuiAutocomplete-paper": {
              backgroundColor: Colors.bg3,
              margin: 0,
            },
            "& .MuiAutocomplete-listbox": {
              backgroundColor: Colors.bg3,
            },
          }}
        />
      )}

      <Typography variant="body1" mt={3} mb={1}>
        AI agent mode of contact
      </Typography>

      <FormControl variant="outlined" fullWidth>
        <Box display="flex" gap="10px">
          <Button
            onClick={() => handleContactModeChange("call")}
            variant="outlined"
            sx={{
              borderRadius: "10px",
              textTransform: "none",
              borderColor: contactMode === "call" ? Colors.primary : "rgba(255, 255, 255, 0.2)",
              backgroundColor: "transparent",
              color: contactMode === "call" ? "#fff" : Colors.info,
            }}
          >
            Call only
          </Button>

          <Button
            onClick={() => handleContactModeChange("text")}
            variant="outlined"
            sx={{
              borderRadius: "10px",
              textTransform: "none",
              borderColor: contactMode === "text" ? Colors.primary : "rgba(255, 255, 255, 0.2)",
              backgroundColor: "transparent",
              color: contactMode === "text" ? "#fff" : Colors.info,
            }}
          >
            Text only
          </Button>

          <Button
            onClick={() => handleContactModeChange("both")}
            variant="outlined"
            sx={{
              borderRadius: "10px",
              textTransform: "none",
              borderColor: contactMode === "both" ? Colors.primary : "rgba(255, 255, 255, 0.2)",
              backgroundColor: "transparent",
              color: contactMode === "both" ? "#fff" : Colors.info,
            }}
          >
            Both
          </Button>
        </Box>
      </FormControl>

      <Typography variant="body1" fontWeight={"bold"} mt={3}>
        Waitlist Parameters <HelpTooltip title={"The patient will automatically be put on waitlists for appointments fitting these criteria."} />
      </Typography>

      <FormControl fullWidth>
        <Box display="flex" justifyContent="space-between" alignItems="center" gap="10px">
          <Typography variant="body1">Providers</Typography>
          {selectedProviders.length === providerOptions.length ? (
            <Button sx={{ textTransform: "none" }} onClick={handleDeselectAllProviders}>
              Deselect All
            </Button>
          ) : (
            <Button sx={{ textTransform: "none" }} onClick={handleSelectAllProviders}>
              Select All
            </Button>
          )}
        </Box>

        <Select
          multiple
          displayEmpty
          value={selectedProviders}
          onChange={(e) => setSelectedProviders(e.target.value as string[])}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <Typography sx={{ color: Colors.info }}>Select options</Typography>;
            }
            return (selected as string[]).map((value) => providerOptions.find((option) => option.value === value)?.label).join(", ");
          }}
          sx={dropdownStyles}
          MenuProps={menuPropsStyles}
        >
          {providerOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <Checkbox checked={selectedProviders.indexOf(option.value) > -1} sx={{ color: "#FFF" }} />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth>
        <Box display="flex" justifyContent="space-between" alignItems="center" gap="10px" mt={2}>
          <Typography variant="body1">Appointment Types</Typography>
          {selectedAppointmentTypes.length === appointmentOptions.length ? (
            <Button sx={{ textTransform: "none" }} onClick={handleDeselectAllAppointmentTypes}>
              Deselect All
            </Button>
          ) : (
            <Button sx={{ textTransform: "none" }} onClick={handleSelectAllAppointmentTypes}>
              Select All
            </Button>
          )}
        </Box>
        <Select
          multiple
          displayEmpty
          value={selectedAppointmentTypes}
          onChange={(e) => setSelectedAppointmentTypes(e.target.value as string[])}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <Typography sx={{ color: Colors.info }}>Select options</Typography>;
            }
            return (selected as string[]).map((value) => appointmentOptions.find((option) => option.value === value)?.label).join(", ");
          }}
          sx={dropdownStyles}
          MenuProps={menuPropsStyles}
        >
          {appointmentOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <Checkbox checked={selectedAppointmentTypes.indexOf(option.value) > -1} />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth>
        <Box display="flex" justifyContent="space-between" alignItems="center" gap="10px" mt={2}>
          <Typography variant="body1">Contact Types</Typography>
          {selectedContactTypes.length === contactTypeOptions.length ? (
            <Button sx={{ textTransform: "none" }} onClick={handleDeselectAllContactTypes}>
              Deselect All
            </Button>
          ) : (
            <Button sx={{ textTransform: "none" }} onClick={handleSelectAllContactTypes}>
              Select All
            </Button>
          )}
        </Box>

        <Select
          multiple
          displayEmpty
          value={selectedContactTypes}
          onChange={(e) => setSelectedContactTypes(e.target.value as string[])}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <Typography sx={{ color: Colors.info }}>Select options</Typography>;
            }
            return (selected as string[]).map((value) => contactTypeOptions.find((option) => option.value === value)?.label).join(", ");
          }}
          sx={dropdownStyles}
          MenuProps={menuPropsStyles}
        >
          {contactTypeOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <Checkbox checked={selectedContactTypes.indexOf(option.value) > -1} />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Typography variant="body1" mt={2} mb={1}>
        Available Days
      </Typography>

      <CustomDatePicker dates={availableDates} onDatesChange={setAvailableDates} placeholder="Select dates" />

      {availableDates.length > 0 && (
        <List
          sx={{
            width: "100%",
            bgcolor: "#1A1E36",
            display: "flex",
            gap: "8px",
            flexWrap: "wrap",
            marginTop: "10px",
            padding: "10px",
            borderRadius: "10px",
          }}
        >
          {availableDates.map((date, index) => (
            <ListItem
              key={index}
              secondaryAction={
                <IconButton edge="end" aria-label="delete" style={{ color: Colors.info }} onClick={() => handleRemoveDate(index)}>
                  <CloseIcon />
                </IconButton>
              }
              sx={{
                background: "#23283E",
                color: "#FFF",
                margin: "2px 0",
                paddingTop: "2px",
                paddingBottom: "2px",
                width: "auto",
                borderRadius: "10px",
                minHeight: "unset",
                fontSize: "0.75rem",
              }}
            >
              <ListItemText
                primary={formatIsoToCustomDateStringWithEEEE(date.toISOString())}
                sx={{
                  fontSize: "0.75rem",
                  lineHeight: "1.2",
                }}
              />
            </ListItem>
          ))}
        </List>
      )}

      <FooterBox onClick={createPatient} disabled={!selectedHealthiePatient} buttonText="Add Patient" loading={loading} />
    </Box>
  );
};

export default AddPatientModal;
